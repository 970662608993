import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { ApiApprovalRequest } from 'app/services/generated/src/main/proto/api/approval-service.pb';
import { BinaryInfo } from 'app/services/generated/src/main/proto/storage/approval.pb';

interface BinaryTypeInfo {
  name: string;
  description: string;
  binaryType: number;
}

@Component({
  selector: 'app-binary-details-dialog',
  templateUrl: './binary-details-dialog.component.html',
  styleUrls: ['./binary-details-dialog.component.scss'],
  standalone: false,
})
export class BinaryDetailsDialogComponent implements OnInit {
  details: BinaryInfo | undefined;
  binaryTypeInfos: BinaryTypeInfo[] = [];
  title = 'Binary Details';

  constructor(
    public dialogRef: MatDialogRef<BinaryDetailsDialogComponent>,
    private binaryTypeService: BinaryTypeService,
    @Inject(MAT_DIALOG_DATA) public data: { approval: ApiApprovalRequest }
  ) {}

  async ngOnInit() {
    const binaryTypes = await this.binaryTypeService.getBinaryTypes();

    binaryTypes.binaryTypeInfos?.forEach((binaryType) => {
      this.binaryTypeInfos.push({
        name: binaryType.name,
        description: binaryType.description,
        binaryType: binaryType.binaryType,
      });
    });

    this.details = this.data.approval.approvalRequestDetail?.change?.binaryInfo;
  }

  getBinaryTypeName(binaryType: number): string | undefined {
    let result = undefined;
    this.binaryTypeInfos.forEach((binaryTypeInfo) => {
      if (binaryTypeInfo.binaryType === binaryType) {
        result = binaryTypeInfo.name;
      }
    });
    return result;
  }

  close() {
    this.dialogRef.close();
  }
}
