<app-container>
  <app-page-header
    title="{{ title }}"
    lastUpdated="{{ time }}"
  ></app-page-header>
  <span *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="24"></mat-spinner>
  </span>

  <div mat-dialog-content>
    <form
      ngNativeValidate
      (ngSubmit)="process()"
      [formGroup]="customerDataSetForm!"
      id="customerDataSetForm"
    >
      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">Name</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Name"
              formControlName="name"
              size="40"
              [ngClass]="{
                'app-input-error': checkError('name', 'required'),
              }"
            />
          </div>
          <mat-error *ngIf="checkError('name', 'required')"
            >Name is required</mat-error
          >
        </div>
      </div>

      <div class="field-row">
        <app-customer-dropdown
          [showArchiveToggle]="false"
          [showSearchBox]="true"
          [displayAsBanner]="false"
          [displayTitle]="true"
          [selectedCustomer]="selectedCustomer"
          [readOnly]="update"
          (selectCustomerEvent)="onCustomerSelect($event)"
        ></app-customer-dropdown>
      </div>
      <div class="field-row">
        <app-location-dropdown
          *ngIf="allowedCustomerLocations"
          [allowedLocations]="allowedCustomerLocations"
          [selectedLocation]="selectedLocation"
          (selectLocationEvent)="onLocationSelect($event)"
        ></app-location-dropdown>
      </div>

      <div class="field-dropdown-label">
        <span class="field-title">Encryption Key</span>
      </div>
      <div class="field-row">
        <mat-form-field class="field-name-container">
          <mat-label>Encryption Key</mat-label>
          <mat-select
            #key
            required
            (selectionChange)="onKeySelect($event.value)"
            formControlName="key_name"
          >
            <mat-option *ngFor="let key of keys" [value]="key.config!.id">
              {{ key.config!.keyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field-dropdown-label" *ngIf="key.value">
        <span class="field-title">Encryption Key Version</span>
      </div>
      <div class="field-row" *ngIf="key.value">
        <mat-form-field class="field-name-container">
          <mat-label>Encryption Key Version</mat-label>
          <mat-select formControlName="key_version">
            <mat-option
              *ngFor="let version of keyVersions"
              [value]="version.version"
            >
              {{ version.version }}
              {{ getKeyVersionExpirationLabel(version.expiresTime) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field-dropdown-label" *ngIf="key.value">
        <span class="field-title">Key File Structure</span>
      </div>
      <div class="field-row" *ngIf="key.value">
        <mat-form-field class="field-name-container">
          <mat-label>Key File Structure</mat-label>
          <mat-select #keyFileStructure formControlName="key_file_structure">
            <mat-option
              [value]="KeyFileStructure.KEY_FILE_STRUCTURE_SINGLE_KEY"
            >
              One key file
            </mat-option>
            <mat-option
              [value]="
                KeyFileStructure.KEY_FILE_STRUCTURE_ONE_KEY_PER_DATA_FILE
              "
            >
              One key file for each data file
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">External data directory</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="External data directory"
              formControlName="external_data_directory"
              size="40"
            />
          </div>
        </div>
      </div>

      <div
        class="field-row"
        *ngIf="
          keyFileStructureControl!.value ===
          KeyFileStructure.KEY_FILE_STRUCTURE_SINGLE_KEY
        "
      >
        <div class="field-name-container">
          <label class="field-title">External key path</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="External key path"
              formControlName="external_key_path"
              size="40"
            />
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">Internal data directory</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Data directory"
              formControlName="data_directory"
              size="40"
              [ngClass]="{
                'app-input-error': checkError('data_directory', 'required'),
              }"
            />
          </div>
          <mat-error *ngIf="checkError('data_directory', 'required')"
            >Data directory is required.</mat-error
          >
        </div>
      </div>

      <div
        class="field-row"
        *ngIf="
          keyFileStructureControl!.value ===
          KeyFileStructure.KEY_FILE_STRUCTURE_SINGLE_KEY
        "
      >
        <div class="field-name-container">
          <label class="field-title">Internal key path</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Key path"
              formControlName="key_path"
              size="40"
              [ngClass]="{
                'app-input-error': checkError('key_path', 'required'),
              }"
            />
          </div>
          <mat-error *ngIf="checkError('key_path', 'required')"
            >Key path is required</mat-error
          >
        </div>
      </div>
      <div class="field-row">
        <mat-checkbox formControlName="dated"> Dated </mat-checkbox>
      </div>
      <div class="field-dropdown-label">
        <span class="field-title">Formatter config</span>
      </div>
      <div class="field-row">
        <mat-form-field class="field-name-container">
          <textarea
            class="field-textarea"
            matInput
            formControlName="parser_config"
          ></textarea>
        </mat-form-field>
      </div>

      <app-message-box></app-message-box>

      <div class="save-btn" mat-dialog-actions>
        <button
          mat-flat-button
          color="primary"
          [disabled]="isLoading"
          form="customerDataSetForm"
          type="submit"
          data-testid="saveBtn"
        >
          {{ isLoading ? null : 'Save' }}
          <span *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="24"></mat-spinner>
          </span>
        </button>
      </div>
    </form>
  </div>
</app-container>
