<div class="results-table">
  <div class="error" *ngIf="error">Error loading results table.</div>
  <table *ngIf="!error">
    <thead>
      <tr>
        <th *ngFor="let column of columns">{{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td *ngFor="let column of columns">{{ row[column] }}</td>
      </tr>
    </tbody>
  </table>
</div>
