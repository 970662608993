<app-container>
  <app-page-header title="Project Manager" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <div *ngIf="isLoading" class="loader">
    <mat-spinner [diameter]="dataSource.data.length ? 24 : 42"></mat-spinner>
  </div>

  <div class="filter-block">
    <div class="filter-row">
      <app-binary-type-dropdown
        [selectedBinaryType]="selectedBinaryType"
        appearance="fill"
        (selectBinaryTypeEvent)="onBinarySelect($event)"
      ></app-binary-type-dropdown>
    </div>
    <div class="filter-row">
      <app-location-dropdown
        [allowedLocations]="allowedCustomerLocations"
        (selectLocationEvent)="onLocationSelect($event)"
      ></app-location-dropdown>
    </div>
    <div class="filter-row">
      <app-customer-dropdown
        [showArchiveToggle]="false"
        [showSearchBox]="true"
        [displayAsBanner]="true"
        [displayLabel]="true"
        label="Publisher"
        (selectCustomerEvent)="onPublisherSelect($event)"
      ></app-customer-dropdown>

      <app-customer-dropdown
        [showArchiveToggle]="false"
        [showSearchBox]="true"
        [displayAsBanner]="true"
        [displayLabel]="true"
        label="Advertiser"
        (selectCustomerEvent)="onAdvertiserSelect($event)"
      ></app-customer-dropdown>
    </div>
  </div>

  <section class="data-table-container" tabindex="0">
    <div class="data-table-title-container">
      <h2 class="data-users-title">Projects</h2>
      <button
        data-testid="create"
        class="add-project-button"
        (click)="newProject()"
      >
        New Project
      </button>
    </div>

    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>

    <table
      mat-table
      data-testid="datasetTable"
      [dataSource]="dataSource"
      class="table-data"
      matSort
    >
      <ng-container matColumnDef="advertiserId">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by advertiser"
        >
          Advertiser
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ getCustomerName(projectRow.advertiserId) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by state"
        >
          State
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <div class="col-state">
            <span>
              <span
                *ngIf="isReadyState(projectRow.state)"
                class="circle completed"
              ></span>
              <span
                *ngIf="isDraftState(projectRow.state)"
                class="circle draft"
              ></span>
              {{ getStateName(projectRow.state) }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="binaryType">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by binaryType"
        >
          Binary Type
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ binaryTypeToName.get(projectRow.binaryType) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="publisherId">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by publisher"
        >
          Publisher
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ getCustomerName(projectRow.publisherId) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by location"
        >
          Location
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ projectRow.location }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="campaignStartDate">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by campaign start"
        >
          Campaign start
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ formatProjectDate(projectRow.campaignStartDay) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="campaignEndDate">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by campaign end"
        >
          Campaign end
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ formatProjectDate(projectRow.campaignEndDay) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="postPeriodDays">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by post period days"
        >
          Post-period days
        </th>
        <td mat-cell *matCellDef="let projectRow">
          {{ projectRow.postPeriodDays }} day(s)
        </td>
      </ng-container>

      <ng-container matColumnDef="jobRunDate">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by job run date"
        >
          Job run date
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <span>{{ projectRow.jobRunDate }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="publisherDataSetName">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by publisher data set name"
        >
          Publisher dataset
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <button
            mat-menu-item
            *ngIf="projectRow.publisherDataSetId"
            (click)="viewDataSet(projectRow.publisherDataSetId)"
            [matTooltip]="projectRow.publisherDataSetId"
          >
            <mat-icon>folder_open</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="advertiserDataSetName">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by advertiser data set name"
        >
          Advertiser dataset
        </th>
        <td mat-cell *matCellDef="let projectRow">
          <button
            mat-menu-item
            *ngIf="projectRow.advertiserDataSetId"
            (click)="viewDataSet(projectRow.advertiserDataSetId)"
            [matTooltip]="projectRow.advertiserDataSetId"
          >
            <mat-icon>folder_open</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let projectRow">
          <div class="user-name-col">
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <button mat-menu-item (click)="edit(projectRow.id)">
                <mat-icon>edit</mat-icon>
                <span>View/edit</span>
              </button>
              <button mat-menu-item (click)="viewJobs(projectRow.id)">
                <mat-icon>remove_red_eye</mat-icon>
                <span>View jobs</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
  </section>

  <div class="footer">
    <mat-paginator
      *ngIf="(!isLoading || dataSource.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isLoading"
    ></mat-paginator>
  </div>
</app-container>
