<div class="background">
  <div class="card">
    <app-anonym-logo></app-anonym-logo>
    <hr />
    <div class="body">
      <div class="row">
        <p>Your session has expired. Please sign in again to continue.</p>
      </div>
      <div class="row">
        <button
          tabindex="1"
          color="primary"
          mat-flat-button
          (click)="redirectToSignIn()"
        >
          Sign in
        </button>
      </div>
    </div>
  </div>
</div>
