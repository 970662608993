import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GrpcStatusEvent } from '@ngx-grpc/common';
import { ApprovalService } from 'app/services/approval.service';
import { CustomerService } from 'app/services/customer.service';
import { DecisionRollup } from 'app/services/generated/src/main/proto/api/approval-service.pb';
import {
  ApprovedViewers,
  AttestationPolicyState,
  AuditAreaApprovalState,
} from 'app/services/generated/src/main/proto/storage/approval.pb';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

type CustomerApprovalsTable = {
  id: string | undefined;
  name: string | undefined;
  managed: boolean | undefined;
  assigned: boolean | undefined;
  approved: boolean | undefined;
  policyUpdated: boolean | undefined;
};

@Component({
  selector: 'app-binary-details-dialog',
  templateUrl: './approver-details-dialog.component.html',
  styleUrls: ['./approver-details-dialog.component.scss'],
  standalone: false,
})
export class ApproverDetailsDialogComponent implements OnInit {
  assignedCustomers: string[] = [];
  isLoading = false;
  customerApprovals: MatTableDataSource<CustomerApprovalsTable> =
    new MatTableDataSource();

  title = 'Binary Details';
  public columnsToDisplay: string[] = [
    'Id',
    'Name',
    'Managed',
    'Assigned',
    'Approved',
    'Policy updated',
    'Menu',
  ];
  constructor(
    public dialogRef: MatDialogRef<ApproverDetailsDialogComponent>,
    private approvalService: ApprovalService,
    private customerService: CustomerService,
    private messageBox: MessageBoxProvider,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      etag: string;
      approvalId: string;
      approvedViewers: ApprovedViewers;
      decisionRollups: DecisionRollup[];
    }
  ) {
    if (data.approvedViewers.customerIdList) {
      this.assignedCustomers = data.approvedViewers.customerIdList.customerIds;
    }
    this.dialogRef.disableClose = true;
  }

  async ngOnInit() {
    this.isLoading = true;
    this.customerService
      .readAllCustomers(false)
      .then((customers) => {
        const customerApprovals: CustomerApprovalsTable[] = [];
        customers?.forEach((customer) => {
          if (!customer.disabled) {
            customerApprovals.push({
              id: customer.id,
              name: customer.companyName,
              managed: customer.isManagedCustomer,
              assigned: this.isAssigned(customer.id),
              approved: this.isApproved(customer.id),
              policyUpdated: this.isPolicyUpdated(customer.id),
            });
          }
        });
        this.customerApprovals.data = customerApprovals;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async assign(customerId: string) {
    this.assignedCustomers!.push(customerId);
    try {
      const response = await this.approvalService.updateViewers(
        this.data.approvalId,
        this.data.etag,
        this.assignedCustomers!,
        undefined
      );
      this.data.etag = response.etag;
      this.ngOnInit();
    } catch (error) {
      this.messageBox.show((error as GrpcStatusEvent).statusMessage);
    }
  }

  async unAssign(customerId: string) {
    this.assignedCustomers = this.assignedCustomers!.filter(
      (item) => item != customerId
    );
    try {
      const response = await this.approvalService.updateViewers(
        this.data.approvalId,
        this.data.etag,
        this.assignedCustomers!,
        undefined
      );
      this.data.etag = response.etag;
      this.ngOnInit();
    } catch (error) {
      this.messageBox.show((error as GrpcStatusEvent).statusMessage);
    }
  }

  isAssigned(customerId: string) {
    return this.assignedCustomers!.includes(customerId);
  }

  isApproved(customerId: string) {
    const decisionRollup = this.data.decisionRollups.find(
      (decisionRollup) => decisionRollup.customerId === customerId
    );
    if (!decisionRollup) {
      return false;
    }
    return (
      decisionRollup?.approvalDecision!.approvalState ==
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED
    );
  }

  isPolicyUpdated(customerId: string) {
    const decisionRollup = this.data.decisionRollups.find(
      (decisionRollup) => decisionRollup.customerId === customerId
    );
    if (!decisionRollup) {
      return false;
    }
    return (
      decisionRollup?.attestationPolicyState ==
      AttestationPolicyState.ATTESTATION_POLICY_STATE_SET
    );
  }

  close() {
    this.dialogRef.close();
  }
}
