/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as attribution001 from '../../../../src/main/proto/attribution/advertiser.pb';
import * as storage002 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage003 from '../../../../src/main/proto/storage/commons.pb';
import * as storage004 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
import * as storage005 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api006 from '../../../../src/main/proto/api/headers.pb';
import * as api007 from '../../../../src/main/proto/api/pagination.pb';
import * as storage008 from '../../../../src/main/proto/storage/project.pb';
/**
 * Message implementation for api.project.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.project.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.project = _instance.project || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api006.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.project = new storage008.Project();
          _reader.readMessage(
            _instance.project,
            storage008.Project.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.project) {
      _writer.writeMessage(
        2,
        _instance.project as any,
        storage008.Project.serializeBinaryToWriter
      );
    }
  }

  private _header?: api006.RequestHeader;
  private _project?: storage008.Project;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.RequestHeader(_value.header)
      : undefined;
    this.project = _value.project
      ? new storage008.Project(_value.project)
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api006.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api006.RequestHeader | undefined) {
    this._header = value;
  }
  get project(): storage008.Project | undefined {
    return this._project;
  }
  set project(value: storage008.Project | undefined) {
    this._project = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      project: this.project ? this.project.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      project: this.project ? this.project.toProtobufJSON(options) : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api006.RequestHeader.AsObject;
    project?: storage008.Project.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api006.RequestHeader.AsProtobufJSON | null;
    project: storage008.Project.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.project.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.project.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.projectId = _instance.projectId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api006.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.projectId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.projectId) {
      _writer.writeString(2, _instance.projectId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api006.ResponseHeader;
  private _projectId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.ResponseHeader(_value.header)
      : undefined;
    this.projectId = _value.projectId;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api006.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api006.ResponseHeader | undefined) {
    this._header = value;
  }
  get projectId(): string {
    return this._projectId;
  }
  set projectId(value: string) {
    this._projectId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      projectId: this.projectId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      projectId: this.projectId,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api006.ResponseHeader.AsObject;
    projectId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api006.ResponseHeader.AsProtobufJSON | null;
    projectId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.project.GetProjectRequest
 */
export class GetProjectRequest implements GrpcMessage {
  static id = 'api.project.GetProjectRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetProjectRequest();
    GetProjectRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetProjectRequest) {
    _instance.header = _instance.header || undefined;
    _instance.projectId = _instance.projectId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetProjectRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api006.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.projectId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetProjectRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetProjectRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.projectId) {
      _writer.writeString(2, _instance.projectId);
    }
  }

  private _header?: api006.RequestHeader;
  private _projectId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetProjectRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetProjectRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.RequestHeader(_value.header)
      : undefined;
    this.projectId = _value.projectId;
    GetProjectRequest.refineValues(this);
  }
  get header(): api006.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api006.RequestHeader | undefined) {
    this._header = value;
  }
  get projectId(): string {
    return this._projectId;
  }
  set projectId(value: string) {
    this._projectId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetProjectRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetProjectRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      projectId: this.projectId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetProjectRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      projectId: this.projectId,
    };
  }
}
export module GetProjectRequest {
  /**
   * Standard JavaScript object representation for GetProjectRequest
   */
  export interface AsObject {
    header?: api006.RequestHeader.AsObject;
    projectId: string;
  }

  /**
   * Protobuf JSON representation for GetProjectRequest
   */
  export interface AsProtobufJSON {
    header: api006.RequestHeader.AsProtobufJSON | null;
    projectId: string;
  }
}

/**
 * Message implementation for api.project.GetProjectResponse
 */
export class GetProjectResponse implements GrpcMessage {
  static id = 'api.project.GetProjectResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetProjectResponse();
    GetProjectResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetProjectResponse) {
    _instance.header = _instance.header || undefined;
    _instance.project = _instance.project || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetProjectResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api006.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.project = new storage008.Project();
          _reader.readMessage(
            _instance.project,
            storage008.Project.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetProjectResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetProjectResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.project) {
      _writer.writeMessage(
        2,
        _instance.project as any,
        storage008.Project.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api006.ResponseHeader;
  private _project?: storage008.Project;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetProjectResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetProjectResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.ResponseHeader(_value.header)
      : undefined;
    this.project = _value.project
      ? new storage008.Project(_value.project)
      : undefined;
    this.etag = _value.etag;
    GetProjectResponse.refineValues(this);
  }
  get header(): api006.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api006.ResponseHeader | undefined) {
    this._header = value;
  }
  get project(): storage008.Project | undefined {
    return this._project;
  }
  set project(value: storage008.Project | undefined) {
    this._project = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetProjectResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetProjectResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      project: this.project ? this.project.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetProjectResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      project: this.project ? this.project.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module GetProjectResponse {
  /**
   * Standard JavaScript object representation for GetProjectResponse
   */
  export interface AsObject {
    header?: api006.ResponseHeader.AsObject;
    project?: storage008.Project.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for GetProjectResponse
   */
  export interface AsProtobufJSON {
    header: api006.ResponseHeader.AsProtobufJSON | null;
    project: storage008.Project.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.project.ListProjectsRequest
 */
export class ListProjectsRequest implements GrpcMessage {
  static id = 'api.project.ListProjectsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListProjectsRequest();
    ListProjectsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListProjectsRequest) {
    _instance.header = _instance.header || undefined;
    _instance.filter = _instance.filter || undefined;
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListProjectsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api006.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.filter = new ListProjectsFilter();
          _reader.readMessage(
            _instance.filter,
            ListProjectsFilter.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.paginated = new api007.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api007.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListProjectsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListProjectsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.filter) {
      _writer.writeMessage(
        2,
        _instance.filter as any,
        ListProjectsFilter.serializeBinaryToWriter
      );
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api007.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api006.RequestHeader;
  private _filter?: ListProjectsFilter;
  private _paginated?: api007.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListProjectsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListProjectsRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.RequestHeader(_value.header)
      : undefined;
    this.filter = _value.filter
      ? new ListProjectsFilter(_value.filter)
      : undefined;
    this.paginated = _value.paginated
      ? new api007.GetPaginatedRequest(_value.paginated)
      : undefined;
    ListProjectsRequest.refineValues(this);
  }
  get header(): api006.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api006.RequestHeader | undefined) {
    this._header = value;
  }
  get filter(): ListProjectsFilter | undefined {
    return this._filter;
  }
  set filter(value: ListProjectsFilter | undefined) {
    this._filter = value;
  }
  get paginated(): api007.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api007.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListProjectsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListProjectsRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      filter: this.filter ? this.filter.toObject() : undefined,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListProjectsRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      filter: this.filter ? this.filter.toProtobufJSON(options) : null,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module ListProjectsRequest {
  /**
   * Standard JavaScript object representation for ListProjectsRequest
   */
  export interface AsObject {
    header?: api006.RequestHeader.AsObject;
    filter?: ListProjectsFilter.AsObject;
    paginated?: api007.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for ListProjectsRequest
   */
  export interface AsProtobufJSON {
    header: api006.RequestHeader.AsProtobufJSON | null;
    filter: ListProjectsFilter.AsProtobufJSON | null;
    paginated: api007.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.project.ListProjectsResponse
 */
export class ListProjectsResponse implements GrpcMessage {
  static id = 'api.project.ListProjectsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListProjectsResponse();
    ListProjectsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListProjectsResponse) {
    _instance.header = _instance.header || undefined;
    _instance.projects = _instance.projects || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListProjectsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api006.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage008.Project();
          _reader.readMessage(
            messageInitializer2,
            storage008.Project.deserializeBinaryFromReader
          );
          (_instance.projects = _instance.projects || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api007.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api007.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListProjectsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListProjectsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.projects && _instance.projects.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.projects as any,
        storage008.Project.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api007.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api006.ResponseHeader;
  private _projects?: storage008.Project[];
  private _paginatedResponse?: api007.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListProjectsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListProjectsResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.ResponseHeader(_value.header)
      : undefined;
    this.projects = (_value.projects || []).map(
      (m) => new storage008.Project(m)
    );
    this.paginatedResponse = _value.paginatedResponse
      ? new api007.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    ListProjectsResponse.refineValues(this);
  }
  get header(): api006.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api006.ResponseHeader | undefined) {
    this._header = value;
  }
  get projects(): storage008.Project[] | undefined {
    return this._projects;
  }
  set projects(value: storage008.Project[] | undefined) {
    this._projects = value;
  }
  get paginatedResponse(): api007.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api007.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListProjectsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListProjectsResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      projects: (this.projects || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListProjectsResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      projects: (this.projects || []).map((m) => m.toProtobufJSON(options)),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module ListProjectsResponse {
  /**
   * Standard JavaScript object representation for ListProjectsResponse
   */
  export interface AsObject {
    header?: api006.ResponseHeader.AsObject;
    projects?: storage008.Project.AsObject[];
    paginatedResponse?: api007.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for ListProjectsResponse
   */
  export interface AsProtobufJSON {
    header: api006.ResponseHeader.AsProtobufJSON | null;
    projects: storage008.Project.AsProtobufJSON[] | null;
    paginatedResponse: api007.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.project.ListProjectsFilter
 */
export class ListProjectsFilter implements GrpcMessage {
  static id = 'api.project.ListProjectsFilter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListProjectsFilter();
    ListProjectsFilter.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListProjectsFilter) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListProjectsFilter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.advertiserId = _reader.readString();
          break;
        case 2:
          _instance.publisherId = _reader.readString();
          break;
        case 3:
          _instance.location = _reader.readEnum();
          break;
        case 4:
          _instance.binaryType = _reader.readEnum();
          break;
        case 5:
          _instance.archived = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ListProjectsFilter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListProjectsFilter,
    _writer: BinaryWriter
  ) {
    if (
      _instance.advertiserId !== undefined &&
      _instance.advertiserId !== null
    ) {
      _writer.writeString(1, _instance.advertiserId);
    }
    if (_instance.publisherId !== undefined && _instance.publisherId !== null) {
      _writer.writeString(2, _instance.publisherId);
    }
    if (_instance.location !== undefined && _instance.location !== null) {
      _writer.writeEnum(3, _instance.location);
    }
    if (_instance.binaryType !== undefined && _instance.binaryType !== null) {
      _writer.writeEnum(4, _instance.binaryType);
    }
    if (_instance.archived !== undefined && _instance.archived !== null) {
      _writer.writeBool(5, _instance.archived);
    }
  }

  private _advertiserId?: string;
  private _publisherId?: string;
  private _location?: storage003.Location;
  private _binaryType?: storage002.BinaryType;
  private _archived?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListProjectsFilter to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListProjectsFilter.AsObject>) {
    _value = _value || {};
    this.advertiserId = _value.advertiserId;
    this.publisherId = _value.publisherId;
    this.location = _value.location;
    this.binaryType = _value.binaryType;
    this.archived = _value.archived;
    ListProjectsFilter.refineValues(this);
  }
  get advertiserId(): string | undefined {
    return this._advertiserId;
  }
  set advertiserId(value?: string) {
    this._advertiserId = value;
  }
  get publisherId(): string | undefined {
    return this._publisherId;
  }
  set publisherId(value?: string) {
    this._publisherId = value;
  }
  get location(): storage003.Location | undefined {
    return this._location;
  }
  set location(value?: storage003.Location) {
    this._location = value;
  }
  get binaryType(): storage002.BinaryType | undefined {
    return this._binaryType;
  }
  set binaryType(value?: storage002.BinaryType) {
    this._binaryType = value;
  }
  get archived(): boolean | undefined {
    return this._archived;
  }
  set archived(value?: boolean) {
    this._archived = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListProjectsFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListProjectsFilter.AsObject {
    return {
      advertiserId: this.advertiserId,
      publisherId: this.publisherId,
      location: this.location,
      binaryType: this.binaryType,
      archived: this.archived,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListProjectsFilter.AsProtobufJSON {
    return {
      advertiserId:
        this.advertiserId === null || this.advertiserId === undefined
          ? null
          : this.advertiserId,
      publisherId:
        this.publisherId === null || this.publisherId === undefined
          ? null
          : this.publisherId,
      location:
        this.location === undefined
          ? null
          : storage003.Location[
              this.location === null || this.location === undefined
                ? 0
                : this.location
            ],
      binaryType:
        this.binaryType === undefined
          ? null
          : storage002.BinaryType[
              this.binaryType === null || this.binaryType === undefined
                ? 0
                : this.binaryType
            ],
      archived: this.archived === undefined ? null : this.archived,
    };
  }
}
export module ListProjectsFilter {
  /**
   * Standard JavaScript object representation for ListProjectsFilter
   */
  export interface AsObject {
    advertiserId?: string;
    publisherId?: string;
    location?: storage003.Location;
    binaryType?: storage002.BinaryType;
    archived?: boolean;
  }

  /**
   * Protobuf JSON representation for ListProjectsFilter
   */
  export interface AsProtobufJSON {
    advertiserId: string | null;
    publisherId: string | null;
    location: string | null;
    binaryType: string | null;
    archived: boolean | null;
  }
}

/**
 * Message implementation for api.project.UpdateProjectRequest
 */
export class UpdateProjectRequest implements GrpcMessage {
  static id = 'api.project.UpdateProjectRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateProjectRequest();
    UpdateProjectRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateProjectRequest) {
    _instance.header = _instance.header || undefined;
    _instance.project = _instance.project || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateProjectRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api006.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.project = new storage008.Project();
          _reader.readMessage(
            _instance.project,
            storage008.Project.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateProjectRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateProjectRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.project) {
      _writer.writeMessage(
        2,
        _instance.project as any,
        storage008.Project.serializeBinaryToWriter
      );
    }
  }

  private _header?: api006.RequestHeader;
  private _project?: storage008.Project;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateProjectRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateProjectRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.RequestHeader(_value.header)
      : undefined;
    this.project = _value.project
      ? new storage008.Project(_value.project)
      : undefined;
    UpdateProjectRequest.refineValues(this);
  }
  get header(): api006.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api006.RequestHeader | undefined) {
    this._header = value;
  }
  get project(): storage008.Project | undefined {
    return this._project;
  }
  set project(value: storage008.Project | undefined) {
    this._project = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateProjectRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateProjectRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      project: this.project ? this.project.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateProjectRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      project: this.project ? this.project.toProtobufJSON(options) : null,
    };
  }
}
export module UpdateProjectRequest {
  /**
   * Standard JavaScript object representation for UpdateProjectRequest
   */
  export interface AsObject {
    header?: api006.RequestHeader.AsObject;
    project?: storage008.Project.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateProjectRequest
   */
  export interface AsProtobufJSON {
    header: api006.RequestHeader.AsProtobufJSON | null;
    project: storage008.Project.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.project.UpdateProjectResponse
 */
export class UpdateProjectResponse implements GrpcMessage {
  static id = 'api.project.UpdateProjectResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateProjectResponse();
    UpdateProjectResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateProjectResponse) {
    _instance.header = _instance.header || undefined;
    _instance.projectId = _instance.projectId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateProjectResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api006.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api006.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.projectId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateProjectResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateProjectResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api006.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.projectId) {
      _writer.writeString(2, _instance.projectId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api006.ResponseHeader;
  private _projectId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateProjectResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateProjectResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api006.ResponseHeader(_value.header)
      : undefined;
    this.projectId = _value.projectId;
    this.etag = _value.etag;
    UpdateProjectResponse.refineValues(this);
  }
  get header(): api006.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api006.ResponseHeader | undefined) {
    this._header = value;
  }
  get projectId(): string {
    return this._projectId;
  }
  set projectId(value: string) {
    this._projectId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateProjectResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateProjectResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      projectId: this.projectId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateProjectResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      projectId: this.projectId,
      etag: this.etag,
    };
  }
}
export module UpdateProjectResponse {
  /**
   * Standard JavaScript object representation for UpdateProjectResponse
   */
  export interface AsObject {
    header?: api006.ResponseHeader.AsObject;
    projectId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateProjectResponse
   */
  export interface AsProtobufJSON {
    header: api006.ResponseHeader.AsProtobufJSON | null;
    projectId: string;
    etag: string;
  }
}
