import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { GetRequest } from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { PostprocessingConfig } from 'app/services/generated/src/main/proto/postprocessing/postprocessing-config.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { JobLogService } from 'app/services/job-log.service';

import { Project } from '../../../services/generated/src/main/proto/storage/project.pb';

export interface PostprocessingInfo {
  postprocessingConfig: PostprocessingConfig;
  customerIds: string[];
}

@Component({
  selector: 'app-postprocessing',
  templateUrl: './postprocessing.component.html',
  styleUrls: ['./postprocessing.component.scss'],
  standalone: false,
})
export class PostprocessingComponent implements OnChanges {
  @Output() postprocessingInfo = new EventEmitter<PostprocessingInfo>();
  @Input() project: Project | undefined;
  @Input() inputJobLog: JobLog | undefined;
  showJobError: boolean = false;

  form = this.fb.group({
    originalJobId: '',
  });

  constructor(
    private fb: FormBuilder,
    private jobLogService: JobLogService
  ) {
    this.form.valueChanges.subscribe(() => this.emitPostprocessingConfig());
  }

  async emitPostprocessingConfig() {
    let customerIds: string[] = [];
    if (this.form.value.originalJobId) {
      try {
        const resp = await this.jobLogService.get(
          new GetRequest({
            jobLogId: this.form.value.originalJobId,
          })
        );
        this.showJobError = false;
        const jobLog = resp.jobLogs![0].jobLog!;
        customerIds = jobLog.customerRoles!.map((customer) => {
          return customer.customerId;
        });
      } catch {
        this.showJobError = true;
      }
    } else {
      this.showJobError = false;
    }
    this.postprocessingInfo.emit({
      postprocessingConfig: this.createPostprocessingConfig(),
      customerIds: customerIds,
    });
  }

  convertIdStringToList(idString: string) {
    return idString.replaceAll(' ', '').split(',');
  }

  createPostprocessingConfig(): PostprocessingConfig {
    const form = this.form.value;
    return new PostprocessingConfig({
      originalJobId: form.originalJobId!,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const postprocessingConfig =
        inputJobLog.binaryConfig.unpack<PostprocessingConfig>(
          GRPC_MESSAGE_POOL
        );
      const controls = this.form.controls;
      if (postprocessingConfig.originalJobId) {
        controls.originalJobId.setValue(postprocessingConfig.originalJobId);
      }
    }
  }
}
