import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pending-user-modal',
  templateUrl: './pending-onboarding-modal.component.html',
  styleUrls: [
    './pending-onboarding-modal.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: false,
})
export class PendingOnboardingModalComponent {
  constructor(
    private dialogRef: MatDialogRef<PendingOnboardingModalComponent>
  ) {
    this.title = 'Pending Onboarding';
  }

  title: string;

  public close() {
    this.dialogRef.close();
  }
}
