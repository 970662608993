import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
  collection,
  collectionData,
  CollectionReference,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from '@angular/fire/storage';
import { Observable } from 'rxjs';

import { DocumentType } from '../../../src/types/document';
import { BinaryType } from './generated/src/main/proto/storage/binary-type.pb';
import { LoggerService } from './logger.service';

/**
 * Service to manage documents, including fetching, caching, and retrieving document links.
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private docsRef: CollectionReference<DocumentType>;
  private storage: FirebaseStorage;

  constructor(
    private firebaseApp: FirebaseApp,
    private firestore: Firestore, // Updated: Inject Firestore instead of AngularFirestore
    private logger: LoggerService
  ) {
    this.docsRef = collection(
      this.firestore,
      'docs'
    ) as CollectionReference<DocumentType>;
    this.storage = getStorage(this.firebaseApp);
  }

  /**
   * Retrieves a signed URL for a specified path.
   *
   * @param path - The path to retrieve the signed URL for.
   * @returns A promise that resolves to the signed URL.
   */
  public async getSignedURL(path: string): Promise<string> {
    try {
      const storageRef = ref(this.storage, path); // Reference to the file
      return await getDownloadURL(storageRef); // Get download URL
    } catch (error) {
      this.logger.error('Failed to retrieve signed URL', error);
      throw error;
    }
  }

  /**
   * Retrieves the Firestore collection reference for documents.
   *
   * @returns The CollectionReference for documents.
   */
  public getDocuments(): CollectionReference<DocumentType> {
    return this.docsRef;
  }

  /**
   * Retrieves a cached value from local storage for a specified path.
   *
   * @param path - The path to retrieve the cached value for.
   * @returns The cached value as a string, or null if not found.
   */
  public getCache(path: string): string | null {
    return localStorage.getItem(path);
  }

  /**
   * Sets a cached value in local storage for a specified path.
   *
   * @param path - The path to set the cached value for.
   * @param signedUrl - The signed URL to cache.
   */
  public setCache(path: string, signedUrl: string): void {
    localStorage.setItem(path, signedUrl);
  }

  /**
   * Retrieves the documentation link for a specified binary type and release version.
   *
   * @param binaryType - The binary type to filter documents by.
   * @param release - The release version to filter documents by.
   * @returns An observable of the document data matching the specified criteria.
   */
  public getBinaryDocumentationLink(
    binaryType: BinaryType,
    release: string
  ): Observable<DocumentType[]> {
    const q = query(
      this.docsRef,
      where('binary_type', '==', binaryType),
      where('release', '==', release),
      where('docType', '==', 'html')
    );

    return collectionData(q);
  }
}
