import { Component, OnInit } from '@angular/core';
import { MultiFactorError, UserCredential } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from 'app/services/logger.service';

import { SamlBase } from '../../base/saml-base';
import { MultifactorLoginModalComponent } from '../../modals/multifactor-login-modal.component/multifactor-login-modal.component';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: false,
})
export class SignInComponent extends SamlBase implements OnInit {
  allowSignInWithSaml = false;
  isLoading = false;
  signInForm: FormGroup;
  tenantId?: string;
  redirectUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analytics: AngularFireAnalytics,
    private dialog: MatDialog,
    private router: Router,
    override logger: LoggerService,
    override messageBox: MessageBoxProvider,
    override userService: UserService
  ) {
    super(logger, messageBox, userService);

    this.redirectUrl =
      this.activatedRoute.snapshot.queryParams['redirect'] || 'landing-page';
    this.signInForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  async ngOnInit(): Promise<void> {
    this.initializeQueryParams();
    if (!this.tenantId) {
      this.router.navigate(['sign-in-options']);
    }
  }

  private initializeQueryParams(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.tenantId = queryParams['tenantId'];
    this.allowSignInWithSaml = queryParams['signInWithSaml'] === 'true';
    const email = queryParams['email'];
    if (email) {
      this.signInForm.patchValue({ email });
    }
  }

  async handleSignIn(): Promise<void> {
    if (this.signInForm.invalid) return;

    const { email, password } = this.signInForm.value;
    if (!this.tenantId) {
      this.router.navigate(['sign-in-options']);
      return;
    }

    this.isLoading = true;
    try {
      const user = await this.userService.signIn(
        email,
        password,
        this.tenantId
      );
      await this.handleSuccessfulSignIn(user);
    } catch (error) {
      this.handleSignInError(error);
    } finally {
      this.isLoading = false;
    }
  }

  private async handleSuccessfulSignIn(user: UserCredential): Promise<void> {
    await this.analytics.setUserId(user.user.uid);
    await this.analytics.setUserProperties({
      signInType: 'email_password',
      email: user.user.email,
    });
    this.router.navigate([this.redirectUrl]);
  }

  private handleSignInError(error: any): void {
    switch (error.code) {
      case 'auth/user-not-found':
        this.messageBox.show('Invalid email or password', 'error');
        this.logger.error('User not found', error);
        break;
      case 'auth/multi-factor-auth-required':
        this.openMultiFactorAuthModal(error);
        break;
      default:
        this.messageBox.show('An error occurred during sign-in', 'error');
        this.logger.error('Sign-in error', error);
    }
  }

  handleIdentityProviderSignIn(): void {
    if (this.tenantId) {
      this.signInWithSaml(this.tenantId);
    } else {
      this.router.navigate(['sign-in-options']);
    }
  }

  handlePasswordReset(): void {
    const email = this.signInForm.value.email;
    if (email) {
      this.router.navigate(['forgot-password'], { queryParams: { email } });
    }
  }

  private openMultiFactorAuthModal(error: MultiFactorError): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { error };

    this.dialog
      .open(MultifactorLoginModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((user: UserCredential) => {
        if (user) {
          this.router.navigate([this.redirectUrl]);
        }
      });
  }
}
