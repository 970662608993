import { Component, Optional } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { FormatService } from 'app/services/format.service';
import { KeyVersions } from 'app/services/generated/src/main/proto/api/key-service.pb';
import {
  HsmKeyConfig,
  KeyConfig,
} from 'app/services/generated/src/main/proto/storage/key-config.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';
import {
  PermissionRoles,
  PermissionService,
} from 'app/services/permission.service';
import { environment } from 'environments/environment';

import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';
import { validateKeyParameters } from './keys-validation';
import {
  KeyLookup,
  RegisterKeyModalComponent,
  RegisterKeyModalData,
} from './register-key-modal.component';

/** Management of customer keys. */
@Component({
  selector: 'app-keys',
  templateUrl: './keys.component.html',
  styleUrls: ['./keys.component.scss'],
  standalone: false,
})
export class KeysComponent {
  keys: KeyVersions[] = [];
  displayedColumns = ['Version', 'Download Public Key', 'Created', 'Expires'];
  userHasManagerRole = false;
  userHasAccessToAllKeys = false;

  constructor(
    @Optional() private auth: Auth,
    private keyService: KeyService,
    private permissionService: PermissionService,
    private dialog: MatDialog,
    private messageBoxProvider: MessageBoxProvider,
    private logger: LoggerService,
    private router: Router,
    private FormatService: FormatService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user === null) {
        this.router.navigate([
          'sign-in-options',
          {
            queryParams: { redirect: this.router.url[0] },
          },
        ]);
      } else {
        // If customers have access to the component, this code will need
        // to consider other roles.
        this.permissionService
          .hasMatchingRoles([PermissionRoles.AnonymJobAdmin])
          .then((r) => {
            this.userHasAccessToAllKeys = r;
            this.userHasManagerRole = r;
            this.refreshList();
          });
      }
    });
  }

  async refreshList() {
    if (this.userHasAccessToAllKeys) {
      this.keyService
        .listAllKeys()
        .then((r) => {
          this.updateKeys(r.keyVersions ?? []);
        })
        .catch((error) => {
          this.logger.error('List versions error', error);
          this.messageBoxProvider.show('Failed to list keys.');
        });
    } else {
      this.keyService
        .listKeys()
        .then((r) => {
          this.updateKeys(r.keyVersions ?? []);
        })
        .catch((error) => {
          this.logger.error('List versions error', error);
          this.messageBoxProvider.show('Failed to list keys.');
        });
    }
  }

  private updateKeys(keyVersions: KeyVersions[]): void {
    this.keys = [...keyVersions];

    // Sort by update time in descending order.
    this.keys.sort((a, b) => {
      if (a.config?.lastUpdate && b.config?.lastUpdate) {
        return (
          parseInt(b.config.lastUpdate.seconds) -
          parseInt(a.config.lastUpdate.seconds)
        );
      }
      return 0;
    });
  }

  get hasManagerRole(): boolean {
    return this.userHasManagerRole;
  }

  formatDate(
    timestamp: Timestamp | undefined | null,
    def: string | undefined
  ): string | undefined {
    return timestamp ? this.FormatService.formatProtoDate(timestamp) : def;
  }

  formatTime(
    timestamp: Timestamp | undefined | null,
    def: string | undefined
  ): string | undefined {
    return timestamp ? this.FormatService.formatProtoDateTime(timestamp) : def;
  }

  formatReleasePolicy(policy: string | undefined) {
    if (policy) {
      policy = policy.replace(/\//g, '');
      return JSON.parse(policy);
    }
    return '';
  }

  async registerKey() {
    const existingKeyNames = new KeyLookup();
    this.keys.forEach((v) => {
      if (v.config) {
        existingKeyNames.add(v.config.customerId, v.config.keyName);
      }
    });
    const data: RegisterKeyModalData = {
      customerId: '',
      keyName: '',
      hsmUrl: environment.hsm.host,
      existingKeyNames: existingKeyNames,
    };
    const dialogRef = this.dialog.open(RegisterKeyModalComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const keyConfig = new KeyConfig();
        keyConfig.customerId = result.customerId;
        keyConfig.keyName = result.keyName;
        keyConfig.hsm = new HsmKeyConfig();
        keyConfig.hsm.hsmUrl = result.hsmUrl;
        if (result.expiry) {
          keyConfig.expiryTime = result.expiry;
        }
        if (result.beforeExpiry) {
          keyConfig.beforeExpiryTime = result.beforeExpiry;
        }

        this.keyService
          .registerKey(keyConfig)
          .then(() => {
            // Reload keys.
            this.refreshList();
          })
          .catch((error) => {
            this.logger.error(
              `Failed to register key: ${keyConfig.keyName}`,
              error
            );
            this.messageBoxProvider.show(
              `Failed to register key: ${keyConfig.keyName}.`
            );
          });
      }
    });
  }

  async unregisterKey(customerId: string, keyId: string) {
    this.keyService
      .unregisterKey(customerId, keyId)
      .then(() => {
        this.refreshList();
      })
      .catch((error) => {
        if (error.stastusCode === 3) {
          this.logger.error(
            `Failed to unregister key, invalid customer: ${keyId}`,
            error
          );
          this.messageBoxProvider.show(
            'Failed to unregister key, invalid customer.'
          );
        } else {
          this.logger.error(`Failed to unregister key: ${keyId}`, error);
          this.messageBoxProvider.show(`Failed to unregister key: ${keyId}.`);
        }
      });
  }

  validateKeyFields(keyConfig: KeyConfig): boolean {
    return validateKeyParameters(
      keyConfig.expiryTime,
      keyConfig.beforeExpiryTime
    );
  }

  async updateKey(keyConfig: KeyConfig) {
    this.keyService.updateKey(keyConfig).catch((error) => {
      this.logger.error(`Failed to update key: ${keyConfig.id}`, error);
      this.messageBoxProvider.show(`Failed to update key: ${keyConfig.id}.`);
    });
  }

  canRotateKey(keyConfig: KeyConfig): boolean {
    if (!keyConfig.expiryTime) {
      // Cannot rotate the key if rotation policy is not set.
      return false;
    }
    return this.hasAnonymPublicKey(keyConfig);
  }

  async rotateKey(keyConfig: KeyConfig) {
    this.keyService
      .rotateKey(keyConfig.customerId, keyConfig.id)
      .then(() => this.refreshList())
      .catch((error) => {
        this.logger.error(`Failed to rotate key: ${keyConfig.id}`, error);
        this.messageBoxProvider.show(`Failed to rotate key ${keyConfig.id}.`);
      });
  }

  hasAnonymPublicKey(keyConfig: KeyConfig): boolean {
    if (!keyConfig.hsm) {
      return false;
    }

    if (
      !keyConfig.hsm.hsmUrl ||
      !keyConfig.hsm.hsmUrl.startsWith(environment.hsm.host)
    ) {
      return false;
    }

    return true;
  }

  async downloadPublicKey(keyId: string, version: string) {
    this.keyService.downloadPublicKey(keyId, version);
  }
}
