<div class="search-box">
  <app-search
    title="Search Box"
    (selectSearchEvent)="onSearchEvent($event)"
  ></app-search>
</div>
<div class="container">
  <div class="title">
    <p>
      Total storage file size:
      <b>{{ formatBytes(totalFileSize.toString()) }}</b>
    </p>
    <p>
      Storage account: <b>{{ getFileStorageAccount() }}</b>
    </p>
  </div>
  <span *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="24"></mat-spinner>
  </span>
  <mat-accordion>
    <mat-expansion-panel
      (opened)="onOpenPanel(node)"
      [hideToggle]="hideToggle(node)"
      [expanded]="hideToggle(node)"
      *ngFor="let node of getNextPage()"
    >
      <mat-expansion-panel-header *ngIf="node.children">
        <mat-panel-description>
          <div class="banner">
            <div class="banner-left">{{ node.name }}</div>
            <div class="banner-right">
              Total file size:
              {{ formatBytes(getDirectoryFileSize(node.children)) }}
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <table cellPadding="0" cellSpacing="0" *ngIf="isNodeActive(node)">
        <tr>
          <th>Path</th>
          <th>File size</th>
          <th>Creation time (UTC)</th>
          <th>Last modified (UTC)</th>
          <th>MD5 hash</th>
        </tr>
        <tr *ngFor="let child of getPagedChildren(node.children)">
          <td>
            <button
              mat-icon-button
              color="primary"
              class="text"
              (click)="tooltipTarget.show()"
              matTooltip="{{ getFileLink(child.metadata!.url) }}"
              #tooltipTarget="matTooltip"
            >
              {{ child.name }}
            </button>
          </td>
          <td>
            <b>{{ formatBytes(child.metadata?.fileBytes) }}</b>
          </td>
          <td>{{ formatDateTime(child.metadata?.creationTime) }}</td>
          <td>{{ formatDateTime(child.metadata?.lastModified) }}</td>
          <td>
            <i>{{ child.metadata?.md5Hash }}</i>
          </td>
        </tr>
        <tfoot
          *ngIf="
            node.children &&
            node.children.length > getChildNodePagingSize() &&
            activeNodeChildCount < node.children.length
          "
        >
          <tr>
            <td colspan="5" class="table-footer">
              <button
                class="more-files-button"
                (click)="onMoreFiles(node.children)"
              >
                Load more files...
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-paginator
    #paginator
    class="demo-paginator"
    (page)="handlePageEvent($event)"
    [length]="nodeCount"
    [pageSize]="pageSize"
    [showFirstLastButtons]="true"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[10, 50, 100]"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
