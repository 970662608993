import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { TimeWindow } from 'app/services/generated/src/main/proto/attribution/attribution-config.pb';
import { MatchingConfig } from 'app/services/generated/src/main/proto/matching/matching-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import {
  PrivacyConfig,
  Subsample,
  TiktokTargetingConfig,
} from 'app/services/generated/src/main/proto/tiktok-targeting/tiktok-targeting-config.pb';

import { Project } from '../../../services/generated/src/main/proto/storage/project.pb';
import {
  CustomerDataSetInfo,
  DatasetPickerComponent,
} from '../common/dataset-picker/dataset-picker.component';

export interface TiktokTargetingInfo {
  tiktokTargetingConfig: TiktokTargetingConfig;
  customerIds: string[];
}

@Component({
  selector: 'app-tiktok-targeting',
  templateUrl: './tiktok-targeting.component.html',
  styleUrls: ['./tiktok-targeting.component.scss'],
  standalone: false,
})
export class TiktokTargetingComponent implements OnChanges {
  @ViewChild(DatasetPickerComponent)
  publisherDatasetPicker!: DatasetPickerComponent;
  @ViewChild(DatasetPickerComponent)
  advertiserDatasetPicker!: DatasetPickerComponent;
  @Input() project: Project | undefined;
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  @Input() inputJobLog: JobLog | undefined;
  @Output() tiktokTargetingInfo = new EventEmitter<TiktokTargetingInfo>();
  pubCustomerId = '';
  pubCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  advCustomerId = '';
  advCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  readonly BinaryType = BinaryType;

  form = this.fb.group({
    matchingColumns: '',
    reach: '',
    eventType: '',
    advertiserStartDate: '',
    advertiserEndDate: '',
    pubFracToRemove: 0,
    advFracToRemove: 0,
    privacyConfigs: this.fb.array([
      this.fb.group({
        rho: '',
      }),
    ]),
    enableDebugLogging: false,
    experimental: false,
  });

  eventTypes: AdvertiserEventType[] = [];
  publisherCustomerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };
  advertiserCustomerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService,
    private formatService: FormatService
  ) {
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      this.eventTypes.push(parseInt(value));
    }
    this.form.valueChanges.subscribe(() => this.emitTiktokTargetingInfo());
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  receivePublisherCustomerDataSetInfo(data: CustomerDataSetInfo) {
    this.publisherCustomerDataSetInfo = data;
    this.emitTiktokTargetingInfo();
  }

  receiveAdvertiserCustomerDataSetInfo(data: CustomerDataSetInfo) {
    this.advertiserCustomerDataSetInfo = data;
    this.emitTiktokTargetingInfo();
  }

  get privacyConfigs() {
    return this.form.controls['privacyConfigs'] as FormArray;
  }

  addPrivacyConfig() {
    this.privacyConfigs.push(
      this.fb.group({
        rho: '',
      })
    );
  }

  deletePrivacyConfig(index: number) {
    this.privacyConfigs.removeAt(index);
  }

  eventTypeName(index: number) {
    return AdvertiserEventType[index];
  }

  emitTiktokTargetingInfo() {
    this.tiktokTargetingInfo.emit({
      tiktokTargetingConfig: this.emitTiktokTargetingConfig(),
      customerIds: [
        this.publisherCustomerDataSetInfo.customerId,
        this.advertiserCustomerDataSetInfo.customerId,
      ],
    });
  }

  toTimestamp(
    timestampField: string | undefined | null
  ): Timestamp | undefined {
    if (!timestampField) {
      return undefined;
    }
    const time_millis = Date.parse(timestampField);
    return new Timestamp({
      seconds: Math.trunc(time_millis / 1000).toString(),
    });
  }

  emitTiktokTargetingConfig(): TiktokTargetingConfig {
    const form = this.form.value;
    const advertiserRef =
      this.advertiserCustomerDataSetInfo.customerDataSetReference;
    advertiserRef.startTime = this.toTimestamp(form.advertiserStartDate);
    advertiserRef.endTime = this.toTimestamp(form.advertiserEndDate);
    return new TiktokTargetingConfig({
      advertiserCustomerDataSet: advertiserRef,
      publisherCustomerDataSet:
        // Dated datasets aren't enabled for publisher targeting data.
        this.publisherCustomerDataSetInfo.customerDataSetReference,
      subsample: new Subsample({
        pubFracToRemove: form.pubFracToRemove!,
        advFracToRemove: form.advFracToRemove!,
      }),
      matchingConfig: new MatchingConfig({
        matchingColumns: this.form.value
          .matchingColumns!.split(',')
          .map((x) => x.trim()),
      }),
      advertiserEventType: parseInt(this.form.value.eventType!),
      timeWindow: new TimeWindow({
        advertiserStartTime: this.toTimestamp(
          this.form.value.advertiserStartDate
        ),
        advertiserEndTime: this.toTimestamp(this.form.value.advertiserEndDate),
      }),
      privacyConfig: this.form.value.privacyConfigs?.map(
        (x) =>
          new PrivacyConfig({
            rho: parseFloat(x.rho!),
          })
      ),
      reach: parseInt(this.form.value.reach!),
      enableDebugLogging: this.form.value.enableDebugLogging!,
      experimental: this.form.value.experimental!,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const tiktokTargetingConfig =
        inputJobLog.binaryConfig.unpack<TiktokTargetingConfig>(
          GRPC_MESSAGE_POOL
        );
      if (!tiktokTargetingConfig) {
        return;
      }
      const controls = this.form.controls;

      controls.matchingColumns.setValue(
        tiktokTargetingConfig.matchingConfig!.matchingColumns!.join(',')
      );
      controls.reach.setValue('' + tiktokTargetingConfig.reach);
      if (tiktokTargetingConfig.advertiserEventType) {
        controls.eventType.setValue(
          '' + tiktokTargetingConfig.advertiserEventType
        );
      }
      if (tiktokTargetingConfig.subsample) {
        controls.pubFracToRemove.setValue(
          tiktokTargetingConfig.subsample.pubFracToRemove
        );
        controls.advFracToRemove.setValue(
          tiktokTargetingConfig.subsample.advFracToRemove
        );
      }
      controls.advertiserStartDate.setValue(
        this.formatService.formatProtoDateForInput(
          tiktokTargetingConfig.timeWindow?.advertiserStartTime
        )
      );
      controls.advertiserEndDate.setValue(
        this.formatService.formatProtoDateForInput(
          tiktokTargetingConfig.timeWindow?.advertiserEndTime
        )
      );
      this.privacyConfigs.clear();
      tiktokTargetingConfig.privacyConfig!.forEach((v) => {
        this.privacyConfigs.push(
          this.fb.group({
            rho: v.rho,
          })
        );
      });
      controls.experimental.setValue(tiktokTargetingConfig.experimental!);
      controls.enableDebugLogging.setValue(
        tiktokTargetingConfig.enableDebugLogging!
      );
      const pubResponse = await this.customerDataService.get(
        tiktokTargetingConfig.publisherCustomerDataSet!.id
      );
      this.pubCustomerId = pubResponse.customerDataSet!.customerId;
      this.pubCustomerDataSetReference =
        tiktokTargetingConfig.publisherCustomerDataSet!;
      const advResponse = await this.customerDataService.get(
        tiktokTargetingConfig.advertiserCustomerDataSet!.id
      );
      this.advCustomerId = advResponse.customerDataSet!.customerId;
      this.advCustomerDataSetReference =
        tiktokTargetingConfig.advertiserCustomerDataSet!;
    }
  }
}
