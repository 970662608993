import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LocationList } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import {
  ArchiveRequest,
  CreateTriggerRequest,
  DeleteTriggerRequest,
  FinalizeRequest,
  ListFilter,
  ListRequest,
  StartExternalTransferRequest,
} from 'app/services/generated/src/main/proto/api/customer-data-set-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';

import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

const RECORDS_PER_PAGE = 15;

interface Page {
  continuationToken: string | null;
}

@Component({
  selector: 'app-customer-data',
  providers: [DatePipe],
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.scss'],
  standalone: false,
})
export class CustomerDataComponent implements AfterViewInit, OnInit {
  dataSource: MatTableDataSource<CustomerDataSet> = new MatTableDataSource();
  public columnsToDisplay: string[] = [
    'ID',
    'name',
    'customerId',
    'location',
    'creationTimestamp',
    'lastUpdate',
    'state',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  selectedCustomerId: string | undefined;
  dataset: CustomerDataSet[] = [];
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  lastUpdate = this.formatService.getLastUpdate();

  constructor(
    public customerDataService: CustomerDataService,
    private dialog: MatDialog,
    private formatService: FormatService,
    private keyService: KeyService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadCustomerData(this.currentPageIndex);
  }

  createCustomerDataSet(): void {
    this.router.navigate([`dataset/create`]);
  }

  editCustomerDataSet(customerDataSet: CustomerDataSet): void {
    this.router.navigate([`dataset/${customerDataSet.id}/edit`]);
  }
  startExternalTransfer(id: string): void {
    this.isLoading = true;
    const startExternalTransferRequest = new StartExternalTransferRequest({
      customerDataSetId: id,
    });
    this.customerDataService
      .startExternalTransfer(startExternalTransferRequest)
      .catch((error) => {
        this.snackBar.open(error.statusMessage, 'Start External Transfer', {
          verticalPosition: 'top',
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  finalize(id: string, etag: string): void {
    this.isLoading = true;
    const finalizeRequest = new FinalizeRequest({
      customerDataSetId: id,
      etag: etag,
    });
    this.customerDataService
      .finalize(finalizeRequest)
      .catch((error) => {
        this.snackBar.open(error.statusMessage, 'Dismiss', {
          verticalPosition: 'top',
        });
      })
      .finally(() => {
        this.isLoading = false;
        this.ngOnInit();
      });
  }

  archive(id: string, etag: string): void {
    if (confirm(`Archive data set with ${id}`) == true) {
      this.isLoading = true;
      const archiveRequest = new ArchiveRequest({
        customerDataSetId: id,
        etag: etag,
      });
      this.customerDataService
        .archive(archiveRequest)
        .catch((error) => {
          this.snackBar.open(error.statusMessage, 'Archive', {
            verticalPosition: 'top',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.ngOnInit();
        });
    }
  }

  createTrigger(id: string): void {
    if (confirm(`Create trigger for data set with ${id}`) == true) {
      this.isLoading = true;
      const createTriggerRequest = new CreateTriggerRequest({
        customerDataSetId: id,
      });
      this.customerDataService
        .createTrigger(createTriggerRequest)
        .catch((error) => {
          this.snackBar.open(error.statusMessage, 'Trigger', {
            verticalPosition: 'top',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.ngOnInit();
        });
    }
  }

  deleteTrigger(id: string): void {
    if (confirm(`Delete trigger for data set with ${id}`) == true) {
      this.isLoading = true;
      const deleteTriggerRequest = new DeleteTriggerRequest({
        customerDataSetId: id,
      });
      this.customerDataService
        .deleteTrigger(deleteTriggerRequest)
        .catch((error) => {
          this.snackBar.open(error.statusMessage, 'Trigger', {
            verticalPosition: 'top',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.ngOnInit();
        });
    }
  }

  getLocationName(location: Location) {
    const decodedLocation = LocationList.find((l) => l.value == location);
    if (decodedLocation) {
      return decodedLocation.name;
    } else {
      return 'Unknown';
    }
  }

  onCustomerChange(event: MatSelectChange) {
    this.selectedCustomerId = event.value;
    this.loadCustomerData(0);
  }

  async onPageChange(event: PageEvent) {
    if (event.pageIndex == 0) {
      this.loadCustomerData(0);
      this.currentPageIndex = 0;
    } else {
      const page = this.pages[event.pageIndex];
      if (page.continuationToken) {
        this.loadCustomerData(event.pageIndex, page.continuationToken);
        this.currentPageIndex = event.pageIndex;
      }
    }
  }

  async downloadPublicKey(keyId: string, version: string) {
    this.keyService.downloadPublicKey(keyId, version);
  }

  openStorageViewer(customerDataSetId: string): void {
    this.router.navigate([`storage-metadata-viewer/${customerDataSetId}/view`]);
  }

  loadCustomerData(pageIndex = 0, token: string | null = null) {
    this.isLoading = true;
    const listRequest = new ListRequest({
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && listRequest.paginated) {
      listRequest.paginated.continuationToken = token;
    }

    if (this.selectedCustomerId) {
      listRequest.filter = new ListFilter();
      listRequest.filter.customerId = this.selectedCustomerId;
    }

    this.customerDataService
      .list(listRequest)
      .then((data) => {
        if (data !== undefined && data.customerDataSets) {
          this.dataset = data.customerDataSets ?? [];
          this.dataSource.data = data.customerDataSets;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'ID':
                return item.id;
              case 'name':
                return item.name;
              case 'customerId':
                return item.customerId;
              case 'creationTimestamp':
                return item.creationTimestamp!.seconds;
              case 'location':
                return item.location;
              case 'lastUpdate':
                return item.lastUpdate!.seconds;
              case 'state':
                return item.state;
              case 'key':
                return String(item.encryptedData?.keyId != undefined);
              default:
                // Sort by creation time by default.
                return item.creationTimestamp!.seconds;
            }
          };
          this.dataSource.sort = this.sort;

          if (!token) {
            // When we fetch the first page, (no continuation token), we receive the
            // total records count.
            this.totalRecords =
              data.paginatedResponse?.count ?? this.dataSource.data.length;
          }

          if (data.paginatedResponse?.continuationToken) {
            this.pages[pageIndex + 1] = {
              continuationToken: data.paginatedResponse.continuationToken,
            };
          }
        }
      })
      .catch((error) => {
        this.logger.error('Error loading dataset', error);
        this.messageBox.error('Error loading dataset.');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    this.sort.sort({ id: 'creationTimestamp', start: 'desc' } as MatSortable);
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.selectedCustomerId = customer.id;
      this.loadCustomerData(0);
    }
  }
}
