import { Component, OnInit, Optional } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrl: './sign-out.component.scss',
  standalone: false,
})
export class SignOutComponent implements OnInit {
  email: string | undefined;
  tenantId: string | undefined;

  constructor(
    @Optional() private auth: Auth,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.auth?.signOut();
  }

  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.queryParams['email'];
    this.tenantId = this.activatedRoute.snapshot.queryParams['tenantId'];
  }

  redirectToSignIn() {
    this.router.navigate(['sign-in'], {
      queryParams: {
        email: this.email,
        tenantId: this.tenantId,
      },
    });
  }
}
