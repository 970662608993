import { Injectable, NgZone } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
  ActionCodeSettings,
  Auth,
  getRedirectResult,
  GoogleAuthProvider,
  SAMLAuthProvider,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  User,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { getDownloadURL, ref, uploadBytes } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { GrpcMetadata } from '@ngx-grpc/common';
import { environment } from 'environments/environment';
import * as auth from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { firstValueFrom, Observable } from 'rxjs';
import { FullUserName } from 'types/user';
import { v4 as uuidv4 } from 'uuid';

import { LoggerService } from '../services/logger.service';
import { ApiAuthService } from './api-auth.service';
import {
  CreateRequest,
  CreateResponse,
  DeleteRequest,
  GetRequest as GetUsersRequest,
  GetResponse as GetUsersResponse,
  SetPasswordRequest,
  UpdateRequest,
  UpdateResponse,
  UserInfo,
} from './generated/src/main/proto/api/user-service.pb';
import { UserServiceClient } from './generated/src/main/proto/api/user-service.pbsc';

/**
 * Service to manage user authentication, profile, and related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser: User | undefined;
  private providerId: string | null = null;

  constructor(
    private firestore: Firestore,
    public router: Router,
    public ngZone: NgZone,
    public firebaseApp: FirebaseApp,
    private analytics: AngularFireAnalytics,
    private auth: Auth,
    private userServiceClient: UserServiceClient,
    private logger: LoggerService,
    private apiAuthService: ApiAuthService
  ) {
    // Initialize user authentication state and handle redirects
    // https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.currentUser = user;
        this.logger.info('Logged in');
      } else {
        this.logger.info('Not logged in');
      }
    });

    getRedirectResult(this.auth).then((userCredential) => {
      if (
        userCredential &&
        (userCredential.operationType === 'signIn' ||
          userCredential.operationType === 'reauthenticate')
      ) {
        this.providerId = userCredential.providerId;
      }
    });
  }

  /**
   * Creates a new user.
   *
   * @param userInfo - The information of the user to create.
   * @param isAnonym - Whether the user is an Anonym user.
   * @returns A promise that resolves to the CreateResponse.
   */
  public async createUser(
    userInfo: UserInfo,
    isAnonym: boolean
  ): Promise<CreateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const createRequest = new CreateRequest();
    createRequest.email = userInfo.email;
    createRequest.roles = userInfo.roles;
    createRequest.displayName = userInfo.displayName;
    createRequest.phoneNumber = userInfo.phoneNumber;

    if (typeof userInfo.tenantId !== 'undefined') {
      createRequest.tenantId = userInfo.tenantId;
    } else {
      if (this.currentUser && this.currentUser.tenantId) {
        createRequest.tenantId = this.currentUser.tenantId;
      }
    }

    let createResponse;
    if (isAnonym) {
      createResponse = this.userServiceClient.createAnonymUser(
        createRequest,
        grpcMetaData
      );
    } else {
      createResponse = this.userServiceClient.createPartnerUser(
        createRequest,
        grpcMetaData
      );
    }

    const value = await firstValueFrom(createResponse);
    if (this.currentUser && this.currentUser.tenantId) {
      this.auth.tenantId = this.currentUser.tenantId;
      this.analytics.logEvent('create-user');
    }
    return value;
  }

  /**
   * Deletes a user.
   *
   * @param uid - The UID of the user to delete.
   * @param isAnonym - Whether the user is an Anonym user.
   * @param tenantId - The tenant ID of the user (optional).
   * @returns A promise that resolves to the delete response.
   */
  public async deleteUser(uid: string, isAnonym: boolean, tenantId?: string) {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const deleteRequest = new DeleteRequest();
    deleteRequest.uid = uid;
    if (tenantId) {
      deleteRequest.tenantId = tenantId;
    }

    let deleteResponse;
    if (isAnonym) {
      deleteResponse = this.userServiceClient.deleteAnonymUser(
        deleteRequest,
        grpcMetaData
      );
    } else {
      deleteResponse = this.userServiceClient.deletePartnerUser(
        deleteRequest,
        grpcMetaData
      );
    }

    this.analytics.logEvent('delete-user');
    return await firstValueFrom(deleteResponse);
  }

  /**
   * Creates a support ticket.
   *
   * @param title - The title of the support ticket.
   * @param message - The message of the support ticket.
   * @returns A promise that resolves when the support ticket is created.
   */
  public async createSupportTicket(title: string, message: string) {
    const payload = {
      email: this.currentUser?.email,
      item_name: title,
      message: message,
      date: Timestamp.now(),
      status: { label: 'New' },
    };
    if (this.currentUser) this.analytics.logEvent('support-ticket');

    const supportRef = doc(
      this.firestore,
      `support/${this.currentUser?.uid}/tickets/${uuidv4()}`
    );
    return setDoc(supportRef, payload, { merge: false });
  }

  /**
   * Retrieves users for a customer.
   *
   * @param tenantId - The tenant ID of the customer (optional).
   * @returns A promise that resolves to the GetUsersResponse.
   */
  async getCustomerUsers(tenantId?: string): Promise<GetUsersResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getUsersRequest = new GetUsersRequest();
    if (tenantId) {
      getUsersRequest.firebaseTenantId = tenantId;
    } else {
      //TODO: This might be any customer including Anonym
      getUsersRequest.firebaseTenantId = grpcMetaData.get('Tenant-Id');
    }
    return firstValueFrom(
      this.userServiceClient.get(getUsersRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves the TODO list for the current user.
   *
   * @returns A reference to the TODO list document.
   */
  public async getTodos() {
    const tenantId = this.currentUser?.tenantId;
    if (!tenantId) return;

    const todoRef = doc(this.firestore, `todo/${this.currentUser?.tenantId}`);
    this.analytics.logEvent('getting-todo-list');
    return getDoc(todoRef);
  }

  /**
   * Retrieves the full user name from the display name.
   *
   * @param displayName - The display name of the user.
   * @returns The full user name with first and last names.
   */
  public getFullUserName(displayName: string | null): FullUserName {
    if (!displayName) return { firstName: '', lastName: '' };

    const lastName = displayName.includes(' ') ? displayName.split(' ')[1] : '';
    const firstName = displayName.includes(' ')
      ? displayName.split(' ')[0]
      : displayName;

    return { firstName, lastName };
  }

  /**
   * Retrieves the source code agreement for the current user.
   *
   * @returns A reference to the source code agreement document.
   */
  public async getSourceCodeAgreement() {
    const tenantId = this.currentUser?.tenantId;
    if (!tenantId) return;

    const sourceCodeAgreementRef = doc(
      this.firestore,
      `source-code-agreement/${this.currentUser?.tenantId}/users/${this.currentUser?.uid}`
    );
    this.analytics.logEvent('getting-source-code-agreement');
    return getDoc(sourceCodeAgreementRef);
  }

  /**
   * Sets the source code agreement for the current user.
   *
   * @param accepted - Whether the agreement is accepted.
   * @returns A promise that resolves when the agreement is set.
   */
  public async setSourceCodeAgreement(accepted: boolean) {
    const payload = {
      accepted: accepted,
      date: Timestamp.now(),
    };
    if (this.currentUser)
      this.analytics.logEvent('setting-source-code-agreement');

    const sourceCodeAgreementRef = doc(
      this.firestore,
      `source-code-agreement/${this.currentUser?.tenantId}/users/${this.currentUser?.uid}`
    );
    return setDoc(sourceCodeAgreementRef, payload, { merge: false });
  }

  /**
   * Initiates the password reset process for a given email and tenant ID.
   *
   * @param email - The email address of the user.
   * @param tenantId - The tenant ID.
   * @returns A promise that resolves when the password reset email is sent.
   */
  public async resetPasswordInit(email: string, tenantId: string) {
    this.auth.tenantId = tenantId;
    this.analytics.logEvent('reset-user-password');
    return sendPasswordResetEmail(this.auth, email);
  }

  /**
   * Signs in a user with email and password for a specified tenant.
   *
   * @param email - The email address of the user.
   * @param password - The password of the user.
   * @param tenantId - The tenant ID.
   * @returns A promise that resolves to the user credential.
   */
  public async signIn(
    email: string,
    password: string,
    tenantId: string
  ): Promise<auth.UserCredential> {
    this.auth.tenantId = tenantId;
    this.analytics.logEvent('sign-in');
    return await signInWithEmailAndPassword(this.auth, email, password);
  }

  /**
   * Signs in a user using SAML authentication for a specified tenant.
   *
   * @param tenantId - The tenant ID.
   * @returns A promise that resolves to the user credential.
   */
  public async signInWithSaml(tenantId: string) {
    const provider = new SAMLAuthProvider(`saml.${tenantId.toLowerCase()}`);
    this.auth.tenantId = tenantId;
    this.analytics.logEvent('saml-sign-in');
    // Temporarily use pop up instead of redirect as it works with custom domain, redirect does not.
    return signInWithPopup(this.auth, provider);
  }

  /**
   * Links the current user's account with a SAML account.
   *
   * @returns A promise that resolves to the user credential.
   */
  public async linkWithSamlAccount() {
    if (this.currentUser && this.currentUser.tenantId) {
      const provider = new SAMLAuthProvider(
        `saml.${this.currentUser.tenantId.toLocaleLowerCase()}`
      );
      // Temporarily use pop up instead of redirect as it works with custom domain, redirect does not.
      return auth.linkWithPopup(this.currentUser, provider);
    } else {
      return Promise.reject('User is not authenticated.');
    }
  }

  /**
   * Unlinks the current user's SAML account.
   *
   * @returns A promise that resolves when the SAML account is unlinked.
   */
  public async unlinkSamlAccount() {
    if (this.currentUser && this.currentUser.tenantId) {
      return auth.unlink(
        this.currentUser,
        `saml.${this.currentUser.tenantId.toLocaleLowerCase()}`
      );
    } else {
      return Promise.reject('User is not authenticated.');
    }
  }

  /**
   * Signs in a user using a popup with Google authentication for a specified tenant.
   *
   * @param email - The email address of the user.
   * @param tenantId - The tenant ID.
   * @returns A promise that resolves to the user credential.
   */
  public async signInPopup(
    email: string,
    tenantId: string
  ): Promise<auth.UserCredential> {
    this.auth.tenantId = tenantId;
    return await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  /**
   * Signs out the current user and navigates to the sign-in options page.
   */
  public signOut() {
    this.auth
      .signOut()
      .then(() => {
        this.router.navigate(['sign-in-options']);
        this.analytics.logEvent('sign-out');
      })
      .catch((error) => {
        this.logger.error('Logout error', error);
      });
  }

  /**
   * Updates the password for the current user.
   *
   * @param newPassword - The new password.
   * @param confirmationPassword - The confirmation password.
   * @throws An error if the passwords do not match.
   * @returns A promise that resolves when the password is updated.
   */
  public async updatePassword(
    newPassword: string,
    confirmationPassword: string
  ) {
    if (newPassword != confirmationPassword) {
      throw new Error('Passwords must match.');
    }

    if (!this.currentUser) return;
    this.analytics.logEvent('update-password');

    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const setPasswordRequest = new SetPasswordRequest();
    setPasswordRequest.password = newPassword;
    return await firstValueFrom(
      this.userServiceClient.setPassword(setPasswordRequest, grpcMetaData)
    );
  }

  /**
   * Updates the user information for a specified user.
   *
   * @param userInfo - The user information to update.
   * @param isAnonym - Whether the user is an Anonym user.
   * @returns A promise that resolves to the UpdateResponse.
   */
  public async updateUser(
    userInfo: UserInfo,
    isAnonym: boolean
  ): Promise<UpdateResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const updateRequest = new UpdateRequest();
    if (typeof userInfo.tenantId !== 'undefined') {
      updateRequest.tenantId = userInfo.tenantId;
    } else {
      if (this.currentUser && this.currentUser.tenantId) {
        updateRequest.tenantId = this.currentUser.tenantId;
      }
    }
    updateRequest.disable = userInfo.disabled;
    updateRequest.displayName = userInfo.displayName;
    updateRequest.email = userInfo.email;
    updateRequest.phoneNumber = userInfo.phoneNumber;
    updateRequest.photoUrl = userInfo.photoUrl;
    updateRequest.roles = userInfo.roles;
    updateRequest.uid = userInfo.uid;

    return firstValueFrom(
      isAnonym
        ? this.userServiceClient.updateAnonymUser(updateRequest, grpcMetaData)
        : this.userServiceClient.updatePartnerUser(updateRequest, grpcMetaData)
    );
  }

  /**
   * Updates the profile information of the current user.
   *
   * @param firstName - The first name of the user.
   * @param lastName - The last name of the user.
   * @param photoURL - The URL of the user's profile photo.
   * @returns A promise that resolves when the profile is updated.
   */
  public async updateUserProfile(
    firstName: string,
    lastName: string,
    photoURL: string
  ) {
    if (!this.currentUser) return;

    const displayName = `${firstName} ${lastName}`;
    this.analytics.logEvent('update-user-profile');
    await updateProfile(this.currentUser, {
      displayName,
      photoURL,
    });
  }

  /**
   * Updates the display name of the current user.
   *
   * @param displayName - The new display name.
   * @returns A promise that resolves when the display name is updated.
   */
  public async updateDisplayName(displayName: string) {
    if (!this.currentUser) return;
    await updateProfile(this.currentUser, {
      displayName,
    });
  }

  /**
   * Uploads a profile picture to Firebase storage and retrieves the download URL.
   *
   * @param photoPath - The path to store the photo.
   * @param photoFile - The photo file to upload.
   * @returns An observable that emits the download URL of the uploaded photo.
   */
  public uploadProfilePicture(
    photoPath: string,
    photoFile: File
  ): Observable<string> {
    const storage = getStorage();
    const storageRef = ref(storage, photoPath);
    this.analytics.logEvent('upload-profile-picture');
    return new Observable((observer) => {
      uploadBytes(storageRef, photoFile)
        .then(() => {
          getDownloadURL(storageRef).then((downloadUrl) => {
            observer.next(downloadUrl);
            observer.complete();
          });
        })
        .catch((error) => observer.error(error));
    });
  }

  /**
   * Updates a TODO item for the current user.
   *
   * @param id - The ID of the TODO item.
   * @param state - The state of the TODO item.
   * @returns A promise that resolves when the TODO item is updated.
   */
  public async updateTodo(id: string, state: boolean) {
    const tenantId = this.currentUser?.tenantId;
    if (!tenantId) return;

    const todoRef = doc(this.firestore, `todo/${this.currentUser?.tenantId}`);
    this.analytics.logEvent('writing-todo-list', { tenantId: tenantId });
    return setDoc(todoRef, { [id]: state }, { merge: true });
  }

  /**
   * Sends an email sign-in link to the specified email address.
   *
   * @param email - The email address to send the sign-in link to.
   * @param tenantId - The tenant ID (optional).
   * @returns A promise that resolves when the sign-in link is sent.
   */
  async sendEmailSignInLink(email: string, tenantId: string | null) {
    const actionCodeSettings: ActionCodeSettings = {
      url: `https://${environment.firebase.authDomain}/landing-page`,
      handleCodeInApp: true,
      dynamicLinkDomain: environment.firebase.dynamicLinkDomain,
    };
    this.auth.tenantId = tenantId;

    return await sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
  }
}
