import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { LoggerService } from 'app/services/logger.service';
import { NotificationService } from 'app/services/notification.service';
import { UserService } from 'app/services/user.service';
import { v4 as uuidv4 } from 'uuid';

import {
  Message,
  Notification,
  NotificationTopic,
  NotificationType,
} from '../../services/generated/src/main/proto/storage/notification.pb';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  templateUrl: './admin-notification.component.html',
  styleUrls: ['./admin-notification.component.scss'],
  standalone: false,
})
export class AdminNotificationComponent implements OnInit {
  isLoading = false;
  notificationForm: FormGroup;
  selectedTenantId: string | undefined;
  notificationTopics: Array<{
    value: NotificationTopic;
    name: string;
  }> = [
    { value: NotificationTopic.NOTIFICATION_TOPIC_GENERAL, name: 'General' },
    {
      value: NotificationTopic.NOTIFICATION_TOPIC_BINARY_APPROVED,
      name: 'Binary Approved',
    },
    {
      value: NotificationTopic.NOTIFICATION_TOPIC_BINARY_RELEASED,
      name: 'Binary Release',
    },
    { value: NotificationTopic.NOTIFICATION_TOPIC_JOB, name: 'Job Completed' },
  ];
  notificationTypes: Array<{
    value: NotificationType;
    name: string;
  }> = [
    { value: NotificationType.NOTIFICATION_TYPE_EMAIL, name: 'Email' },
    {
      value: NotificationType.NOTIFICATION_TYPE_PUSH,
      name: 'Push',
    },
  ];

  constructor(
    public userService: UserService,
    private messageBox: MessageBoxProvider,
    private logger: LoggerService,
    private formHelper: FormHelpersService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService
  ) {
    this.notificationForm = this.formBuilder.group({
      title: new FormControl(null, {
        validators: [Validators.required],
      }),
      body: new FormControl(null, {
        validators: [Validators.required],
      }),
      icon: new FormControl(),
      link: new FormControl(),
      image: new FormControl(),
      notificationTypes: new FormArray([], {
        validators: [Validators.required],
      }),
      notificationTopic: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    this.formHelper.setForm(this.notificationForm);
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  onCheckboxChange(e: Event, inputType: string) {
    const component = <HTMLInputElement>e.target;
    const typesArray: FormArray = this.notificationForm.get(
      inputType
    ) as FormArray;

    if (component.checked) {
      typesArray.push(new FormControl(component.value));
    } else {
      let i = 0;
      typesArray.controls.forEach((item: AbstractControl) => {
        if (item.value == component.value) {
          typesArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.selectedTenantId = customer.tenantId;
    }
  }

  send() {
    const { value } = this.notificationForm;

    const notification = new Notification();
    notification.etag = uuidv4();
    notification.notificationEvent = value.notificationEvent;

    value.notificationTypes.forEach((notificationType: string) => {
      notification.notificationType.push(
        parseInt(notificationType) as NotificationType
      );
    });

    if (
      !value.notificationTopic ||
      !this.selectedTenantId ||
      notification.notificationType.length === 0
    ) {
      this.messageBox.error('Missing required fields');
      return;
    }

    const message = new Message();
    message.body = value.body;
    message.title = value.title;
    message.icon = value.icon;
    message.link = value.link;
    message.image = value.image;
    notification.message = message;
    this.notificationService
      .sendNotification(notification)
      .then(() => {
        this.messageBox.success('Message sent succesfully');
      })
      .catch((error) => {
        this.logger.error(error);
        this.messageBox.error('Failed to send message');
      });
  }
}
