import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { CustomerStorageCredentialService } from 'app/services/customer-storage-credential.service';
import {
  AwsS3Storage,
  EnclaveEncryptionConfig,
  GoogleCloudStorage,
} from 'app/services/generated/src/main/proto/enclaveencryption/enclave-encryption-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { CustomerStorageCredential } from 'app/services/generated/src/main/proto/storage/customer-storage-credential.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';

import {
  CustomerDataSetInfo,
  DatasetPickerComponent,
} from '../common/dataset-picker/dataset-picker.component';

export interface EnclaveEncryptionInfo {
  enclaveEncryptionConfig: EnclaveEncryptionConfig;
  customerIds: string[];
}

@Component({
  selector: 'app-enclave-encryption',
  templateUrl: './enclave-encryption.component.html',
  styleUrls: ['./enclave-encryption.component.scss'],
  standalone: false,
})
export class EnclaveEncryptionComponent implements OnChanges {
  @ViewChild(DatasetPickerComponent) datasetPicker!: DatasetPickerComponent;
  @Output() enclaveEncryptionInfo = new EventEmitter<EnclaveEncryptionInfo>();
  @Input() inputJobLog: JobLog | undefined;
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  customerId = '';
  customerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  readonly BinaryType = BinaryType;

  form = this.fb.group({
    storage_type: ['aws'],
    enclave_encryption_dataset_id: '',
    s3_bucket_name: '',
    s3_prefixes: this.fb.control<string[]>([]),
    gcs_bucket_name: '',
    gcs_prefixes: this.fb.control<string[]>([]),
    key_prefix: '',
    storage_credential_id: '',
    isEncrypted: false,
    scanOnly: false,
  });
  storage_credentials: CustomerStorageCredential[] = [];
  customerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };

  constructor(
    private fb: FormBuilder,
    private customerStorageCredentialService: CustomerStorageCredentialService,
    private customerDataService: CustomerDataService
  ) {
    this.form.valueChanges.subscribe(() => this.emitEnclaveEncryptionInfo());
  }

  onStorageTypeChange() {
    this.updateStorageCredentialsSelect();
    this.emitEnclaveEncryptionInfo();
  }

  addPrefix(type: 'gcs' | 's3', value: string): void {
    if (!value.trim()) return;

    const controlName = type === 's3' ? 's3_prefixes' : 'gcs_prefixes';
    const control = this.form.get(controlName);
    if (control) {
      const currentPrefixes = control.value || [];
      control.setValue([...currentPrefixes, value.trim()]);
    }
  }

  removePrefix(type: 'gcs' | 's3', index: number): void {
    const controlName = type === 's3' ? 's3_prefixes' : 'gcs_prefixes';
    const control = this.form.get(controlName);
    if (control) {
      const currentPrefixes = control.value || [];
      control.setValue(currentPrefixes.filter((_, i) => i !== index));
    }
  }

  receiveCustomerDataSet(data: CustomerDataSetInfo) {
    this.customerDataSetInfo = data;
    this.updateStorageCredentialsSelect();
    this.emitEnclaveEncryptionInfo();
  }

  emitEnclaveEncryptionInfo() {
    this.enclaveEncryptionInfo.emit({
      enclaveEncryptionConfig: this.createEnclaveEncryptionConfig(),
      customerIds: [this.customerDataSetInfo.customerId],
    });
  }

  createEnclaveEncryptionConfig(): EnclaveEncryptionConfig {
    const form = this.form.value;
    const storageType = form.storage_type;

    const isEncrypted = form.isEncrypted ?? false;
    const scanOnly = form.scanOnly ?? false;

    if (storageType === 'aws') {
      return new EnclaveEncryptionConfig({
        customerStorageCredentialId: form.storage_credential_id || '',
        customerDataSetId: this.customerDataSetInfo.customerDataSetReference.id,
        isEncrypted: isEncrypted,
        scanOnly: scanOnly,
        awsS3Storage: new AwsS3Storage({
          bucketName: form.s3_bucket_name || '',
          prefixes: form.s3_prefixes || [],
          encryptedSymmetricKeyPath: form.key_prefix || '',
        }),
      });
    } else if (storageType === 'gcs') {
      return new EnclaveEncryptionConfig({
        customerDataSetId: this.customerDataSetInfo.customerDataSetReference.id,
        isEncrypted: isEncrypted,
        scanOnly: scanOnly,
        googleCloudStorage: new GoogleCloudStorage({
          bucketName: form.gcs_bucket_name || '',
          prefixes: form.gcs_prefixes || [],
          encryptedSymmetricKeyPath: form.key_prefix || '',
        }),
      });
    }
    return new EnclaveEncryptionConfig();
  }

  async updateStorageCredentialsSelect() {
    this.storage_credentials.length = 0;
    const resp =
      await this.customerStorageCredentialService.listStorageCredentials(
        this.customerDataSetInfo.customerId
      );
    this.storage_credentials = resp.customerStorageCredentials!;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const enclaveEncryptionConfig =
        inputJobLog.binaryConfig.unpack<EnclaveEncryptionConfig>(
          GRPC_MESSAGE_POOL
        );
      const controls = this.form.controls;
      controls.isEncrypted.setValue(enclaveEncryptionConfig.isEncrypted);
      controls.scanOnly.setValue(enclaveEncryptionConfig.scanOnly);
      if (enclaveEncryptionConfig.awsS3Storage) {
        controls.storage_type.setValue('aws');
        if (enclaveEncryptionConfig.customerStorageCredentialId) {
          controls.storage_credential_id.setValue(
            enclaveEncryptionConfig.customerStorageCredentialId
          );
        }
        controls.s3_bucket_name.setValue(
          enclaveEncryptionConfig.awsS3Storage.bucketName
        );
        controls.s3_prefixes.setValue(
          enclaveEncryptionConfig.awsS3Storage.prefixes
        );
        controls.key_prefix.setValue(
          enclaveEncryptionConfig.awsS3Storage.encryptedSymmetricKeyPath
        );
      }
      if (enclaveEncryptionConfig.googleCloudStorage) {
        controls.storage_type.setValue('gcs');
        controls.gcs_bucket_name.setValue(
          enclaveEncryptionConfig.googleCloudStorage.bucketName
        );
        controls.gcs_prefixes.setValue(
          enclaveEncryptionConfig.googleCloudStorage.prefixes
        );
        controls.key_prefix.setValue(
          enclaveEncryptionConfig.googleCloudStorage.encryptedSymmetricKeyPath
        );
      }
      const response = await this.customerDataService.get(
        enclaveEncryptionConfig.customerDataSetId
      );
      this.customerId = response.customerDataSet!.customerId;
      this.customerDataSetReference = new CustomerDataSetReference({
        id: enclaveEncryptionConfig.customerDataSetId,
      });
    }
  }
}
