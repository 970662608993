import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { LoggerService } from 'app/services/logger.service';

import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss', '../../shared/shared.scss'],
  standalone: false,
})
export class SetPasswordComponent {
  private tenantId!: string | null;
  isLoading = false;
  setPasswordForm: FormGroup;
  public time: string = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
  }).format(Date.now());

  constructor(
    private analytics: AngularFireAnalytics,
    private auth: Auth,
    private formHelper: FormHelpersService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private userService: UserService
  ) {
    this.setPasswordForm = new FormGroup({
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(12),
      ]),
      confirmation: new FormControl<string>('', [
        Validators.required,
        (control) => {
          return formHelper.checkConfirmationPassword(control);
        },
      ]),
    });

    this.formHelper.setForm(this.setPasswordForm);
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  confirmPasswordReset() {
    this.isLoading = true;

    if (this.setPasswordForm?.value) {
      const { password, confirmation } = this.setPasswordForm.value;

      if (password !== confirmation) {
        this.messageBox.error('Passwords does not match.');
        this.isLoading = false;
        return;
      }

      this.auth.tenantId = this.tenantId;
      this.userService
        .updatePassword(password, confirmation)
        .then(() => {
          this.analytics.logEvent('reset-password-success');
          this.messageBox.success('Password changed successfully', () => {
            this.userService.signOut();
          });
        })
        .catch((error) => {
          this.logger.error('Error resetting password:', error);
          this.messageBox.show(error.message, 'error');
          this.isLoading = false;
        });
    }
  }
}
