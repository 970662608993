import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GrpcStatusEvent } from '@ngx-grpc/common';
import { LoggerService } from 'app/services/logger.service';

import { ContentService } from '../../services/content.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
  standalone: false,
})
export class ContentPageComponent implements OnInit {
  public markdown: string | undefined;
  isLoading = false;

  constructor(
    private contentService: ContentService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.data.subscribe(({ fileName }) => {
      this.logger.info(`Getting content from ${fileName}.`);
      this.getContent(fileName);
    });
  }

  getContent(fileName: string) {
    this.contentService
      .getContent(fileName)
      .then((contentResponse) => {
        this.markdown = contentResponse.contents;
      })
      .catch((error: GrpcStatusEvent) => {
        this.messageBox.error('Unable to load content for this page.');
        this.logger.error('Error loading content.', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
