import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { parse } from 'csv-parse/browser/esm/sync';

@Component({
  selector: 'app-csv-viewer',
  templateUrl: './csv-viewer.component.html',
  styleUrl: './csv-viewer.component.scss',
  standalone: false,
})
export class CsvViewerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() csv: string | undefined | null;
  @Input() displayHeader: boolean = true;
  @Input() displayColumnFilter: boolean = false;
  @Input() displayColumns: string[] | undefined;
  @Input() title: string = 'file';

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  canonicalColumns: string[] | undefined;
  displayedColumns: string[] | undefined;
  dataSource: any;
  csvSource: any;

  constructor(private liveAnnouncer: LiveAnnouncer) {}

  ngOnInit() {
    if (this.csv) {
      this.csvSource = parse(this.csv, {
        columns: true,
        relax_quotes: true,
        to: 1_000,
      });

      this.setup();
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getColumnLabel(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction} ending.`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared.');
    }
  }

  setup() {
    if (this.displayHeader && this.csvSource) {
      const header = this.csvSource[0];

      if (this.displayColumns) {
        this.displayedColumns = this.displayColumns;
        this.canonicalColumns = this.displayColumns;
      } else {
        this.displayedColumns = Object.keys(header);
        this.canonicalColumns = Object.keys(header);
      }

      this.dataSource = new MatTableDataSource(this.csvSource);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onToggleColumn(id: string, state: boolean) {
    if (this.displayedColumns) {
      if (state) {
        this.displayedColumns.push(id);
      } else {
        this.displayedColumns = this.displayedColumns.filter(
          (column) => column !== id
        );
      }
    }
  }
}
