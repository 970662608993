import { GrpcMessagePool } from '@ngx-grpc/common';
import { Empty } from '@ngx-grpc/well-known-types';
import { JobResultFileType } from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { AttributionConfig } from 'app/services/generated/src/main/proto/attribution/attribution-config.pb';
import { EnclaveEncryptionConfig } from 'app/services/generated/src/main/proto/enclaveencryption/enclave-encryption-config.pb';
import { LiftConfig } from 'app/services/generated/src/main/proto/lift/lift-config.pb';
import { PostprocessingConfig } from 'app/services/generated/src/main/proto/postprocessing/postprocessing-config.pb';
import { RedditAttributionConfig } from 'app/services/generated/src/main/proto/reddit-attribution/reddit-attribution-config.pb';
import { RedditLiftConfig } from 'app/services/generated/src/main/proto/reddit-lift/reddit-lift-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { JobState } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { State } from 'app/services/generated/src/main/proto/storage/project.pb';
import { TiktokTargetingConfig } from 'app/services/generated/src/main/proto/tiktok-targeting/tiktok-targeting-config.pb';
import { ValidationConfig } from 'app/services/generated/src/main/proto/validation/validation-config.pb';

// See https://github.com/smnbbrv/ngx-grpc?tab=readme-ov-file#googleprotobufany
// for how this is used. Note that 'Empty' must always be the first element in the list.
export const GRPC_MESSAGE_POOL = new GrpcMessagePool([
  Empty,
  LiftConfig,
  AttributionConfig,
  ValidationConfig,
  EnclaveEncryptionConfig,
  TiktokTargetingConfig,
  PostprocessingConfig,
  RedditAttributionConfig,
  RedditLiftConfig,
]);

export const JOB_STATE_TO_STRING: Record<JobState, string> = {
  [JobState.JOB_STATE_COMPLETE]: 'Completed',
  [JobState.JOB_STATE_DRAFT]: 'Draft',
  [JobState.JOB_STATE_ERROR]: 'Failed',
  [JobState.JOB_STATE_PENDING]: 'Pending',
  [JobState.JOB_STATE_RUNNING]: 'Running',
  [JobState.JOB_STATE_PENDING_CANCEL]: 'Pending Cancel',
  [JobState.JOB_STATE_CANCELLED]: 'Cancelled',
  [JobState.JOB_STATE_UNSPECIFIED]: 'Unspecified',
};

export const TYPE_ID_TO_BINARY_TYPE: Record<string, BinaryType> = {
  'attribution_v2.AttributionConfig': BinaryType.BINARY_TYPE_ATTRIBUTION,
  'enclaveencryption.EnclaveEncryptionConfig':
    BinaryType.BINARY_TYPE_ENCLAVE_ENCRYPTION,
  'lift.LiftConfig': BinaryType.BINARY_TYPE_LIFT,
  'postprocessing.PostprocessingConfig': BinaryType.BINARY_TYPE_POSTPROCESSING,
  'reddit_attribution.RedditAttributionConfig':
    BinaryType.BINARY_TYPE_REDDIT_ATTRIBUTION,
  'reddit_lift.RedditLiftConfig': BinaryType.BINARY_TYPE_REDDIT_LIFT,
  'tiktok_targeting.TiktokTargetingConfig':
    BinaryType.BINARY_TYPE_TIKTOK_TARGETING,
  'validation.ValidationConfig': BinaryType.BINARY_TYPE_VALIDATION,
};

export const AnonymRoles = [
  { value: 'AnonymAdmin', name: 'Admin', selected: false },
  { value: 'PortalUser', name: 'Portal User', selected: false },
  { value: 'AnonymJobAdmin', name: 'Job Admin', selected: false },
  { value: 'AnonymPartnerManager', name: 'Partner Manager', selected: false },
  { value: 'AnonymTest', name: 'Test', selected: false },
];

export const CustomerRoles = [
  { value: 'PartnerAdmin', name: 'Admin', selected: false },
  { value: 'PortalContributor', name: 'Portal Contributor', selected: false },
  { value: 'Approver', name: 'Approver', selected: false },
];

// Location list, use until alternative is offered by the API.
export const LocationList = [
  { value: Location.LOCATION_CUS, name: 'Central US', selected: true },
  { value: Location.LOCATION_JPE, name: 'Japan East', selected: false },
  { value: Location.LOCATION_NEU, name: 'North Europe', selected: false },
];

export const LocationCodeList = [
  { value: Location.LOCATION_CUS, name: '' },
  { value: Location.LOCATION_JPE, name: 'jpe' },
  { value: Location.LOCATION_NEU, name: 'neu' },
];

export const LocationCodeStorageList = [
  { value: Location.LOCATION_CUS, name: 'cus' },
  { value: Location.LOCATION_JPE, name: 'jpe' },
  { value: Location.LOCATION_NEU, name: 'neu' },
];

export const ProjectStateList = [
  { value: State.STATE_PROJECT_DRAFT, name: 'Draft', selected: true },
  { value: State.STATE_PROJECT_READY, name: 'Ready', selected: false },
];

// Job output file type mapping
export const JobResultFileTypeList = [
  {
    value: JobResultFileType.JOB_RESULT_FILE_TYPE_LOGS_JSON,
    name: 'Logs (json)',
    fileName: 'logs.json',
  },
  {
    value: JobResultFileType.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV,
    name: 'Measurement (csv)',
    fileName: 'measurement.csv',
  },
  {
    value: JobResultFileType.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV,
    name: 'Performance (csv)',
    fileName: 'performance.csv',
  },
  {
    value: JobResultFileType.JOB_RESULT_FILE_TYPE_OUTPUT_JSON,
    name: 'Output (json)',
    fileName: 'output.json',
  },
];

export const IdentityMatchKeysList = ['idfa', 'email', 'phone', 'gaid'];
