import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-action-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.scss', '../../shared/shared.scss'],
  standalone: false,
})
export class ConfirmationModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private analytics: AngularFireAnalytics,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      buttonText: string;
    }
  ) {}

  public async save() {
    this.analytics.logEvent('action-update-success', {
      title: this.data.title,
    });
    this.dialogRef.close({ accepted: true });
  }

  public close() {
    this.analytics.logEvent('action-update-cancelled', {
      title: this.data.title,
    });
    this.dialogRef.close({ accepted: false });
  }
}
