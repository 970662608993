<app-container class="background">
  <div class="app-header">
    <div class="left">
      <h1 data-testid="pageHeaderTitle">Data upload</h1>
      <p class="card-header" *ngIf="uploadStep === 0">
        Upload your data files securely & seamlessly!
      </p>
    </div>
  </div>

  <mat-card appearance="outlined">
    <mat-horizontal-stepper linear #stepper>
      <mat-step
        [stepControl]="datasetFormGroup"
        [completed]="validateDatasetForm()"
      >
        <ng-template matStepLabel>Create upload task</ng-template>
        <form [formGroup]="datasetFormGroup">
          <div class="field-row">
            <div class="field-name-container">
              <p class="field-title">Dataset Name</p>

              <div>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    class="app-input"
                    type="text"
                    placeholder="Type in the dataset name"
                    formControlName="name"
                    data-testid="name"
                    size="40"
                    [ngClass]="{
                      'app-input-error': checkError('name', 'required'),
                    }"
                  />
                  <mat-hint
                    >Select a descriptive name for your dataset.</mat-hint
                  >
                </mat-form-field>
              </div>

              <mat-error *ngIf="checkError('name', 'required')"
                >Dataset name is required.</mat-error
              >
            </div>
          </div>
          <div class="field-row" *ngIf="publicKeys && publicKeys.length > 1">
            <div class="field-name-container">
              <p class="field-title">Public Key</p>
              <div>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="--Select--"
                    data-testid="publicKey"
                    (selectionChange)="onKeySelect($event.value)"
                    formControlName="publicKey"
                  >
                    <mat-option
                      *ngFor="let key of publicKeys"
                      [value]="key.config!.id"
                    >
                      <label class="select-label">{{
                        key.config!.keyName
                      }}</label>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="field-row" *ngIf="keyVersions && keyVersions.length > 1">
            <div class="field-name-container">
              <p class="field-title">Key version</p>
              <div>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="--Select--"
                    data-testid="keyVersion"
                    (selectionChange)="onKeySelect($event.value)"
                    formControlName="keyVersion"
                  >
                    <mat-option
                      *ngFor="let version of keyVersions"
                      [value]="version.version"
                    >
                      {{ version.version }}
                      {{ getKeyVersionExpirationLabel(version.expiresTime) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="field-row">
            <app-location-dropdown
              *ngIf="customer && customer.locations.length > 1"
              [allowedLocations]="customer.locations"
              (selectLocationEvent)="onLocationSelect($event)"
            ></app-location-dropdown>
          </div>
          <div>
            <button
              data-testid="firstButton"
              matStepperNext
              class="next-btn"
              [disabled]="!validateDatasetForm()"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step label="Upload" state="chat" [completed]="areFilesSelected()">
        <ng-template matStepLabel>Select files</ng-template>
        <div class="drop-zone">
          <ngx-file-drop
            [multiple]="true"
            [directory]="true"
            dropZoneClassName="drop-zone"
            accept="{{ getValidFileFormats() }}"
            (onFileDrop)="dropped($event)"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
              showBrowseBtn="true"
            >
              <input
                type="file"
                class="file-input"
                data-testid="fileUploadControl"
                multiple
                fileControlName="fileUpload"
                required
                hidden
                accept=".csv,.json,.parquet,.pqt"
                (change)="onFileSelectionChange($event)"
                #fileUpload
              />
              <div (click)="fileUpload.click()">
                <button class="upload-btn" data-testid="fileUploadButton">
                  <mat-icon class="upload-icon">cloud_upload</mat-icon>
                  <div class="upload-title">File Upload</div>
                </button>
              </div>
            </ng-template>
          </ngx-file-drop>
        </div>
        <div class="file-format" *ngIf="displayInvalidFileFormatMessage">
          Accepted file formats are: {{ getValidFileFormats() }}
        </div>
        <div class="selected-files" *ngIf="files.length > 0">
          <h3>Selected files</h3>
          <ul *ngFor="let file of files">
            <li>{{ file.name }} - {{ formatBytes(file.size) }}</li>
          </ul>
        </div>
        <div>
          <button mat-button matStepperPrevious class="back-btn">Back</button>
          <button
            data-testid="secondButton"
            matStepperNext
            class="next-btn"
            [disabled]="!areFilesSelected()"
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step state="process" [completed]="isProcessComplete">
        <ng-template matStepLabel>Process</ng-template>
        <div class="process" *ngIf="files.length > 0">
          <p>
            <i
              >Files will be encrypted in your browser with the "{{
                getKeyName()
              }}" public key before uploading to Anonym's storage</i
            >
          </p>
          <br />
          <h3>Files to encrypt and upload:</h3>
          <ul *ngFor="let file of files">
            <li>{{ file.name }} - {{ formatBytes(file.size) }}</li>
          </ul>
          <div class="go-box">
            <button
              (click)="process()"
              mat-flat-button
              color="primary"
              class="go-btn"
              [disabled]="isProcessing || isProcessComplete"
              data-testid="processButton"
            >
              Process
            </button>
          </div>
        </div>
        <app-message-box></app-message-box>

        <section class="progress" *ngIf="displayProgress">
          <p
            *ngIf="isFormatProgress && customer?.webUploadCsvFormattingEnabled"
          >
            <b>Formatting row: {{ formatProgress }}</b>
          </p>
          <p>
            <b>{{ currentFileUploadState }}</b>
          </p>
          <mat-progress-bar
            class="progress-bar"
            mode="indeterminate"
            *ngIf="!isFileProgressTypeDeterminate"
          />
          <mat-progress-bar
            class="progress-bar"
            mode="determinate"
            [value]="fileProgress"
            *ngIf="fileProgress && isFileProgressTypeDeterminate"
          />
          <p><b>Total</b></p>
          <mat-progress-bar
            class="progress-bar"
            mode="determinate"
            [value]="totalProgress"
            *ngIf="totalProgress"
          />
          <p>{{ uploadStage }}</p>
        </section>

        <div>
          <button mat-button matStepperPrevious class="back-btn">Back</button>
          <button
            data-testid="thirdButton"
            matStepperNext
            class="next-btn"
            *ngIf="isProcessComplete"
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step state="complete">
        <ng-template matStepLabel>Summary</ng-template>
        <h3>Upload Summary</h3>

        <section>
          <div *ngIf="files.length" class="summary">
            <ul data-testid="uploadSummary" *ngFor="let file of files">
              <li>
                File {{ file.name }} of size
                {{ formatBytes(file.size) }} uploaded successfully.
              </li>
            </ul>
            <p>
              A total of {{ formatBytes(totalUploadFileSize) }} was encrypted
              and uploaded in
              {{ formatMicrosecondsToTime(totalUploadTimeInSeconds) }} seconds
              to a secure Azure blobstorage container.<br />
              The upload reference is: <b>{{ datasetName }}</b
              >.
            </p>
          </div>
        </section>

        <div>
          <button data-testid="doneButton" (click)="done()" class="next-btn">
            Done
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card>
</app-container>
