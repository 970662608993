/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as attribution001 from '../../../../src/main/proto/attribution/advertiser.pb';
import * as matching002 from '../../../../src/main/proto/matching/matching-config.pb';
import * as storage003 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
/**
 * Message implementation for tiktok_targeting.TiktokTargetingConfig
 */
export class TiktokTargetingConfig implements GrpcMessage {
  static id = 'tiktok_targeting.TiktokTargetingConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TiktokTargetingConfig();
    TiktokTargetingConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TiktokTargetingConfig) {
    _instance.publisherCustomerDataSet =
      _instance.publisherCustomerDataSet || undefined;
    _instance.advertiserCustomerDataSet =
      _instance.advertiserCustomerDataSet || undefined;
    _instance.matchingConfig = _instance.matchingConfig || undefined;
    _instance.advertiserEventType = _instance.advertiserEventType || 0;
    _instance.privacyConfig = _instance.privacyConfig || [];
    _instance.reach = _instance.reach || 0;
    _instance.enableDebugLogging = _instance.enableDebugLogging || false;
    _instance.timeWindow = _instance.timeWindow || undefined;
    _instance.dropDuplicateRecordIds =
      _instance.dropDuplicateRecordIds || false;
    _instance.loadRecordId = _instance.loadRecordId || false;
    _instance.experimental = _instance.experimental || false;
    _instance.subsample = _instance.subsample || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TiktokTargetingConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.publisherCustomerDataSet =
            new storage003.CustomerDataSetReference();
          _reader.readMessage(
            _instance.publisherCustomerDataSet,
            storage003.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.advertiserCustomerDataSet =
            new storage003.CustomerDataSetReference();
          _reader.readMessage(
            _instance.advertiserCustomerDataSet,
            storage003.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.matchingConfig = new matching002.MatchingConfig();
          _reader.readMessage(
            _instance.matchingConfig,
            matching002.MatchingConfig.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.advertiserEventType = _reader.readEnum();
          break;
        case 5:
          const messageInitializer5 = new PrivacyConfig();
          _reader.readMessage(
            messageInitializer5,
            PrivacyConfig.deserializeBinaryFromReader
          );
          (_instance.privacyConfig = _instance.privacyConfig || []).push(
            messageInitializer5
          );
          break;
        case 6:
          _instance.reach = _reader.readInt32();
          break;
        case 7:
          _instance.enableDebugLogging = _reader.readBool();
          break;
        case 8:
          _instance.timeWindow = new TimeWindow();
          _reader.readMessage(
            _instance.timeWindow,
            TimeWindow.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.dropDuplicateRecordIds = _reader.readBool();
          break;
        case 10:
          _instance.loadRecordId = _reader.readBool();
          break;
        case 11:
          _instance.experimental = _reader.readBool();
          break;
        case 12:
          _instance.subsample = new Subsample();
          _reader.readMessage(
            _instance.subsample,
            Subsample.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TiktokTargetingConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TiktokTargetingConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.publisherCustomerDataSet) {
      _writer.writeMessage(
        1,
        _instance.publisherCustomerDataSet as any,
        storage003.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserCustomerDataSet) {
      _writer.writeMessage(
        2,
        _instance.advertiserCustomerDataSet as any,
        storage003.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.matchingConfig) {
      _writer.writeMessage(
        3,
        _instance.matchingConfig as any,
        matching002.MatchingConfig.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserEventType) {
      _writer.writeEnum(4, _instance.advertiserEventType);
    }
    if (_instance.privacyConfig && _instance.privacyConfig.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.privacyConfig as any,
        PrivacyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.reach) {
      _writer.writeInt32(6, _instance.reach);
    }
    if (_instance.enableDebugLogging) {
      _writer.writeBool(7, _instance.enableDebugLogging);
    }
    if (_instance.timeWindow) {
      _writer.writeMessage(
        8,
        _instance.timeWindow as any,
        TimeWindow.serializeBinaryToWriter
      );
    }
    if (_instance.dropDuplicateRecordIds) {
      _writer.writeBool(9, _instance.dropDuplicateRecordIds);
    }
    if (_instance.loadRecordId) {
      _writer.writeBool(10, _instance.loadRecordId);
    }
    if (_instance.experimental) {
      _writer.writeBool(11, _instance.experimental);
    }
    if (_instance.subsample) {
      _writer.writeMessage(
        12,
        _instance.subsample as any,
        Subsample.serializeBinaryToWriter
      );
    }
  }

  private _publisherCustomerDataSet?: storage003.CustomerDataSetReference;
  private _advertiserCustomerDataSet?: storage003.CustomerDataSetReference;
  private _matchingConfig?: matching002.MatchingConfig;
  private _advertiserEventType: attribution001.AdvertiserEventType;
  private _privacyConfig?: PrivacyConfig[];
  private _reach: number;
  private _enableDebugLogging: boolean;
  private _timeWindow?: TimeWindow;
  private _dropDuplicateRecordIds: boolean;
  private _loadRecordId: boolean;
  private _experimental: boolean;
  private _subsample?: Subsample;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TiktokTargetingConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<TiktokTargetingConfig.AsObject>) {
    _value = _value || {};
    this.publisherCustomerDataSet = _value.publisherCustomerDataSet
      ? new storage003.CustomerDataSetReference(_value.publisherCustomerDataSet)
      : undefined;
    this.advertiserCustomerDataSet = _value.advertiserCustomerDataSet
      ? new storage003.CustomerDataSetReference(
          _value.advertiserCustomerDataSet
        )
      : undefined;
    this.matchingConfig = _value.matchingConfig
      ? new matching002.MatchingConfig(_value.matchingConfig)
      : undefined;
    this.advertiserEventType = _value.advertiserEventType;
    this.privacyConfig = (_value.privacyConfig || []).map(
      (m) => new PrivacyConfig(m)
    );
    this.reach = _value.reach;
    this.enableDebugLogging = _value.enableDebugLogging;
    this.timeWindow = _value.timeWindow
      ? new TimeWindow(_value.timeWindow)
      : undefined;
    this.dropDuplicateRecordIds = _value.dropDuplicateRecordIds;
    this.loadRecordId = _value.loadRecordId;
    this.experimental = _value.experimental;
    this.subsample = _value.subsample
      ? new Subsample(_value.subsample)
      : undefined;
    TiktokTargetingConfig.refineValues(this);
  }
  get publisherCustomerDataSet():
    | storage003.CustomerDataSetReference
    | undefined {
    return this._publisherCustomerDataSet;
  }
  set publisherCustomerDataSet(
    value: storage003.CustomerDataSetReference | undefined
  ) {
    this._publisherCustomerDataSet = value;
  }
  get advertiserCustomerDataSet():
    | storage003.CustomerDataSetReference
    | undefined {
    return this._advertiserCustomerDataSet;
  }
  set advertiserCustomerDataSet(
    value: storage003.CustomerDataSetReference | undefined
  ) {
    this._advertiserCustomerDataSet = value;
  }
  get matchingConfig(): matching002.MatchingConfig | undefined {
    return this._matchingConfig;
  }
  set matchingConfig(value: matching002.MatchingConfig | undefined) {
    this._matchingConfig = value;
  }
  get advertiserEventType(): attribution001.AdvertiserEventType {
    return this._advertiserEventType;
  }
  set advertiserEventType(value: attribution001.AdvertiserEventType) {
    this._advertiserEventType = value;
  }
  get privacyConfig(): PrivacyConfig[] | undefined {
    return this._privacyConfig;
  }
  set privacyConfig(value: PrivacyConfig[] | undefined) {
    this._privacyConfig = value;
  }
  get reach(): number {
    return this._reach;
  }
  set reach(value: number) {
    this._reach = value;
  }
  get enableDebugLogging(): boolean {
    return this._enableDebugLogging;
  }
  set enableDebugLogging(value: boolean) {
    this._enableDebugLogging = value;
  }
  get timeWindow(): TimeWindow | undefined {
    return this._timeWindow;
  }
  set timeWindow(value: TimeWindow | undefined) {
    this._timeWindow = value;
  }
  get dropDuplicateRecordIds(): boolean {
    return this._dropDuplicateRecordIds;
  }
  set dropDuplicateRecordIds(value: boolean) {
    this._dropDuplicateRecordIds = value;
  }
  get loadRecordId(): boolean {
    return this._loadRecordId;
  }
  set loadRecordId(value: boolean) {
    this._loadRecordId = value;
  }
  get experimental(): boolean {
    return this._experimental;
  }
  set experimental(value: boolean) {
    this._experimental = value;
  }
  get subsample(): Subsample | undefined {
    return this._subsample;
  }
  set subsample(value: Subsample | undefined) {
    this._subsample = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TiktokTargetingConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TiktokTargetingConfig.AsObject {
    return {
      publisherCustomerDataSet: this.publisherCustomerDataSet
        ? this.publisherCustomerDataSet.toObject()
        : undefined,
      advertiserCustomerDataSet: this.advertiserCustomerDataSet
        ? this.advertiserCustomerDataSet.toObject()
        : undefined,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toObject()
        : undefined,
      advertiserEventType: this.advertiserEventType,
      privacyConfig: (this.privacyConfig || []).map((m) => m.toObject()),
      reach: this.reach,
      enableDebugLogging: this.enableDebugLogging,
      timeWindow: this.timeWindow ? this.timeWindow.toObject() : undefined,
      dropDuplicateRecordIds: this.dropDuplicateRecordIds,
      loadRecordId: this.loadRecordId,
      experimental: this.experimental,
      subsample: this.subsample ? this.subsample.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TiktokTargetingConfig.AsProtobufJSON {
    return {
      publisherCustomerDataSet: this.publisherCustomerDataSet
        ? this.publisherCustomerDataSet.toProtobufJSON(options)
        : null,
      advertiserCustomerDataSet: this.advertiserCustomerDataSet
        ? this.advertiserCustomerDataSet.toProtobufJSON(options)
        : null,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toProtobufJSON(options)
        : null,
      advertiserEventType:
        attribution001.AdvertiserEventType[
          this.advertiserEventType === null ||
          this.advertiserEventType === undefined
            ? 0
            : this.advertiserEventType
        ],
      privacyConfig: (this.privacyConfig || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      reach: this.reach,
      enableDebugLogging: this.enableDebugLogging,
      timeWindow: this.timeWindow
        ? this.timeWindow.toProtobufJSON(options)
        : null,
      dropDuplicateRecordIds: this.dropDuplicateRecordIds,
      loadRecordId: this.loadRecordId,
      experimental: this.experimental,
      subsample: this.subsample ? this.subsample.toProtobufJSON(options) : null,
    };
  }
}
export module TiktokTargetingConfig {
  /**
   * Standard JavaScript object representation for TiktokTargetingConfig
   */
  export interface AsObject {
    publisherCustomerDataSet?: storage003.CustomerDataSetReference.AsObject;
    advertiserCustomerDataSet?: storage003.CustomerDataSetReference.AsObject;
    matchingConfig?: matching002.MatchingConfig.AsObject;
    advertiserEventType: attribution001.AdvertiserEventType;
    privacyConfig?: PrivacyConfig.AsObject[];
    reach: number;
    enableDebugLogging: boolean;
    timeWindow?: TimeWindow.AsObject;
    dropDuplicateRecordIds: boolean;
    loadRecordId: boolean;
    experimental: boolean;
    subsample?: Subsample.AsObject;
  }

  /**
   * Protobuf JSON representation for TiktokTargetingConfig
   */
  export interface AsProtobufJSON {
    publisherCustomerDataSet: storage003.CustomerDataSetReference.AsProtobufJSON | null;
    advertiserCustomerDataSet: storage003.CustomerDataSetReference.AsProtobufJSON | null;
    matchingConfig: matching002.MatchingConfig.AsProtobufJSON | null;
    advertiserEventType: string;
    privacyConfig: PrivacyConfig.AsProtobufJSON[] | null;
    reach: number;
    enableDebugLogging: boolean;
    timeWindow: TimeWindow.AsProtobufJSON | null;
    dropDuplicateRecordIds: boolean;
    loadRecordId: boolean;
    experimental: boolean;
    subsample: Subsample.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for tiktok_targeting.PrivacyConfig
 */
export class PrivacyConfig implements GrpcMessage {
  static id = 'tiktok_targeting.PrivacyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PrivacyConfig();
    PrivacyConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PrivacyConfig) {
    _instance.rho = _instance.rho || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PrivacyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rho = _reader.readFloat();
          break;
        default:
          _reader.skipField();
      }
    }

    PrivacyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PrivacyConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.rho) {
      _writer.writeFloat(1, _instance.rho);
    }
  }

  private _rho: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PrivacyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<PrivacyConfig.AsObject>) {
    _value = _value || {};
    this.rho = _value.rho;
    PrivacyConfig.refineValues(this);
  }
  get rho(): number {
    return this._rho;
  }
  set rho(value: number) {
    this._rho = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PrivacyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PrivacyConfig.AsObject {
    return {
      rho: this.rho,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PrivacyConfig.AsProtobufJSON {
    return {
      rho: this.rho,
    };
  }
}
export module PrivacyConfig {
  /**
   * Standard JavaScript object representation for PrivacyConfig
   */
  export interface AsObject {
    rho: number;
  }

  /**
   * Protobuf JSON representation for PrivacyConfig
   */
  export interface AsProtobufJSON {
    rho: number;
  }
}

/**
 * Message implementation for tiktok_targeting.TimeWindow
 */
export class TimeWindow implements GrpcMessage {
  static id = 'tiktok_targeting.TimeWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeWindow();
    TimeWindow.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeWindow) {
    _instance.advertiserStartTime = _instance.advertiserStartTime || undefined;
    _instance.advertiserEndTime = _instance.advertiserEndTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.advertiserStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.advertiserEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TimeWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TimeWindow, _writer: BinaryWriter) {
    if (_instance.advertiserStartTime) {
      _writer.writeMessage(
        1,
        _instance.advertiserStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserEndTime) {
      _writer.writeMessage(
        2,
        _instance.advertiserEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _advertiserStartTime?: googleProtobuf000.Timestamp;
  private _advertiserEndTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeWindow.AsObject>) {
    _value = _value || {};
    this.advertiserStartTime = _value.advertiserStartTime
      ? new googleProtobuf000.Timestamp(_value.advertiserStartTime)
      : undefined;
    this.advertiserEndTime = _value.advertiserEndTime
      ? new googleProtobuf000.Timestamp(_value.advertiserEndTime)
      : undefined;
    TimeWindow.refineValues(this);
  }
  get advertiserStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserStartTime;
  }
  set advertiserStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserStartTime = value;
  }
  get advertiserEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserEndTime;
  }
  set advertiserEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserEndTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeWindow.AsObject {
    return {
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toObject()
        : undefined,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeWindow.AsProtobufJSON {
    return {
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toProtobufJSON(options)
        : null,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module TimeWindow {
  /**
   * Standard JavaScript object representation for TimeWindow
   */
  export interface AsObject {
    advertiserStartTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserEndTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for TimeWindow
   */
  export interface AsProtobufJSON {
    advertiserStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for tiktok_targeting.Subsample
 */
export class Subsample implements GrpcMessage {
  static id = 'tiktok_targeting.Subsample';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Subsample();
    Subsample.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Subsample) {
    _instance.pubFracToRemove = _instance.pubFracToRemove || 0;
    _instance.advFracToRemove = _instance.advFracToRemove || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Subsample,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.pubFracToRemove = _reader.readFloat();
          break;
        case 2:
          _instance.advFracToRemove = _reader.readFloat();
          break;
        default:
          _reader.skipField();
      }
    }

    Subsample.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Subsample, _writer: BinaryWriter) {
    if (_instance.pubFracToRemove) {
      _writer.writeFloat(1, _instance.pubFracToRemove);
    }
    if (_instance.advFracToRemove) {
      _writer.writeFloat(2, _instance.advFracToRemove);
    }
  }

  private _pubFracToRemove: number;
  private _advFracToRemove: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Subsample to deeply clone from
   */
  constructor(_value?: RecursivePartial<Subsample.AsObject>) {
    _value = _value || {};
    this.pubFracToRemove = _value.pubFracToRemove;
    this.advFracToRemove = _value.advFracToRemove;
    Subsample.refineValues(this);
  }
  get pubFracToRemove(): number {
    return this._pubFracToRemove;
  }
  set pubFracToRemove(value: number) {
    this._pubFracToRemove = value;
  }
  get advFracToRemove(): number {
    return this._advFracToRemove;
  }
  set advFracToRemove(value: number) {
    this._advFracToRemove = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Subsample.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Subsample.AsObject {
    return {
      pubFracToRemove: this.pubFracToRemove,
      advFracToRemove: this.advFracToRemove,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Subsample.AsProtobufJSON {
    return {
      pubFracToRemove: this.pubFracToRemove,
      advFracToRemove: this.advFracToRemove,
    };
  }
}
export module Subsample {
  /**
   * Standard JavaScript object representation for Subsample
   */
  export interface AsObject {
    pubFracToRemove: number;
    advFracToRemove: number;
  }

  /**
   * Protobuf JSON representation for Subsample
   */
  export interface AsProtobufJSON {
    pubFracToRemove: number;
    advFracToRemove: number;
  }
}
