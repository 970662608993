/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as googleProtobuf002 from '@ngx-grpc/well-known-types';
import * as storage003 from '../../../../src/main/proto/storage/commons.pb';
import * as storage004 from '../../../../src/main/proto/storage/schema-annotations.pb';
export enum JobState {
  JOB_STATE_UNSPECIFIED = 0,
  JOB_STATE_DRAFT = 1,
  JOB_STATE_PENDING = 2,
  JOB_STATE_RUNNING = 3,
  JOB_STATE_COMPLETE = 4,
  JOB_STATE_ERROR = 5,
  JOB_STATE_CANCELLED = 6,
  JOB_STATE_PENDING_CANCEL = 7,
}
/**
 * Message implementation for storage.DaskConfig
 */
export class DaskConfig implements GrpcMessage {
  static id = 'storage.DaskConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DaskConfig();
    DaskConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DaskConfig) {
    _instance.numWorkers = _instance.numWorkers || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DaskConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.numWorkers = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    DaskConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: DaskConfig, _writer: BinaryWriter) {
    if (_instance.numWorkers) {
      _writer.writeInt32(1, _instance.numWorkers);
    }
  }

  private _numWorkers: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DaskConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<DaskConfig.AsObject>) {
    _value = _value || {};
    this.numWorkers = _value.numWorkers;
    DaskConfig.refineValues(this);
  }
  get numWorkers(): number {
    return this._numWorkers;
  }
  set numWorkers(value: number) {
    this._numWorkers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DaskConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DaskConfig.AsObject {
    return {
      numWorkers: this.numWorkers,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DaskConfig.AsProtobufJSON {
    return {
      numWorkers: this.numWorkers,
    };
  }
}
export module DaskConfig {
  /**
   * Standard JavaScript object representation for DaskConfig
   */
  export interface AsObject {
    numWorkers: number;
  }

  /**
   * Protobuf JSON representation for DaskConfig
   */
  export interface AsProtobufJSON {
    numWorkers: number;
  }
}

/**
 * Message implementation for storage.JobLog
 */
export class JobLog implements GrpcMessage {
  static id = 'storage.JobLog';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobLog();
    JobLog.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobLog) {
    _instance.id = _instance.id || '';

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.creationTimestamp = _instance.creationTimestamp || undefined;
    _instance.state = _instance.state || 0;
    _instance.customerRoles = _instance.customerRoles || [];
    _instance.location = _instance.location || 0;
    _instance.binaryConfig = _instance.binaryConfig || undefined;
    _instance.binary = _instance.binary || undefined;
    _instance.execution = _instance.execution || undefined;
    _instance.metadata = _instance.metadata || undefined;
    _instance.archived = _instance.archived || false;
    _instance.error = _instance.error || undefined;
    _instance.jobScheduleId = _instance.jobScheduleId || '';
    _instance.daskConfig = _instance.daskConfig || undefined;
    _instance.projectId = _instance.projectId || '';
    _instance.postprocessing = _instance.postprocessing || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: JobLog, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 19:
          _instance.etag = _reader.readString();
          break;
        case 20:
          _instance.lastUpdate = new googleProtobuf002.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf002.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.creationTimestamp = new googleProtobuf002.Timestamp();
          _reader.readMessage(
            _instance.creationTimestamp,
            googleProtobuf002.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.state = _reader.readEnum();
          break;
        case 21:
          const messageInitializer21 = new JobLogCustomerRole();
          _reader.readMessage(
            messageInitializer21,
            JobLogCustomerRole.deserializeBinaryFromReader
          );
          (_instance.customerRoles = _instance.customerRoles || []).push(
            messageInitializer21
          );
          break;
        case 24:
          _instance.location = _reader.readEnum();
          break;
        case 28:
          _instance.binaryConfig = new googleProtobuf001.Any();
          _reader.readMessage(
            _instance.binaryConfig,
            googleProtobuf001.Any.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.binary = new JobBinary();
          _reader.readMessage(
            _instance.binary,
            JobBinary.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.execution = new JobLogExecution();
          _reader.readMessage(
            _instance.execution,
            JobLogExecution.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.metadata = new JobMetadata();
          _reader.readMessage(
            _instance.metadata,
            JobMetadata.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.archived = _reader.readBool();
          break;
        case 13:
          _instance.error = new JobError();
          _reader.readMessage(
            _instance.error,
            JobError.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.jobScheduleId = _reader.readString();
          break;
        case 27:
          _instance.daskConfig = new DaskConfig();
          _reader.readMessage(
            _instance.daskConfig,
            DaskConfig.deserializeBinaryFromReader
          );
          break;
        case 29:
          _instance.projectId = _reader.readString();
          break;
        case 30:
          _instance.postprocessing = new Postprocessing();
          _reader.readMessage(
            _instance.postprocessing,
            Postprocessing.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    JobLog.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: JobLog, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(19, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        20,
        _instance.lastUpdate as any,
        googleProtobuf002.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.creationTimestamp) {
      _writer.writeMessage(
        2,
        _instance.creationTimestamp as any,
        googleProtobuf002.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.state) {
      _writer.writeEnum(4, _instance.state);
    }
    if (_instance.customerRoles && _instance.customerRoles.length) {
      _writer.writeRepeatedMessage(
        21,
        _instance.customerRoles as any,
        JobLogCustomerRole.serializeBinaryToWriter
      );
    }
    if (_instance.location) {
      _writer.writeEnum(24, _instance.location);
    }
    if (_instance.binaryConfig) {
      _writer.writeMessage(
        28,
        _instance.binaryConfig as any,
        googleProtobuf001.Any.serializeBinaryToWriter
      );
    }
    if (_instance.binary) {
      _writer.writeMessage(
        6,
        _instance.binary as any,
        JobBinary.serializeBinaryToWriter
      );
    }
    if (_instance.execution) {
      _writer.writeMessage(
        9,
        _instance.execution as any,
        JobLogExecution.serializeBinaryToWriter
      );
    }
    if (_instance.metadata) {
      _writer.writeMessage(
        10,
        _instance.metadata as any,
        JobMetadata.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(12, _instance.archived);
    }
    if (_instance.error) {
      _writer.writeMessage(
        13,
        _instance.error as any,
        JobError.serializeBinaryToWriter
      );
    }
    if (_instance.jobScheduleId) {
      _writer.writeString(26, _instance.jobScheduleId);
    }
    if (_instance.daskConfig) {
      _writer.writeMessage(
        27,
        _instance.daskConfig as any,
        DaskConfig.serializeBinaryToWriter
      );
    }
    if (_instance.projectId) {
      _writer.writeString(29, _instance.projectId);
    }
    if (_instance.postprocessing) {
      _writer.writeMessage(
        30,
        _instance.postprocessing as any,
        Postprocessing.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf002.Timestamp;
  private _creationTimestamp?: googleProtobuf002.Timestamp;
  private _state: JobState;
  private _customerRoles?: JobLogCustomerRole[];
  private _location: storage003.Location;
  private _binaryConfig?: googleProtobuf001.Any;
  private _binary?: JobBinary;
  private _execution?: JobLogExecution;
  private _metadata?: JobMetadata;
  private _archived: boolean;
  private _error?: JobError;
  private _jobScheduleId: string;
  private _daskConfig?: DaskConfig;
  private _projectId: string;
  private _postprocessing?: Postprocessing;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobLog to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobLog.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf002.Timestamp(_value.lastUpdate)
      : undefined;
    this.creationTimestamp = _value.creationTimestamp
      ? new googleProtobuf002.Timestamp(_value.creationTimestamp)
      : undefined;
    this.state = _value.state;
    this.customerRoles = (_value.customerRoles || []).map(
      (m) => new JobLogCustomerRole(m)
    );
    this.location = _value.location;
    this.binaryConfig = _value.binaryConfig
      ? new googleProtobuf001.Any(_value.binaryConfig)
      : undefined;
    this.binary = _value.binary ? new JobBinary(_value.binary) : undefined;
    this.execution = _value.execution
      ? new JobLogExecution(_value.execution)
      : undefined;
    this.metadata = _value.metadata
      ? new JobMetadata(_value.metadata)
      : undefined;
    this.archived = _value.archived;
    this.error = _value.error ? new JobError(_value.error) : undefined;
    this.jobScheduleId = _value.jobScheduleId;
    this.daskConfig = _value.daskConfig
      ? new DaskConfig(_value.daskConfig)
      : undefined;
    this.projectId = _value.projectId;
    this.postprocessing = _value.postprocessing
      ? new Postprocessing(_value.postprocessing)
      : undefined;
    JobLog.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf002.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf002.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get creationTimestamp(): googleProtobuf002.Timestamp | undefined {
    return this._creationTimestamp;
  }
  set creationTimestamp(value: googleProtobuf002.Timestamp | undefined) {
    this._creationTimestamp = value;
  }
  get state(): JobState {
    return this._state;
  }
  set state(value: JobState) {
    this._state = value;
  }
  get customerRoles(): JobLogCustomerRole[] | undefined {
    return this._customerRoles;
  }
  set customerRoles(value: JobLogCustomerRole[] | undefined) {
    this._customerRoles = value;
  }
  get location(): storage003.Location {
    return this._location;
  }
  set location(value: storage003.Location) {
    this._location = value;
  }
  get binaryConfig(): googleProtobuf001.Any | undefined {
    return this._binaryConfig;
  }
  set binaryConfig(value: googleProtobuf001.Any | undefined) {
    this._binaryConfig = value;
  }
  get binary(): JobBinary | undefined {
    return this._binary;
  }
  set binary(value: JobBinary | undefined) {
    this._binary = value;
  }
  get execution(): JobLogExecution | undefined {
    return this._execution;
  }
  set execution(value: JobLogExecution | undefined) {
    this._execution = value;
  }
  get metadata(): JobMetadata | undefined {
    return this._metadata;
  }
  set metadata(value: JobMetadata | undefined) {
    this._metadata = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get error(): JobError | undefined {
    return this._error;
  }
  set error(value: JobError | undefined) {
    this._error = value;
  }
  get jobScheduleId(): string {
    return this._jobScheduleId;
  }
  set jobScheduleId(value: string) {
    this._jobScheduleId = value;
  }
  get daskConfig(): DaskConfig | undefined {
    return this._daskConfig;
  }
  set daskConfig(value: DaskConfig | undefined) {
    this._daskConfig = value;
  }
  get projectId(): string {
    return this._projectId;
  }
  set projectId(value: string) {
    this._projectId = value;
  }
  get postprocessing(): Postprocessing | undefined {
    return this._postprocessing;
  }
  set postprocessing(value: Postprocessing | undefined) {
    this._postprocessing = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobLog.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobLog.AsObject {
    return {
      id: this.id,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      creationTimestamp: this.creationTimestamp
        ? this.creationTimestamp.toObject()
        : undefined,
      state: this.state,
      customerRoles: (this.customerRoles || []).map((m) => m.toObject()),
      location: this.location,
      binaryConfig: this.binaryConfig
        ? this.binaryConfig.toObject()
        : undefined,
      binary: this.binary ? this.binary.toObject() : undefined,
      execution: this.execution ? this.execution.toObject() : undefined,
      metadata: this.metadata ? this.metadata.toObject() : undefined,
      archived: this.archived,
      error: this.error ? this.error.toObject() : undefined,
      jobScheduleId: this.jobScheduleId,
      daskConfig: this.daskConfig ? this.daskConfig.toObject() : undefined,
      projectId: this.projectId,
      postprocessing: this.postprocessing
        ? this.postprocessing.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobLog.AsProtobufJSON {
    return {
      id: this.id,
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      creationTimestamp: this.creationTimestamp
        ? this.creationTimestamp.toProtobufJSON(options)
        : null,
      state:
        JobState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      customerRoles: (this.customerRoles || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      location:
        storage003.Location[
          this.location === null || this.location === undefined
            ? 0
            : this.location
        ],
      binaryConfig: this.binaryConfig
        ? this.binaryConfig.toProtobufJSON(options)
        : null,
      binary: this.binary ? this.binary.toProtobufJSON(options) : null,
      execution: this.execution ? this.execution.toProtobufJSON(options) : null,
      metadata: this.metadata ? this.metadata.toProtobufJSON(options) : null,
      archived: this.archived,
      error: this.error ? this.error.toProtobufJSON(options) : null,
      jobScheduleId: this.jobScheduleId,
      daskConfig: this.daskConfig
        ? this.daskConfig.toProtobufJSON(options)
        : null,
      projectId: this.projectId,
      postprocessing: this.postprocessing
        ? this.postprocessing.toProtobufJSON(options)
        : null,
    };
  }
}
export module JobLog {
  /**
   * Standard JavaScript object representation for JobLog
   */
  export interface AsObject {
    id: string;
    etag?: string;
    lastUpdate?: googleProtobuf002.Timestamp.AsObject;
    creationTimestamp?: googleProtobuf002.Timestamp.AsObject;
    state: JobState;
    customerRoles?: JobLogCustomerRole.AsObject[];
    location: storage003.Location;
    binaryConfig?: googleProtobuf001.Any.AsObject;
    binary?: JobBinary.AsObject;
    execution?: JobLogExecution.AsObject;
    metadata?: JobMetadata.AsObject;
    archived: boolean;
    error?: JobError.AsObject;
    jobScheduleId: string;
    daskConfig?: DaskConfig.AsObject;
    projectId: string;
    postprocessing?: Postprocessing.AsObject;
  }

  /**
   * Protobuf JSON representation for JobLog
   */
  export interface AsProtobufJSON {
    id: string;
    etag: string | null;
    lastUpdate: googleProtobuf002.Timestamp.AsProtobufJSON | null;
    creationTimestamp: googleProtobuf002.Timestamp.AsProtobufJSON | null;
    state: string;
    customerRoles: JobLogCustomerRole.AsProtobufJSON[] | null;
    location: string;
    binaryConfig: googleProtobuf001.Any.AsProtobufJSON | null;
    binary: JobBinary.AsProtobufJSON | null;
    execution: JobLogExecution.AsProtobufJSON | null;
    metadata: JobMetadata.AsProtobufJSON | null;
    archived: boolean;
    error: JobError.AsProtobufJSON | null;
    jobScheduleId: string;
    daskConfig: DaskConfig.AsProtobufJSON | null;
    projectId: string;
    postprocessing: Postprocessing.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.Postprocessing
 */
export class Postprocessing implements GrpcMessage {
  static id = 'storage.Postprocessing';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Postprocessing();
    Postprocessing.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Postprocessing) {
    _instance.enabled = _instance.enabled || false;
    _instance.postprocessingConfig =
      _instance.postprocessingConfig || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Postprocessing,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.enabled = _reader.readBool();
          break;
        case 2:
          _instance.postprocessingConfig = new googleProtobuf001.Any();
          _reader.readMessage(
            _instance.postprocessingConfig,
            googleProtobuf001.Any.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Postprocessing.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: Postprocessing,
    _writer: BinaryWriter
  ) {
    if (_instance.enabled) {
      _writer.writeBool(1, _instance.enabled);
    }
    if (_instance.postprocessingConfig) {
      _writer.writeMessage(
        2,
        _instance.postprocessingConfig as any,
        googleProtobuf001.Any.serializeBinaryToWriter
      );
    }
  }

  private _enabled: boolean;
  private _postprocessingConfig?: googleProtobuf001.Any;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Postprocessing to deeply clone from
   */
  constructor(_value?: RecursivePartial<Postprocessing.AsObject>) {
    _value = _value || {};
    this.enabled = _value.enabled;
    this.postprocessingConfig = _value.postprocessingConfig
      ? new googleProtobuf001.Any(_value.postprocessingConfig)
      : undefined;
    Postprocessing.refineValues(this);
  }
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
  }
  get postprocessingConfig(): googleProtobuf001.Any | undefined {
    return this._postprocessingConfig;
  }
  set postprocessingConfig(value: googleProtobuf001.Any | undefined) {
    this._postprocessingConfig = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Postprocessing.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Postprocessing.AsObject {
    return {
      enabled: this.enabled,
      postprocessingConfig: this.postprocessingConfig
        ? this.postprocessingConfig.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Postprocessing.AsProtobufJSON {
    return {
      enabled: this.enabled,
      postprocessingConfig: this.postprocessingConfig
        ? this.postprocessingConfig.toProtobufJSON(options)
        : null,
    };
  }
}
export module Postprocessing {
  /**
   * Standard JavaScript object representation for Postprocessing
   */
  export interface AsObject {
    enabled: boolean;
    postprocessingConfig?: googleProtobuf001.Any.AsObject;
  }

  /**
   * Protobuf JSON representation for Postprocessing
   */
  export interface AsProtobufJSON {
    enabled: boolean;
    postprocessingConfig: googleProtobuf001.Any.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.JobLogCustomerRole
 */
export class JobLogCustomerRole implements GrpcMessage {
  static id = 'storage.JobLogCustomerRole';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobLogCustomerRole();
    JobLogCustomerRole.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobLogCustomerRole) {
    _instance.customerId = _instance.customerId || '';
    _instance.role = _instance.role || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobLogCustomerRole,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.role = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    JobLogCustomerRole.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: JobLogCustomerRole,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.role) {
      _writer.writeEnum(2, _instance.role);
    }
  }

  private _customerId: string;
  private _role: JobLogCustomerRole.JobLogRole;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobLogCustomerRole to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobLogCustomerRole.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.role = _value.role;
    JobLogCustomerRole.refineValues(this);
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get role(): JobLogCustomerRole.JobLogRole {
    return this._role;
  }
  set role(value: JobLogCustomerRole.JobLogRole) {
    this._role = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobLogCustomerRole.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobLogCustomerRole.AsObject {
    return {
      customerId: this.customerId,
      role: this.role,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobLogCustomerRole.AsProtobufJSON {
    return {
      customerId: this.customerId,
      role: JobLogCustomerRole.JobLogRole[
        this.role === null || this.role === undefined ? 0 : this.role
      ],
    };
  }
}
export module JobLogCustomerRole {
  /**
   * Standard JavaScript object representation for JobLogCustomerRole
   */
  export interface AsObject {
    customerId: string;
    role: JobLogCustomerRole.JobLogRole;
  }

  /**
   * Protobuf JSON representation for JobLogCustomerRole
   */
  export interface AsProtobufJSON {
    customerId: string;
    role: string;
  }
  export enum JobLogRole {
    JOB_LOG_ROLE_UNSPECIFIED = 0,
    JOB_LOG_ROLE_READER = 1,
    JOB_LOG_ROLE_APPROVER = 2,
  }
}

/**
 * Message implementation for storage.JobMetadata
 */
export class JobMetadata implements GrpcMessage {
  static id = 'storage.JobMetadata';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobMetadata();
    JobMetadata.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobMetadata) {
    _instance.description = _instance.description || '';
    _instance.inputFileBytes = _instance.inputFileBytes || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobMetadata,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.description = _reader.readString();
          break;
        case 2:
          _instance.inputFileBytes = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    JobMetadata.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: JobMetadata,
    _writer: BinaryWriter
  ) {
    if (_instance.description) {
      _writer.writeString(1, _instance.description);
    }
    if (_instance.inputFileBytes) {
      _writer.writeInt64String(2, _instance.inputFileBytes);
    }
  }

  private _description: string;
  private _inputFileBytes: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobMetadata to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobMetadata.AsObject>) {
    _value = _value || {};
    this.description = _value.description;
    this.inputFileBytes = _value.inputFileBytes;
    JobMetadata.refineValues(this);
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get inputFileBytes(): string {
    return this._inputFileBytes;
  }
  set inputFileBytes(value: string) {
    this._inputFileBytes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobMetadata.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobMetadata.AsObject {
    return {
      description: this.description,
      inputFileBytes: this.inputFileBytes,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobMetadata.AsProtobufJSON {
    return {
      description: this.description,
      inputFileBytes: this.inputFileBytes,
    };
  }
}
export module JobMetadata {
  /**
   * Standard JavaScript object representation for JobMetadata
   */
  export interface AsObject {
    description: string;
    inputFileBytes: string;
  }

  /**
   * Protobuf JSON representation for JobMetadata
   */
  export interface AsProtobufJSON {
    description: string;
    inputFileBytes: string;
  }
}

/**
 * Message implementation for storage.JobBinary
 */
export class JobBinary implements GrpcMessage {
  static id = 'storage.JobBinary';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobBinary();
    JobBinary.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobBinary) {
    _instance.approvalRequestId = _instance.approvalRequestId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobBinary,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.approvalRequestId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    JobBinary.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: JobBinary, _writer: BinaryWriter) {
    if (_instance.approvalRequestId) {
      _writer.writeString(1, _instance.approvalRequestId);
    }
  }

  private _approvalRequestId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobBinary to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobBinary.AsObject>) {
    _value = _value || {};
    this.approvalRequestId = _value.approvalRequestId;
    JobBinary.refineValues(this);
  }
  get approvalRequestId(): string {
    return this._approvalRequestId;
  }
  set approvalRequestId(value: string) {
    this._approvalRequestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobBinary.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobBinary.AsObject {
    return {
      approvalRequestId: this.approvalRequestId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobBinary.AsProtobufJSON {
    return {
      approvalRequestId: this.approvalRequestId,
    };
  }
}
export module JobBinary {
  /**
   * Standard JavaScript object representation for JobBinary
   */
  export interface AsObject {
    approvalRequestId: string;
  }

  /**
   * Protobuf JSON representation for JobBinary
   */
  export interface AsProtobufJSON {
    approvalRequestId: string;
  }
}

/**
 * Message implementation for storage.JobLogExecution
 */
export class JobLogExecution implements GrpcMessage {
  static id = 'storage.JobLogExecution';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobLogExecution();
    JobLogExecution.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobLogExecution) {
    _instance.startTime = _instance.startTime || undefined;
    _instance.completeTime = _instance.completeTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobLogExecution,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startTime = new googleProtobuf002.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf002.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.completeTime = new googleProtobuf002.Timestamp();
          _reader.readMessage(
            _instance.completeTime,
            googleProtobuf002.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    JobLogExecution.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: JobLogExecution,
    _writer: BinaryWriter
  ) {
    if (_instance.startTime) {
      _writer.writeMessage(
        1,
        _instance.startTime as any,
        googleProtobuf002.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.completeTime) {
      _writer.writeMessage(
        2,
        _instance.completeTime as any,
        googleProtobuf002.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _startTime?: googleProtobuf002.Timestamp;
  private _completeTime?: googleProtobuf002.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobLogExecution to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobLogExecution.AsObject>) {
    _value = _value || {};
    this.startTime = _value.startTime
      ? new googleProtobuf002.Timestamp(_value.startTime)
      : undefined;
    this.completeTime = _value.completeTime
      ? new googleProtobuf002.Timestamp(_value.completeTime)
      : undefined;
    JobLogExecution.refineValues(this);
  }
  get startTime(): googleProtobuf002.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf002.Timestamp | undefined) {
    this._startTime = value;
  }
  get completeTime(): googleProtobuf002.Timestamp | undefined {
    return this._completeTime;
  }
  set completeTime(value: googleProtobuf002.Timestamp | undefined) {
    this._completeTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobLogExecution.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobLogExecution.AsObject {
    return {
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      completeTime: this.completeTime
        ? this.completeTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobLogExecution.AsProtobufJSON {
    return {
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      completeTime: this.completeTime
        ? this.completeTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module JobLogExecution {
  /**
   * Standard JavaScript object representation for JobLogExecution
   */
  export interface AsObject {
    startTime?: googleProtobuf002.Timestamp.AsObject;
    completeTime?: googleProtobuf002.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for JobLogExecution
   */
  export interface AsProtobufJSON {
    startTime: googleProtobuf002.Timestamp.AsProtobufJSON | null;
    completeTime: googleProtobuf002.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.JobError
 */
export class JobError implements GrpcMessage {
  static id = 'storage.JobError';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobError();
    JobError.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobError) {
    _instance.code = _instance.code || '0';
    _instance.reason = _instance.reason || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobError,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.code = _reader.readInt64String();
          break;
        case 2:
          _instance.reason = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    JobError.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: JobError, _writer: BinaryWriter) {
    if (_instance.code) {
      _writer.writeInt64String(1, _instance.code);
    }
    if (_instance.reason) {
      _writer.writeString(2, _instance.reason);
    }
  }

  private _code: string;
  private _reason: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobError to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobError.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.reason = _value.reason;
    JobError.refineValues(this);
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get reason(): string {
    return this._reason;
  }
  set reason(value: string) {
    this._reason = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobError.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobError.AsObject {
    return {
      code: this.code,
      reason: this.reason,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobError.AsProtobufJSON {
    return {
      code: this.code,
      reason: this.reason,
    };
  }
}
export module JobError {
  /**
   * Standard JavaScript object representation for JobError
   */
  export interface AsObject {
    code: string;
    reason: string;
  }

  /**
   * Protobuf JSON representation for JobError
   */
  export interface AsProtobufJSON {
    code: string;
    reason: string;
  }
}
