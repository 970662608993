import { Component, OnInit, Optional } from '@angular/core';
import { Auth, IdTokenResult, User } from '@angular/fire/auth';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { User as PublicUser } from 'types/user';

import { EventBusService, Events } from './services/event-bus.service';
import { NotificationService } from './services/notification.service';
import { UrlParamsService } from './services/url-params.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'attestation-portal';
  user: PublicUser | undefined;
  icons = [
    'home_menu',
    'job_activity',
    'knowledge_base',
    'prep_list',
    'your_binaries',
    'home_menu_selected',
    'job_activity_selected',
    'knowledge_base_selected',
    'prep_list_selected',
    'your_binaries_selected',
  ];

  constructor(
    @Optional() private auth: Auth,
    private events: EventBusService,
    private urlParamsService: UrlParamsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.initializeAuthStateListener();
    this.initializeUrlParams();
    this.initializeNotificationService();
  }

  private initializeAuthStateListener(): void {
    if (!this.auth) return;

    this.auth.onAuthStateChanged((user) => {
      let sessionTimeout: ReturnType<typeof setTimeout> | null = null;

      if (user) {
        this.setUserFromAuth(user);
        user.getIdTokenResult().then((idTokenResult) => {
          sessionTimeout = this.scheduleSessionTimeout(
            idTokenResult,
            sessionTimeout
          );
        });
      } else {
        this.clearUserSession(sessionTimeout);
      }
    });
  }

  private setUserFromAuth(user: User): void {
    const { photoURL, uid, email, emailVerified, displayName, tenantId } = user;

    this.user = {
      photoURL: photoURL ?? './assets/default-profile.png',
      uid,
      tenantId: tenantId,
      email: email ?? '',
      emailVerified,
      displayName: displayName ?? email ?? 'Unknown',
    };
  }

  private scheduleSessionTimeout(
    idTokenResult: IdTokenResult,
    sessionTimeout: ReturnType<typeof setTimeout> | null
  ) {
    if (idTokenResult.claims.auth_time) {
      const authTime = parseInt(idTokenResult.claims.auth_time) * 1000;
      const sessionDuration = 1000 * 60 * 60 * 24 * 14; // 14 days
      const millisecondsUntilExpiration =
        sessionDuration - (Date.now() - authTime);

      sessionTimeout = setTimeout(
        () =>
          this.router.navigate(['sign-out'], {
            queryParams: {
              email: this.user?.email,
              tenantId: this.user?.tenantId,
            },
          }),
        millisecondsUntilExpiration
      );
      return sessionTimeout;
    } else {
      return null;
    }
  }

  private clearUserSession(
    sessionTimeout: ReturnType<typeof setTimeout> | null
  ): void {
    this.user = undefined;
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
      sessionTimeout = null;
    }
  }

  private initializeUrlParams(): void {
    this.urlParamsService.parseUrl(document.URL);
  }

  private initializeNotificationService(): void {
    this.notificationService.requestPermission();
    this.notificationService.listen();
  }

  private loadIcons(iconKeys: string[]): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/${key}.svg`)
      );
    });
  }

  ngOnInit(): void {
    this.loadIcons(Object.values(this.icons));
    this.events.on(Events.UserProfileChange, (data: any) => {
      this.user = {
        ...this.user,
        displayName: `${data.firstName} ${data.lastName}`,
        photoURL: data.photoUrl,
      } as PublicUser;
    });
  }
}
