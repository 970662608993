import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MessageBoxProvider } from './message-box.provider';

export type MessageType = 'success' | 'error';
export type OnCloseCallback = ((messageType: MessageType) => void) | undefined;

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
  imports: [CommonModule, MatIconModule],
  standalone: true,
})
export class MessageBoxComponent {
  active = false;
  message = '';
  messageType: MessageType = 'success';

  constructor(private mbProvider: MessageBoxProvider) {
    this.mbProvider.show = this.show.bind(this);
    this.mbProvider.success = this.success.bind(this);
    this.mbProvider.error = this.error.bind(this);
    this.mbProvider.hide = this.hide.bind(this);
  }

  show(
    message: string,
    messageType: MessageType = 'success',
    onClose: OnCloseCallback = undefined,
    duration = 5000
  ) {
    this.message = message;
    this.messageType = messageType;
    this.active = true;

    setTimeout(() => {
      if (onClose) {
        onClose(this.messageType);
      }
      this.active = false;
    }, duration);
  }

  success(
    message: string,
    onClose: OnCloseCallback = undefined,
    duration = 5000
  ) {
    this.message = message;
    this.messageType = 'success';
    this.active = true;

    setTimeout(() => {
      if (onClose) {
        onClose(this.messageType);
      }
      this.active = false;
    }, duration);
  }

  error(
    message: string,
    onClose: OnCloseCallback = undefined,
    duration = 5000
  ) {
    this.message = message;
    this.messageType = 'error';
    this.active = true;

    setTimeout(() => {
      if (onClose) {
        onClose(this.messageType);
      }
      this.active = false;
    }, duration);
  }

  hide() {
    this.active = false;
  }
}
