import { Injectable } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import { CustomerService } from 'app/services/customer.service';
import { ContentType } from 'app/services/generated/src/main/proto/api/auth-service.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';

/**
 * Enumeration of permission roles.
 */
export const PermissionRoles = {
  PortalUser: 'PortalUser',
  PortalContributor: 'PortalContributor',
  PortalAdmin: 'Admin',
  Auditor: 'Auditor',
  Approver: 'Approver',
  AnonymTest: 'AnonymTest',
  AnonymAdmin: 'AnonymAdmin',
  AttestationPolicyUpdater: 'AttestationPolicyUpdater',
  EventProcessor: 'EventProcessor',
  CiCdRunner: 'CiCdRunner',
  BatchJob: 'BatchJob',
  AnonymJobAdmin: 'AnonymJobAdmin',
  AnonymPartnerManager: 'AnonymPartnerManager',
  PartnerAdmin: 'PartnerAdmin',
};

/**
 * Mapping of BinaryType to ContentType.
 */
const BinaryTypeToContentTypeMapping = [
  {
    binaryType: BinaryType.BINARY_TYPE_ATTRIBUTION,
    contentType: ContentType.CONTENT_TYPE_ATTRIBUTION_BINARY,
  },
  {
    binaryType: BinaryType.BINARY_TYPE_LIFT,
    contentType: ContentType.CONTENT_TYPE_LIFT_BINARY,
  },
];

/**
 * Service to manage user permissions and content type access.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  allowedContentTypes: ContentType[] | undefined;

  constructor(
    private auth: Auth,
    private customerService: CustomerService
  ) {}

  /**
   * Checks if the current user has any of the expected roles.
   *
   * @param expectedRoles - The roles to check against the user's roles.
   * @param user - The current user.
   * @returns A promise that resolves to true if the user has a matching role, otherwise false.
   */
  private async hasMatchingUserRoles(
    expectedRoles: string[],
    user: User
  ): Promise<boolean> {
    return user.getIdTokenResult().then((userToken) => {
      if (expectedRoles && expectedRoles.length == 0) {
        return true;
      }

      const {
        claims: { Roles = '' },
      } = userToken;

      const userRoles = (Roles as string).split(',');
      if (userRoles.length === 0) {
        return false;
      }
      const matchingRoles = userRoles.findIndex(
        (role: string) => expectedRoles.indexOf(role) >= 0
      );
      return matchingRoles >= 0;
    });
  }

  /**
   * Checks if the current user has any of the expected roles.
   *
   * @param expectedRoles - The roles to check against the user's roles.
   * @returns A promise that resolves to true if the user has a matching role, otherwise false.
   */
  public hasMatchingRoles(expectedRoles: string[]): Promise<boolean> {
    if (!this.auth.currentUser) {
      return new Promise((resolve) => resolve(false));
    }

    return this.hasMatchingUserRoles(expectedRoles, this.auth.currentUser);
  }

  /**
   * Checks if the current user has access to any of the expected content types.
   *
   * @param expectedContentType - The content types to check against the user's allowed content types.
   * @returns A promise that resolves to true if the user has access to a matching content type, otherwise false.
   */
  public async hasMatchingContentTypes(
    expectedContentType: ContentType[] | undefined
  ) {
    if (expectedContentType && expectedContentType.length > 0) {
      // If we don't have allowed content types loaded, get it from the customer.
      if (!this.allowedContentTypes) {
        const customer = await this.customerService.getCustomer();
        const allowedBinaryTypes = customer.customer
          ? customer.customer.allowedBinaryTypes
          : [];

        this.allowedContentTypes = [];
        allowedBinaryTypes.forEach((binaryType) => {
          this.allowedContentTypes!.push(this.lookupContentType(binaryType));
        });
      }
      return expectedContentType?.some((contentType) =>
        this.allowedContentTypes?.includes(contentType)
      );
    } else return true;
  }

  /**
   * Looks up the corresponding ContentType for a given BinaryType.
   *
   * @param binaryType - The binary type to look up.
   * @returns The corresponding content type.
   */
  public lookupContentType(binaryType: BinaryType) {
    let result = ContentType.CONTENT_TYPE_UNSPECIFIED;

    BinaryTypeToContentTypeMapping.forEach((mapping) => {
      if (mapping.binaryType === binaryType) result = mapping.contentType;
    });
    return result;
  }
}
