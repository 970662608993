/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
/**
 * Message implementation for storage.CustomerStorageCredential
 */
export class CustomerStorageCredential implements GrpcMessage {
  static id = 'storage.CustomerStorageCredential';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerStorageCredential();
    CustomerStorageCredential.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerStorageCredential) {
    _instance.id = _instance.id || '';
    _instance.customerId = _instance.customerId || '';
    _instance.keyName = _instance.keyName || '';
    _instance.keyVersion = _instance.keyVersion || '';

    _instance.etag = _instance.etag || '';
    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.archived = _instance.archived || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerStorageCredential,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.keyName = _reader.readString();
          break;
        case 4:
          _instance.keyVersion = _reader.readString();
          break;
        case 5:
          _instance.awsCredential = new AwsS3Credential();
          _reader.readMessage(
            _instance.awsCredential,
            AwsS3Credential.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.awsAssumeRole = new AwsAssumeRole();
          _reader.readMessage(
            _instance.awsAssumeRole,
            AwsAssumeRole.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.etag = _reader.readString();
          break;
        case 7:
          _instance.lastUpdate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.archived = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerStorageCredential.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerStorageCredential,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.keyName) {
      _writer.writeString(3, _instance.keyName);
    }
    if (_instance.keyVersion) {
      _writer.writeString(4, _instance.keyVersion);
    }
    if (_instance.awsCredential) {
      _writer.writeMessage(
        5,
        _instance.awsCredential as any,
        AwsS3Credential.serializeBinaryToWriter
      );
    }
    if (_instance.awsAssumeRole) {
      _writer.writeMessage(
        9,
        _instance.awsAssumeRole as any,
        AwsAssumeRole.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(6, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        7,
        _instance.lastUpdate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(8, _instance.archived);
    }
  }

  private _id: string;
  private _customerId: string;
  private _keyName: string;
  private _keyVersion: string;
  private _awsCredential?: AwsS3Credential;
  private _awsAssumeRole?: AwsAssumeRole;
  private _etag: string;
  private _lastUpdate?: googleProtobuf001.Timestamp;
  private _archived: boolean;

  private _storageCredential: CustomerStorageCredential.StorageCredentialCase =
    CustomerStorageCredential.StorageCredentialCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerStorageCredential to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerStorageCredential.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    this.keyName = _value.keyName;
    this.keyVersion = _value.keyVersion;
    this.awsCredential = _value.awsCredential
      ? new AwsS3Credential(_value.awsCredential)
      : undefined;
    this.awsAssumeRole = _value.awsAssumeRole
      ? new AwsAssumeRole(_value.awsAssumeRole)
      : undefined;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf001.Timestamp(_value.lastUpdate)
      : undefined;
    this.archived = _value.archived;
    CustomerStorageCredential.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get keyName(): string {
    return this._keyName;
  }
  set keyName(value: string) {
    this._keyName = value;
  }
  get keyVersion(): string {
    return this._keyVersion;
  }
  set keyVersion(value: string) {
    this._keyVersion = value;
  }
  get awsCredential(): AwsS3Credential | undefined {
    return this._awsCredential;
  }
  set awsCredential(value: AwsS3Credential | undefined) {
    if (value !== undefined && value !== null) {
      this._awsAssumeRole = undefined;
      this._storageCredential =
        CustomerStorageCredential.StorageCredentialCase.awsCredential;
    }
    this._awsCredential = value;
  }
  get awsAssumeRole(): AwsAssumeRole | undefined {
    return this._awsAssumeRole;
  }
  set awsAssumeRole(value: AwsAssumeRole | undefined) {
    if (value !== undefined && value !== null) {
      this._awsCredential = undefined;
      this._storageCredential =
        CustomerStorageCredential.StorageCredentialCase.awsAssumeRole;
    }
    this._awsAssumeRole = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf001.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get storageCredential() {
    return this._storageCredential;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerStorageCredential.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerStorageCredential.AsObject {
    return {
      id: this.id,
      customerId: this.customerId,
      keyName: this.keyName,
      keyVersion: this.keyVersion,
      awsCredential: this.awsCredential
        ? this.awsCredential.toObject()
        : undefined,
      awsAssumeRole: this.awsAssumeRole
        ? this.awsAssumeRole.toObject()
        : undefined,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      archived: this.archived,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerStorageCredential.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId,
      keyName: this.keyName,
      keyVersion: this.keyVersion,
      awsCredential: this.awsCredential
        ? this.awsCredential.toProtobufJSON(options)
        : null,
      awsAssumeRole: this.awsAssumeRole
        ? this.awsAssumeRole.toProtobufJSON(options)
        : null,
      etag: this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      archived: this.archived,
    };
  }
}
export module CustomerStorageCredential {
  /**
   * Standard JavaScript object representation for CustomerStorageCredential
   */
  export interface AsObject {
    id: string;
    customerId: string;
    keyName: string;
    keyVersion: string;
    awsCredential?: AwsS3Credential.AsObject;
    awsAssumeRole?: AwsAssumeRole.AsObject;
    etag: string;
    lastUpdate?: googleProtobuf001.Timestamp.AsObject;
    archived: boolean;
  }

  /**
   * Protobuf JSON representation for CustomerStorageCredential
   */
  export interface AsProtobufJSON {
    id: string;
    customerId: string;
    keyName: string;
    keyVersion: string;
    awsCredential: AwsS3Credential.AsProtobufJSON | null;
    awsAssumeRole: AwsAssumeRole.AsProtobufJSON | null;
    etag: string;
    lastUpdate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    archived: boolean;
  }
  export enum StorageCredentialCase {
    none = 0,
    awsCredential = 1,
    awsAssumeRole = 2,
  }
}

/**
 * Message implementation for storage.AwsS3Credential
 */
export class AwsS3Credential implements GrpcMessage {
  static id = 'storage.AwsS3Credential';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AwsS3Credential();
    AwsS3Credential.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AwsS3Credential) {
    _instance.accessKeyId = _instance.accessKeyId || '';
    _instance.secretAccessKey = _instance.secretAccessKey || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AwsS3Credential,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.accessKeyId = _reader.readString();
          break;
        case 2:
          _instance.secretAccessKey = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AwsS3Credential.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AwsS3Credential,
    _writer: BinaryWriter
  ) {
    if (_instance.accessKeyId) {
      _writer.writeString(1, _instance.accessKeyId);
    }
    if (_instance.secretAccessKey) {
      _writer.writeString(2, _instance.secretAccessKey);
    }
  }

  private _accessKeyId: string;
  private _secretAccessKey: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AwsS3Credential to deeply clone from
   */
  constructor(_value?: RecursivePartial<AwsS3Credential.AsObject>) {
    _value = _value || {};
    this.accessKeyId = _value.accessKeyId;
    this.secretAccessKey = _value.secretAccessKey;
    AwsS3Credential.refineValues(this);
  }
  get accessKeyId(): string {
    return this._accessKeyId;
  }
  set accessKeyId(value: string) {
    this._accessKeyId = value;
  }
  get secretAccessKey(): string {
    return this._secretAccessKey;
  }
  set secretAccessKey(value: string) {
    this._secretAccessKey = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AwsS3Credential.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AwsS3Credential.AsObject {
    return {
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AwsS3Credential.AsProtobufJSON {
    return {
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
    };
  }
}
export module AwsS3Credential {
  /**
   * Standard JavaScript object representation for AwsS3Credential
   */
  export interface AsObject {
    accessKeyId: string;
    secretAccessKey: string;
  }

  /**
   * Protobuf JSON representation for AwsS3Credential
   */
  export interface AsProtobufJSON {
    accessKeyId: string;
    secretAccessKey: string;
  }
}

/**
 * Message implementation for storage.AwsAssumeRole
 */
export class AwsAssumeRole implements GrpcMessage {
  static id = 'storage.AwsAssumeRole';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AwsAssumeRole();
    AwsAssumeRole.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AwsAssumeRole) {
    _instance.roleArn = _instance.roleArn || '';
    _instance.useExternalId = _instance.useExternalId || false;
    _instance.externalId = _instance.externalId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AwsAssumeRole,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.roleArn = _reader.readString();
          break;
        case 3:
          _instance.useExternalId = _reader.readBool();
          break;
        case 2:
          _instance.externalId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AwsAssumeRole.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AwsAssumeRole,
    _writer: BinaryWriter
  ) {
    if (_instance.roleArn) {
      _writer.writeString(1, _instance.roleArn);
    }
    if (_instance.useExternalId) {
      _writer.writeBool(3, _instance.useExternalId);
    }
    if (_instance.externalId) {
      _writer.writeString(2, _instance.externalId);
    }
  }

  private _roleArn: string;
  private _useExternalId: boolean;
  private _externalId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AwsAssumeRole to deeply clone from
   */
  constructor(_value?: RecursivePartial<AwsAssumeRole.AsObject>) {
    _value = _value || {};
    this.roleArn = _value.roleArn;
    this.useExternalId = _value.useExternalId;
    this.externalId = _value.externalId;
    AwsAssumeRole.refineValues(this);
  }
  get roleArn(): string {
    return this._roleArn;
  }
  set roleArn(value: string) {
    this._roleArn = value;
  }
  get useExternalId(): boolean {
    return this._useExternalId;
  }
  set useExternalId(value: boolean) {
    this._useExternalId = value;
  }
  get externalId(): string {
    return this._externalId;
  }
  set externalId(value: string) {
    this._externalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AwsAssumeRole.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AwsAssumeRole.AsObject {
    return {
      roleArn: this.roleArn,
      useExternalId: this.useExternalId,
      externalId: this.externalId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AwsAssumeRole.AsProtobufJSON {
    return {
      roleArn: this.roleArn,
      useExternalId: this.useExternalId,
      externalId: this.externalId,
    };
  }
}
export module AwsAssumeRole {
  /**
   * Standard JavaScript object representation for AwsAssumeRole
   */
  export interface AsObject {
    roleArn: string;
    useExternalId: boolean;
    externalId: string;
  }

  /**
   * Protobuf JSON representation for AwsAssumeRole
   */
  export interface AsProtobufJSON {
    roleArn: string;
    useExternalId: boolean;
    externalId: string;
  }
}

/**
 * Message implementation for storage.StorageStats
 */
export class StorageStats implements GrpcMessage {
  static id = 'storage.StorageStats';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StorageStats();
    StorageStats.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StorageStats) {
    _instance.totalFiles = _instance.totalFiles || '0';
    _instance.totalDirectories = _instance.totalDirectories || '0';
    _instance.totalSize = _instance.totalSize || '0';
    _instance.oldestFileTime = _instance.oldestFileTime || undefined;
    _instance.newestFileTime = _instance.newestFileTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StorageStats,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.totalFiles = _reader.readInt64String();
          break;
        case 2:
          _instance.totalDirectories = _reader.readInt64String();
          break;
        case 3:
          _instance.totalSize = _reader.readInt64String();
          break;
        case 4:
          _instance.oldestFileTime = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.oldestFileTime,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.newestFileTime = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.newestFileTime,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    StorageStats.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StorageStats,
    _writer: BinaryWriter
  ) {
    if (_instance.totalFiles) {
      _writer.writeInt64String(1, _instance.totalFiles);
    }
    if (_instance.totalDirectories) {
      _writer.writeInt64String(2, _instance.totalDirectories);
    }
    if (_instance.totalSize) {
      _writer.writeInt64String(3, _instance.totalSize);
    }
    if (_instance.oldestFileTime) {
      _writer.writeMessage(
        4,
        _instance.oldestFileTime as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.newestFileTime) {
      _writer.writeMessage(
        5,
        _instance.newestFileTime as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _totalFiles: string;
  private _totalDirectories: string;
  private _totalSize: string;
  private _oldestFileTime?: googleProtobuf001.Timestamp;
  private _newestFileTime?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StorageStats to deeply clone from
   */
  constructor(_value?: RecursivePartial<StorageStats.AsObject>) {
    _value = _value || {};
    this.totalFiles = _value.totalFiles;
    this.totalDirectories = _value.totalDirectories;
    this.totalSize = _value.totalSize;
    this.oldestFileTime = _value.oldestFileTime
      ? new googleProtobuf001.Timestamp(_value.oldestFileTime)
      : undefined;
    this.newestFileTime = _value.newestFileTime
      ? new googleProtobuf001.Timestamp(_value.newestFileTime)
      : undefined;
    StorageStats.refineValues(this);
  }
  get totalFiles(): string {
    return this._totalFiles;
  }
  set totalFiles(value: string) {
    this._totalFiles = value;
  }
  get totalDirectories(): string {
    return this._totalDirectories;
  }
  set totalDirectories(value: string) {
    this._totalDirectories = value;
  }
  get totalSize(): string {
    return this._totalSize;
  }
  set totalSize(value: string) {
    this._totalSize = value;
  }
  get oldestFileTime(): googleProtobuf001.Timestamp | undefined {
    return this._oldestFileTime;
  }
  set oldestFileTime(value: googleProtobuf001.Timestamp | undefined) {
    this._oldestFileTime = value;
  }
  get newestFileTime(): googleProtobuf001.Timestamp | undefined {
    return this._newestFileTime;
  }
  set newestFileTime(value: googleProtobuf001.Timestamp | undefined) {
    this._newestFileTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StorageStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StorageStats.AsObject {
    return {
      totalFiles: this.totalFiles,
      totalDirectories: this.totalDirectories,
      totalSize: this.totalSize,
      oldestFileTime: this.oldestFileTime
        ? this.oldestFileTime.toObject()
        : undefined,
      newestFileTime: this.newestFileTime
        ? this.newestFileTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StorageStats.AsProtobufJSON {
    return {
      totalFiles: this.totalFiles,
      totalDirectories: this.totalDirectories,
      totalSize: this.totalSize,
      oldestFileTime: this.oldestFileTime
        ? this.oldestFileTime.toProtobufJSON(options)
        : null,
      newestFileTime: this.newestFileTime
        ? this.newestFileTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module StorageStats {
  /**
   * Standard JavaScript object representation for StorageStats
   */
  export interface AsObject {
    totalFiles: string;
    totalDirectories: string;
    totalSize: string;
    oldestFileTime?: googleProtobuf001.Timestamp.AsObject;
    newestFileTime?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for StorageStats
   */
  export interface AsProtobufJSON {
    totalFiles: string;
    totalDirectories: string;
    totalSize: string;
    oldestFileTime: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    newestFileTime: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.StorageMetaData
 */
export class StorageMetaData implements GrpcMessage {
  static id = 'storage.StorageMetaData';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StorageMetaData();
    StorageMetaData.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StorageMetaData) {
    _instance.rootDirectory = _instance.rootDirectory || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StorageMetaData,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rootDirectory = new DirectoryEntry();
          _reader.readMessage(
            _instance.rootDirectory,
            DirectoryEntry.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    StorageMetaData.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StorageMetaData,
    _writer: BinaryWriter
  ) {
    if (_instance.rootDirectory) {
      _writer.writeMessage(
        1,
        _instance.rootDirectory as any,
        DirectoryEntry.serializeBinaryToWriter
      );
    }
  }

  private _rootDirectory?: DirectoryEntry;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StorageMetaData to deeply clone from
   */
  constructor(_value?: RecursivePartial<StorageMetaData.AsObject>) {
    _value = _value || {};
    this.rootDirectory = _value.rootDirectory
      ? new DirectoryEntry(_value.rootDirectory)
      : undefined;
    StorageMetaData.refineValues(this);
  }
  get rootDirectory(): DirectoryEntry | undefined {
    return this._rootDirectory;
  }
  set rootDirectory(value: DirectoryEntry | undefined) {
    this._rootDirectory = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StorageMetaData.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StorageMetaData.AsObject {
    return {
      rootDirectory: this.rootDirectory
        ? this.rootDirectory.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StorageMetaData.AsProtobufJSON {
    return {
      rootDirectory: this.rootDirectory
        ? this.rootDirectory.toProtobufJSON(options)
        : null,
    };
  }
}
export module StorageMetaData {
  /**
   * Standard JavaScript object representation for StorageMetaData
   */
  export interface AsObject {
    rootDirectory?: DirectoryEntry.AsObject;
  }

  /**
   * Protobuf JSON representation for StorageMetaData
   */
  export interface AsProtobufJSON {
    rootDirectory: DirectoryEntry.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.DirectoryEntry
 */
export class DirectoryEntry implements GrpcMessage {
  static id = 'storage.DirectoryEntry';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DirectoryEntry();
    DirectoryEntry.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DirectoryEntry) {
    _instance.name = _instance.name || '';
    _instance.fullPath = _instance.fullPath || '';
    _instance.lastModifiedTime = _instance.lastModifiedTime || undefined;
    _instance.subdirectories = _instance.subdirectories || [];
    _instance.files = _instance.files || [];
    _instance.directoryStats = _instance.directoryStats || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DirectoryEntry,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.fullPath = _reader.readString();
          break;
        case 3:
          _instance.lastModifiedTime = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastModifiedTime,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          const messageInitializer4 = new DirectoryEntry();
          _reader.readMessage(
            messageInitializer4,
            DirectoryEntry.deserializeBinaryFromReader
          );
          (_instance.subdirectories = _instance.subdirectories || []).push(
            messageInitializer4
          );
          break;
        case 5:
          const messageInitializer5 = new FileEntry();
          _reader.readMessage(
            messageInitializer5,
            FileEntry.deserializeBinaryFromReader
          );
          (_instance.files = _instance.files || []).push(messageInitializer5);
          break;
        case 6:
          _instance.directoryStats = new StorageStats();
          _reader.readMessage(
            _instance.directoryStats,
            StorageStats.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DirectoryEntry.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DirectoryEntry,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.fullPath) {
      _writer.writeString(2, _instance.fullPath);
    }
    if (_instance.lastModifiedTime) {
      _writer.writeMessage(
        3,
        _instance.lastModifiedTime as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.subdirectories && _instance.subdirectories.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.subdirectories as any,
        DirectoryEntry.serializeBinaryToWriter
      );
    }
    if (_instance.files && _instance.files.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.files as any,
        FileEntry.serializeBinaryToWriter
      );
    }
    if (_instance.directoryStats) {
      _writer.writeMessage(
        6,
        _instance.directoryStats as any,
        StorageStats.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _fullPath: string;
  private _lastModifiedTime?: googleProtobuf001.Timestamp;
  private _subdirectories?: DirectoryEntry[];
  private _files?: FileEntry[];
  private _directoryStats?: StorageStats;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DirectoryEntry to deeply clone from
   */
  constructor(_value?: RecursivePartial<DirectoryEntry.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.fullPath = _value.fullPath;
    this.lastModifiedTime = _value.lastModifiedTime
      ? new googleProtobuf001.Timestamp(_value.lastModifiedTime)
      : undefined;
    this.subdirectories = (_value.subdirectories || []).map(
      (m) => new DirectoryEntry(m)
    );
    this.files = (_value.files || []).map((m) => new FileEntry(m));
    this.directoryStats = _value.directoryStats
      ? new StorageStats(_value.directoryStats)
      : undefined;
    DirectoryEntry.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get fullPath(): string {
    return this._fullPath;
  }
  set fullPath(value: string) {
    this._fullPath = value;
  }
  get lastModifiedTime(): googleProtobuf001.Timestamp | undefined {
    return this._lastModifiedTime;
  }
  set lastModifiedTime(value: googleProtobuf001.Timestamp | undefined) {
    this._lastModifiedTime = value;
  }
  get subdirectories(): DirectoryEntry[] | undefined {
    return this._subdirectories;
  }
  set subdirectories(value: DirectoryEntry[] | undefined) {
    this._subdirectories = value;
  }
  get files(): FileEntry[] | undefined {
    return this._files;
  }
  set files(value: FileEntry[] | undefined) {
    this._files = value;
  }
  get directoryStats(): StorageStats | undefined {
    return this._directoryStats;
  }
  set directoryStats(value: StorageStats | undefined) {
    this._directoryStats = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DirectoryEntry.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DirectoryEntry.AsObject {
    return {
      name: this.name,
      fullPath: this.fullPath,
      lastModifiedTime: this.lastModifiedTime
        ? this.lastModifiedTime.toObject()
        : undefined,
      subdirectories: (this.subdirectories || []).map((m) => m.toObject()),
      files: (this.files || []).map((m) => m.toObject()),
      directoryStats: this.directoryStats
        ? this.directoryStats.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DirectoryEntry.AsProtobufJSON {
    return {
      name: this.name,
      fullPath: this.fullPath,
      lastModifiedTime: this.lastModifiedTime
        ? this.lastModifiedTime.toProtobufJSON(options)
        : null,
      subdirectories: (this.subdirectories || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      files: (this.files || []).map((m) => m.toProtobufJSON(options)),
      directoryStats: this.directoryStats
        ? this.directoryStats.toProtobufJSON(options)
        : null,
    };
  }
}
export module DirectoryEntry {
  /**
   * Standard JavaScript object representation for DirectoryEntry
   */
  export interface AsObject {
    name: string;
    fullPath: string;
    lastModifiedTime?: googleProtobuf001.Timestamp.AsObject;
    subdirectories?: DirectoryEntry.AsObject[];
    files?: FileEntry.AsObject[];
    directoryStats?: StorageStats.AsObject;
  }

  /**
   * Protobuf JSON representation for DirectoryEntry
   */
  export interface AsProtobufJSON {
    name: string;
    fullPath: string;
    lastModifiedTime: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    subdirectories: DirectoryEntry.AsProtobufJSON[] | null;
    files: FileEntry.AsProtobufJSON[] | null;
    directoryStats: StorageStats.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.FileEntry
 */
export class FileEntry implements GrpcMessage {
  static id = 'storage.FileEntry';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FileEntry();
    FileEntry.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FileEntry) {
    _instance.name = _instance.name || '';
    _instance.fullPath = _instance.fullPath || '';
    _instance.size = _instance.size || '0';
    _instance.contentType = _instance.contentType || '';
    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.version = _instance.version || '';
    _instance.etag = _instance.etag || '';
    _instance.metadata = _instance.metadata || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FileEntry,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.fullPath = _reader.readString();
          break;
        case 3:
          _instance.size = _reader.readInt64String();
          break;
        case 4:
          _instance.contentType = _reader.readString();
          break;
        case 5:
          _instance.lastUpdate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.version = _reader.readString();
          break;
        case 7:
          _instance.etag = _reader.readString();
          break;
        case 8:
          const msg_8 = {} as any;
          _reader.readMessage(
            msg_8,
            FileEntry.MetadataEntry.deserializeBinaryFromReader
          );
          _instance.metadata = _instance.metadata || {};
          _instance.metadata[msg_8.key] = msg_8.value;
          break;
        default:
          _reader.skipField();
      }
    }

    FileEntry.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: FileEntry, _writer: BinaryWriter) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.fullPath) {
      _writer.writeString(2, _instance.fullPath);
    }
    if (_instance.size) {
      _writer.writeInt64String(3, _instance.size);
    }
    if (_instance.contentType) {
      _writer.writeString(4, _instance.contentType);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        5,
        _instance.lastUpdate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.version) {
      _writer.writeString(6, _instance.version);
    }
    if (_instance.etag) {
      _writer.writeString(7, _instance.etag);
    }
    if (!!_instance.metadata) {
      const keys_8 = Object.keys(_instance.metadata as any);

      if (keys_8.length) {
        const repeated_8 = keys_8
          .map((key) => ({ key: key, value: (_instance.metadata as any)[key] }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          8,
          repeated_8,
          FileEntry.MetadataEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _name: string;
  private _fullPath: string;
  private _size: string;
  private _contentType: string;
  private _lastUpdate?: googleProtobuf001.Timestamp;
  private _version: string;
  private _etag: string;
  private _metadata: { [prop: string]: string };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FileEntry to deeply clone from
   */
  constructor(_value?: RecursivePartial<FileEntry.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.fullPath = _value.fullPath;
    this.size = _value.size;
    this.contentType = _value.contentType;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf001.Timestamp(_value.lastUpdate)
      : undefined;
    this.version = _value.version;
    this.etag = _value.etag;
    (this.metadata = _value!.metadata
      ? Object.keys(_value!.metadata).reduce(
          (r, k) => ({ ...r, [k]: _value!.metadata![k] }),
          {}
        )
      : {}),
      FileEntry.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get fullPath(): string {
    return this._fullPath;
  }
  set fullPath(value: string) {
    this._fullPath = value;
  }
  get size(): string {
    return this._size;
  }
  set size(value: string) {
    this._size = value;
  }
  get contentType(): string {
    return this._contentType;
  }
  set contentType(value: string) {
    this._contentType = value;
  }
  get lastUpdate(): googleProtobuf001.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get metadata(): { [prop: string]: string } {
    return this._metadata;
  }
  set metadata(value: { [prop: string]: string }) {
    this._metadata = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FileEntry.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FileEntry.AsObject {
    return {
      name: this.name,
      fullPath: this.fullPath,
      size: this.size,
      contentType: this.contentType,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      version: this.version,
      etag: this.etag,
      metadata: this.metadata
        ? Object.keys(this.metadata).reduce(
            (r, k) => ({ ...r, [k]: this.metadata![k] }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FileEntry.AsProtobufJSON {
    return {
      name: this.name,
      fullPath: this.fullPath,
      size: this.size,
      contentType: this.contentType,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      version: this.version,
      etag: this.etag,
      metadata: this.metadata
        ? Object.keys(this.metadata).reduce(
            (r, k) => ({ ...r, [k]: this.metadata![k] }),
            {}
          )
        : {},
    };
  }
}
export module FileEntry {
  /**
   * Standard JavaScript object representation for FileEntry
   */
  export interface AsObject {
    name: string;
    fullPath: string;
    size: string;
    contentType: string;
    lastUpdate?: googleProtobuf001.Timestamp.AsObject;
    version: string;
    etag: string;
    metadata: { [prop: string]: string };
  }

  /**
   * Protobuf JSON representation for FileEntry
   */
  export interface AsProtobufJSON {
    name: string;
    fullPath: string;
    size: string;
    contentType: string;
    lastUpdate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    version: string;
    etag: string;
    metadata: { [prop: string]: string };
  }

  /**
   * Message implementation for storage.FileEntry.MetadataEntry
   */
  export class MetadataEntry implements GrpcMessage {
    static id = 'storage.FileEntry.MetadataEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new MetadataEntry();
      MetadataEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: MetadataEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: MetadataEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      MetadataEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: MetadataEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of MetadataEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<MetadataEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      MetadataEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      MetadataEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): MetadataEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): MetadataEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value,
      };
    }
  }
  export module MetadataEntry {
    /**
     * Standard JavaScript object representation for MetadataEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for MetadataEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}
