<div class="background">
  <div class="card">
    <a href="sign-in-options">
      <app-anonym-logo></app-anonym-logo>
    </a>
    <hr />
    <div class="body">
      <h1>Login</h1>

      <form ngNativeValidate (submit)="handleSignIn()" [formGroup]="signInForm">
        <label>Email</label>
        <input
          type="email"
          tabindex="1"
          placeholder="Type in your Email"
          formControlName="email"
          data-testid="email"
          readonly
        />

        <label>Password</label>
        <input
          type="password"
          tabindex="2"
          placeholder="Type in your Passcode"
          formControlName="password"
          minlength="6"
          autocomplete="current-password"
          required
          data-testid="password"
        />
        <div class="row">
          <a id="forgot" (click)="handlePasswordReset()">Forgot Password?</a>
        </div>

        <app-message-box></app-message-box>

        <button
          type="submit"
          color="primary"
          mat-flat-button
          [disabled]="isLoading"
          data-testid="loginSubmit"
        >
          {{ isLoading ? null : 'Login' }}
          <span *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="24"></mat-spinner>
          </span>
        </button>
      </form>

      <div *ngIf="allowSignInWithSaml">
        <hr />
        <div id="signin-okta-section">
          <div
            id="signin-okta-group"
            data-testid="signInWithSaml"
            (click)="handleIdentityProviderSignIn()"
          >
            <mat-icon class="lock-icon">vpn_lock</mat-icon>
            <div id="signin-with-okta">
              <div id="signin-with-okta-text">
                Sign in with identity provider.
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p>Have an issue? <a href="mailto:contact@anonymco.com">Contact Us</a></p>
    </div>
  </div>
</div>
