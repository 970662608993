/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as attribution002 from '../../../../src/main/proto/attribution/advertiser.pb';
import * as storage003 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage004 from '../../../../src/main/proto/storage/commons.pb';
import * as storage005 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
import * as storage006 from '../../../../src/main/proto/storage/schema-annotations.pb';
export enum State {
  STATE_PROJECT_UNSPECIFIED = 0,
  STATE_PROJECT_DRAFT = 1,
  STATE_PROJECT_READY = 2,
}
/**
 * Message implementation for storage.Project
 */
export class Project implements GrpcMessage {
  static id = 'storage.Project';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Project();
    Project.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Project) {
    _instance.id = _instance.id || '';

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.lastUpdatedEmail = _instance.lastUpdatedEmail || '';
    _instance.creationTimestamp = _instance.creationTimestamp || undefined;
    _instance.createdEmail = _instance.createdEmail || '';
    _instance.binaryType = _instance.binaryType || 0;
    _instance.location = _instance.location || 0;
    _instance.publisherId = _instance.publisherId || '';
    _instance.advertiserId = _instance.advertiserId || '';
    _instance.customerDataSets = _instance.customerDataSets || {};
    _instance.campaignStartDay = _instance.campaignStartDay || undefined;
    _instance.campaignEndDay = _instance.campaignEndDay || undefined;
    _instance.campaignTimezoneOffsetMinutes =
      _instance.campaignTimezoneOffsetMinutes || 0;
    _instance.postPeriodDays = _instance.postPeriodDays || 0;
    _instance.lookbackWindow = _instance.lookbackWindow || undefined;
    _instance.matchKeys = _instance.matchKeys || [];
    _instance.advertiserEvents = _instance.advertiserEvents || [];
    _instance.notes = _instance.notes || '';
    _instance.archived = _instance.archived || false;
    _instance.state = _instance.state || 0;
    _instance.studyId = _instance.studyId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Project,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        case 3:
          _instance.lastUpdate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.lastUpdatedEmail = _reader.readString();
          break;
        case 5:
          _instance.creationTimestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.creationTimestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.createdEmail = _reader.readString();
          break;
        case 7:
          _instance.binaryType = _reader.readEnum();
          break;
        case 8:
          _instance.location = _reader.readEnum();
          break;
        case 9:
          _instance.publisherId = _reader.readString();
          break;
        case 10:
          _instance.advertiserId = _reader.readString();
          break;
        case 11:
          const msg_11 = {} as any;
          _reader.readMessage(
            msg_11,
            Project.CustomerDataSetsEntry.deserializeBinaryFromReader
          );
          _instance.customerDataSets = _instance.customerDataSets || {};
          _instance.customerDataSets[msg_11.key] = msg_11.value;
          break;
        case 12:
          _instance.campaignStartDay = new Date();
          _reader.readMessage(
            _instance.campaignStartDay,
            Date.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.campaignEndDay = new Date();
          _reader.readMessage(
            _instance.campaignEndDay,
            Date.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.campaignTimezoneOffsetMinutes = _reader.readInt32();
          break;
        case 15:
          _instance.postPeriodDays = _reader.readInt32();
          break;
        case 16:
          _instance.lookbackWindow = new LookbackWindow();
          _reader.readMessage(
            _instance.lookbackWindow,
            LookbackWindow.deserializeBinaryFromReader
          );
          break;
        case 17:
          (_instance.matchKeys = _instance.matchKeys || []).push(
            _reader.readString()
          );
          break;
        case 18:
          const messageInitializer18 = new AdvertiserEvents();
          _reader.readMessage(
            messageInitializer18,
            AdvertiserEvents.deserializeBinaryFromReader
          );
          (_instance.advertiserEvents = _instance.advertiserEvents || []).push(
            messageInitializer18
          );
          break;
        case 19:
          _instance.notes = _reader.readString();
          break;
        case 20:
          _instance.archived = _reader.readBool();
          break;
        case 21:
          _instance.state = _reader.readEnum();
          break;
        case 22:
          _instance.studyId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Project.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Project, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(2, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        3,
        _instance.lastUpdate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastUpdatedEmail) {
      _writer.writeString(4, _instance.lastUpdatedEmail);
    }
    if (_instance.creationTimestamp) {
      _writer.writeMessage(
        5,
        _instance.creationTimestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.createdEmail) {
      _writer.writeString(6, _instance.createdEmail);
    }
    if (_instance.binaryType) {
      _writer.writeEnum(7, _instance.binaryType);
    }
    if (_instance.location) {
      _writer.writeEnum(8, _instance.location);
    }
    if (_instance.publisherId) {
      _writer.writeString(9, _instance.publisherId);
    }
    if (_instance.advertiserId) {
      _writer.writeString(10, _instance.advertiserId);
    }
    if (!!_instance.customerDataSets) {
      const keys_11 = Object.keys(_instance.customerDataSets as any);

      if (keys_11.length) {
        const repeated_11 = keys_11
          .map((key) => ({
            key: key,
            value: (_instance.customerDataSets as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          11,
          repeated_11,
          Project.CustomerDataSetsEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.campaignStartDay) {
      _writer.writeMessage(
        12,
        _instance.campaignStartDay as any,
        Date.serializeBinaryToWriter
      );
    }
    if (_instance.campaignEndDay) {
      _writer.writeMessage(
        13,
        _instance.campaignEndDay as any,
        Date.serializeBinaryToWriter
      );
    }
    if (_instance.campaignTimezoneOffsetMinutes) {
      _writer.writeInt32(14, _instance.campaignTimezoneOffsetMinutes);
    }
    if (_instance.postPeriodDays) {
      _writer.writeInt32(15, _instance.postPeriodDays);
    }
    if (_instance.lookbackWindow) {
      _writer.writeMessage(
        16,
        _instance.lookbackWindow as any,
        LookbackWindow.serializeBinaryToWriter
      );
    }
    if (_instance.matchKeys && _instance.matchKeys.length) {
      _writer.writeRepeatedString(17, _instance.matchKeys);
    }
    if (_instance.advertiserEvents && _instance.advertiserEvents.length) {
      _writer.writeRepeatedMessage(
        18,
        _instance.advertiserEvents as any,
        AdvertiserEvents.serializeBinaryToWriter
      );
    }
    if (_instance.notes) {
      _writer.writeString(19, _instance.notes);
    }
    if (_instance.archived) {
      _writer.writeBool(20, _instance.archived);
    }
    if (_instance.state) {
      _writer.writeEnum(21, _instance.state);
    }
    if (_instance.studyId) {
      _writer.writeString(22, _instance.studyId);
    }
  }

  private _id: string;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf000.Timestamp;
  private _lastUpdatedEmail: string;
  private _creationTimestamp?: googleProtobuf000.Timestamp;
  private _createdEmail: string;
  private _binaryType: storage003.BinaryType;
  private _location: storage004.Location;
  private _publisherId: string;
  private _advertiserId: string;
  private _customerDataSets: {
    [prop: string]: storage005.CustomerDataSetReference;
  };
  private _campaignStartDay?: Date;
  private _campaignEndDay?: Date;
  private _campaignTimezoneOffsetMinutes: number;
  private _postPeriodDays: number;
  private _lookbackWindow?: LookbackWindow;
  private _matchKeys: string[];
  private _advertiserEvents?: AdvertiserEvents[];
  private _notes: string;
  private _archived: boolean;
  private _state: State;
  private _studyId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Project to deeply clone from
   */
  constructor(_value?: RecursivePartial<Project.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf000.Timestamp(_value.lastUpdate)
      : undefined;
    this.lastUpdatedEmail = _value.lastUpdatedEmail;
    this.creationTimestamp = _value.creationTimestamp
      ? new googleProtobuf000.Timestamp(_value.creationTimestamp)
      : undefined;
    this.createdEmail = _value.createdEmail;
    this.binaryType = _value.binaryType;
    this.location = _value.location;
    this.publisherId = _value.publisherId;
    this.advertiserId = _value.advertiserId;
    (this.customerDataSets = _value!.customerDataSets
      ? Object.keys(_value!.customerDataSets).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.customerDataSets![k]
              ? new storage005.CustomerDataSetReference(
                  _value!.customerDataSets![k]
                )
              : undefined,
          }),
          {}
        )
      : {}),
      (this.campaignStartDay = _value.campaignStartDay
        ? new Date(_value.campaignStartDay)
        : undefined);
    this.campaignEndDay = _value.campaignEndDay
      ? new Date(_value.campaignEndDay)
      : undefined;
    this.campaignTimezoneOffsetMinutes = _value.campaignTimezoneOffsetMinutes;
    this.postPeriodDays = _value.postPeriodDays;
    this.lookbackWindow = _value.lookbackWindow
      ? new LookbackWindow(_value.lookbackWindow)
      : undefined;
    this.matchKeys = (_value.matchKeys || []).slice();
    this.advertiserEvents = (_value.advertiserEvents || []).map(
      (m) => new AdvertiserEvents(m)
    );
    this.notes = _value.notes;
    this.archived = _value.archived;
    this.state = _value.state;
    this.studyId = _value.studyId;
    Project.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get lastUpdatedEmail(): string {
    return this._lastUpdatedEmail;
  }
  set lastUpdatedEmail(value: string) {
    this._lastUpdatedEmail = value;
  }
  get creationTimestamp(): googleProtobuf000.Timestamp | undefined {
    return this._creationTimestamp;
  }
  set creationTimestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._creationTimestamp = value;
  }
  get createdEmail(): string {
    return this._createdEmail;
  }
  set createdEmail(value: string) {
    this._createdEmail = value;
  }
  get binaryType(): storage003.BinaryType {
    return this._binaryType;
  }
  set binaryType(value: storage003.BinaryType) {
    this._binaryType = value;
  }
  get location(): storage004.Location {
    return this._location;
  }
  set location(value: storage004.Location) {
    this._location = value;
  }
  get publisherId(): string {
    return this._publisherId;
  }
  set publisherId(value: string) {
    this._publisherId = value;
  }
  get advertiserId(): string {
    return this._advertiserId;
  }
  set advertiserId(value: string) {
    this._advertiserId = value;
  }
  get customerDataSets(): {
    [prop: string]: storage005.CustomerDataSetReference;
  } {
    return this._customerDataSets;
  }
  set customerDataSets(value: {
    [prop: string]: storage005.CustomerDataSetReference;
  }) {
    this._customerDataSets = value;
  }
  get campaignStartDay(): Date | undefined {
    return this._campaignStartDay;
  }
  set campaignStartDay(value: Date | undefined) {
    this._campaignStartDay = value;
  }
  get campaignEndDay(): Date | undefined {
    return this._campaignEndDay;
  }
  set campaignEndDay(value: Date | undefined) {
    this._campaignEndDay = value;
  }
  get campaignTimezoneOffsetMinutes(): number {
    return this._campaignTimezoneOffsetMinutes;
  }
  set campaignTimezoneOffsetMinutes(value: number) {
    this._campaignTimezoneOffsetMinutes = value;
  }
  get postPeriodDays(): number {
    return this._postPeriodDays;
  }
  set postPeriodDays(value: number) {
    this._postPeriodDays = value;
  }
  get lookbackWindow(): LookbackWindow | undefined {
    return this._lookbackWindow;
  }
  set lookbackWindow(value: LookbackWindow | undefined) {
    this._lookbackWindow = value;
  }
  get matchKeys(): string[] {
    return this._matchKeys;
  }
  set matchKeys(value: string[]) {
    this._matchKeys = value;
  }
  get advertiserEvents(): AdvertiserEvents[] | undefined {
    return this._advertiserEvents;
  }
  set advertiserEvents(value: AdvertiserEvents[] | undefined) {
    this._advertiserEvents = value;
  }
  get notes(): string {
    return this._notes;
  }
  set notes(value: string) {
    this._notes = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get state(): State {
    return this._state;
  }
  set state(value: State) {
    this._state = value;
  }
  get studyId(): string {
    return this._studyId;
  }
  set studyId(value: string) {
    this._studyId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Project.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Project.AsObject {
    return {
      id: this.id,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      lastUpdatedEmail: this.lastUpdatedEmail,
      creationTimestamp: this.creationTimestamp
        ? this.creationTimestamp.toObject()
        : undefined,
      createdEmail: this.createdEmail,
      binaryType: this.binaryType,
      location: this.location,
      publisherId: this.publisherId,
      advertiserId: this.advertiserId,
      customerDataSets: this.customerDataSets
        ? Object.keys(this.customerDataSets).reduce(
            (r, k) => ({
              ...r,
              [k]: this.customerDataSets![k]
                ? this.customerDataSets![k].toObject()
                : undefined,
            }),
            {}
          )
        : {},
      campaignStartDay: this.campaignStartDay
        ? this.campaignStartDay.toObject()
        : undefined,
      campaignEndDay: this.campaignEndDay
        ? this.campaignEndDay.toObject()
        : undefined,
      campaignTimezoneOffsetMinutes: this.campaignTimezoneOffsetMinutes,
      postPeriodDays: this.postPeriodDays,
      lookbackWindow: this.lookbackWindow
        ? this.lookbackWindow.toObject()
        : undefined,
      matchKeys: (this.matchKeys || []).slice(),
      advertiserEvents: (this.advertiserEvents || []).map((m) => m.toObject()),
      notes: this.notes,
      archived: this.archived,
      state: this.state,
      studyId: this.studyId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Project.AsProtobufJSON {
    return {
      id: this.id,
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      lastUpdatedEmail: this.lastUpdatedEmail,
      creationTimestamp: this.creationTimestamp
        ? this.creationTimestamp.toProtobufJSON(options)
        : null,
      createdEmail: this.createdEmail,
      binaryType:
        storage003.BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
      location:
        storage004.Location[
          this.location === null || this.location === undefined
            ? 0
            : this.location
        ],
      publisherId: this.publisherId,
      advertiserId: this.advertiserId,
      customerDataSets: this.customerDataSets
        ? Object.keys(this.customerDataSets).reduce(
            (r, k) => ({
              ...r,
              [k]: this.customerDataSets![k]
                ? this.customerDataSets![k].toJSON()
                : null,
            }),
            {}
          )
        : {},
      campaignStartDay: this.campaignStartDay
        ? this.campaignStartDay.toProtobufJSON(options)
        : null,
      campaignEndDay: this.campaignEndDay
        ? this.campaignEndDay.toProtobufJSON(options)
        : null,
      campaignTimezoneOffsetMinutes: this.campaignTimezoneOffsetMinutes,
      postPeriodDays: this.postPeriodDays,
      lookbackWindow: this.lookbackWindow
        ? this.lookbackWindow.toProtobufJSON(options)
        : null,
      matchKeys: (this.matchKeys || []).slice(),
      advertiserEvents: (this.advertiserEvents || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      notes: this.notes,
      archived: this.archived,
      state:
        State[this.state === null || this.state === undefined ? 0 : this.state],
      studyId: this.studyId,
    };
  }
}
export module Project {
  /**
   * Standard JavaScript object representation for Project
   */
  export interface AsObject {
    id: string;
    etag?: string;
    lastUpdate?: googleProtobuf000.Timestamp.AsObject;
    lastUpdatedEmail: string;
    creationTimestamp?: googleProtobuf000.Timestamp.AsObject;
    createdEmail: string;
    binaryType: storage003.BinaryType;
    location: storage004.Location;
    publisherId: string;
    advertiserId: string;
    customerDataSets: { [prop: string]: storage005.CustomerDataSetReference };
    campaignStartDay?: Date.AsObject;
    campaignEndDay?: Date.AsObject;
    campaignTimezoneOffsetMinutes: number;
    postPeriodDays: number;
    lookbackWindow?: LookbackWindow.AsObject;
    matchKeys: string[];
    advertiserEvents?: AdvertiserEvents.AsObject[];
    notes: string;
    archived: boolean;
    state: State;
    studyId: string;
  }

  /**
   * Protobuf JSON representation for Project
   */
  export interface AsProtobufJSON {
    id: string;
    etag: string | null;
    lastUpdate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    lastUpdatedEmail: string;
    creationTimestamp: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    createdEmail: string;
    binaryType: string;
    location: string;
    publisherId: string;
    advertiserId: string;
    customerDataSets: { [prop: string]: storage005.CustomerDataSetReference };
    campaignStartDay: Date.AsProtobufJSON | null;
    campaignEndDay: Date.AsProtobufJSON | null;
    campaignTimezoneOffsetMinutes: number;
    postPeriodDays: number;
    lookbackWindow: LookbackWindow.AsProtobufJSON | null;
    matchKeys: string[];
    advertiserEvents: AdvertiserEvents.AsProtobufJSON[] | null;
    notes: string;
    archived: boolean;
    state: string;
    studyId: string;
  }

  /**
   * Message implementation for storage.Project.CustomerDataSetsEntry
   */
  export class CustomerDataSetsEntry implements GrpcMessage {
    static id = 'storage.Project.CustomerDataSetsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new CustomerDataSetsEntry();
      CustomerDataSetsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: CustomerDataSetsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: CustomerDataSetsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new storage005.CustomerDataSetReference();
            _reader.readMessage(
              _instance.value,
              storage005.CustomerDataSetReference.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      CustomerDataSetsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: CustomerDataSetsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          storage005.CustomerDataSetReference.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: storage005.CustomerDataSetReference;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of CustomerDataSetsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<CustomerDataSetsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value
        ? new storage005.CustomerDataSetReference(_value.value)
        : undefined;
      CustomerDataSetsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): storage005.CustomerDataSetReference | undefined {
      return this._value;
    }
    set value(value: storage005.CustomerDataSetReference | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      CustomerDataSetsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): CustomerDataSetsEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): CustomerDataSetsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module CustomerDataSetsEntry {
    /**
     * Standard JavaScript object representation for CustomerDataSetsEntry
     */
    export interface AsObject {
      key: string;
      value?: storage005.CustomerDataSetReference.AsObject;
    }

    /**
     * Protobuf JSON representation for CustomerDataSetsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: storage005.CustomerDataSetReference.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for storage.AdvertiserEvents
 */
export class AdvertiserEvents implements GrpcMessage {
  static id = 'storage.AdvertiserEvents';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AdvertiserEvents();
    AdvertiserEvents.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AdvertiserEvents) {
    _instance.advertiserEventType = _instance.advertiserEventType || 0;
    _instance.includeAmounts = _instance.includeAmounts || false;
    _instance.includeNumUnits = _instance.includeNumUnits || false;
    _instance.notes = _instance.notes || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AdvertiserEvents,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.advertiserEventType = _reader.readEnum();
          break;
        case 2:
          _instance.includeAmounts = _reader.readBool();
          break;
        case 3:
          _instance.includeNumUnits = _reader.readBool();
          break;
        case 4:
          _instance.notes = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AdvertiserEvents.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AdvertiserEvents,
    _writer: BinaryWriter
  ) {
    if (_instance.advertiserEventType) {
      _writer.writeEnum(1, _instance.advertiserEventType);
    }
    if (_instance.includeAmounts) {
      _writer.writeBool(2, _instance.includeAmounts);
    }
    if (_instance.includeNumUnits) {
      _writer.writeBool(3, _instance.includeNumUnits);
    }
    if (_instance.notes) {
      _writer.writeString(4, _instance.notes);
    }
  }

  private _advertiserEventType: attribution002.AdvertiserEventType;
  private _includeAmounts: boolean;
  private _includeNumUnits: boolean;
  private _notes: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AdvertiserEvents to deeply clone from
   */
  constructor(_value?: RecursivePartial<AdvertiserEvents.AsObject>) {
    _value = _value || {};
    this.advertiserEventType = _value.advertiserEventType;
    this.includeAmounts = _value.includeAmounts;
    this.includeNumUnits = _value.includeNumUnits;
    this.notes = _value.notes;
    AdvertiserEvents.refineValues(this);
  }
  get advertiserEventType(): attribution002.AdvertiserEventType {
    return this._advertiserEventType;
  }
  set advertiserEventType(value: attribution002.AdvertiserEventType) {
    this._advertiserEventType = value;
  }
  get includeAmounts(): boolean {
    return this._includeAmounts;
  }
  set includeAmounts(value: boolean) {
    this._includeAmounts = value;
  }
  get includeNumUnits(): boolean {
    return this._includeNumUnits;
  }
  set includeNumUnits(value: boolean) {
    this._includeNumUnits = value;
  }
  get notes(): string {
    return this._notes;
  }
  set notes(value: string) {
    this._notes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AdvertiserEvents.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AdvertiserEvents.AsObject {
    return {
      advertiserEventType: this.advertiserEventType,
      includeAmounts: this.includeAmounts,
      includeNumUnits: this.includeNumUnits,
      notes: this.notes,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AdvertiserEvents.AsProtobufJSON {
    return {
      advertiserEventType:
        attribution002.AdvertiserEventType[
          this.advertiserEventType === null ||
          this.advertiserEventType === undefined
            ? 0
            : this.advertiserEventType
        ],
      includeAmounts: this.includeAmounts,
      includeNumUnits: this.includeNumUnits,
      notes: this.notes,
    };
  }
}
export module AdvertiserEvents {
  /**
   * Standard JavaScript object representation for AdvertiserEvents
   */
  export interface AsObject {
    advertiserEventType: attribution002.AdvertiserEventType;
    includeAmounts: boolean;
    includeNumUnits: boolean;
    notes: string;
  }

  /**
   * Protobuf JSON representation for AdvertiserEvents
   */
  export interface AsProtobufJSON {
    advertiserEventType: string;
    includeAmounts: boolean;
    includeNumUnits: boolean;
    notes: string;
  }
}

/**
 * Message implementation for storage.LookbackWindow
 */
export class LookbackWindow implements GrpcMessage {
  static id = 'storage.LookbackWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LookbackWindow();
    LookbackWindow.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LookbackWindow) {
    _instance.clickDays = _instance.clickDays || 0;
    _instance.viewDays = _instance.viewDays || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LookbackWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.clickDays = _reader.readInt32();
          break;
        case 2:
          _instance.viewDays = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    LookbackWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LookbackWindow,
    _writer: BinaryWriter
  ) {
    if (_instance.clickDays) {
      _writer.writeInt32(1, _instance.clickDays);
    }
    if (_instance.viewDays) {
      _writer.writeInt32(2, _instance.viewDays);
    }
  }

  private _clickDays: number;
  private _viewDays: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LookbackWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<LookbackWindow.AsObject>) {
    _value = _value || {};
    this.clickDays = _value.clickDays;
    this.viewDays = _value.viewDays;
    LookbackWindow.refineValues(this);
  }
  get clickDays(): number {
    return this._clickDays;
  }
  set clickDays(value: number) {
    this._clickDays = value;
  }
  get viewDays(): number {
    return this._viewDays;
  }
  set viewDays(value: number) {
    this._viewDays = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LookbackWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LookbackWindow.AsObject {
    return {
      clickDays: this.clickDays,
      viewDays: this.viewDays,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LookbackWindow.AsProtobufJSON {
    return {
      clickDays: this.clickDays,
      viewDays: this.viewDays,
    };
  }
}
export module LookbackWindow {
  /**
   * Standard JavaScript object representation for LookbackWindow
   */
  export interface AsObject {
    clickDays: number;
    viewDays: number;
  }

  /**
   * Protobuf JSON representation for LookbackWindow
   */
  export interface AsProtobufJSON {
    clickDays: number;
    viewDays: number;
  }
}

/**
 * Message implementation for storage.Date
 */
export class Date implements GrpcMessage {
  static id = 'storage.Date';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Date();
    Date.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Date) {
    _instance.year = _instance.year || 0;
    _instance.month = _instance.month || 0;
    _instance.day = _instance.day || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Date, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.year = _reader.readInt32();
          break;
        case 2:
          _instance.month = _reader.readInt32();
          break;
        case 3:
          _instance.day = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    Date.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Date, _writer: BinaryWriter) {
    if (_instance.year) {
      _writer.writeInt32(1, _instance.year);
    }
    if (_instance.month) {
      _writer.writeInt32(2, _instance.month);
    }
    if (_instance.day) {
      _writer.writeInt32(3, _instance.day);
    }
  }

  private _year: number;
  private _month: number;
  private _day: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Date to deeply clone from
   */
  constructor(_value?: RecursivePartial<Date.AsObject>) {
    _value = _value || {};
    this.year = _value.year;
    this.month = _value.month;
    this.day = _value.day;
    Date.refineValues(this);
  }
  get year(): number {
    return this._year;
  }
  set year(value: number) {
    this._year = value;
  }
  get month(): number {
    return this._month;
  }
  set month(value: number) {
    this._month = value;
  }
  get day(): number {
    return this._day;
  }
  set day(value: number) {
    this._day = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Date.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Date.AsObject {
    return {
      year: this.year,
      month: this.month,
      day: this.day,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Date.AsProtobufJSON {
    return {
      year: this.year,
      month: this.month,
      day: this.day,
    };
  }
}
export module Date {
  /**
   * Standard JavaScript object representation for Date
   */
  export interface AsObject {
    year: number;
    month: number;
    day: number;
  }

  /**
   * Protobuf JSON representation for Date
   */
  export interface AsProtobufJSON {
    year: number;
    month: number;
    day: number;
  }
}
