import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormHelpersService } from 'app/services/form-helpers.service';

import { LoggerService } from '../../services/logger.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-user-profile-edit-modal',
  templateUrl: './user-profile-edit-modal.component.html',
  styleUrls: [
    './user-profile-edit-modal.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: false,
})
export class UserProfileEditModalComponent {
  constructor(
    private dialogRef: MatDialogRef<UserProfileEditModalComponent>,
    public userService: UserService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      firstName: string;
      lastName: string;
      userProfileIcon: string;
      email: string;
      uid: string;
      roles: string;
    },
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private formHelper: FormHelpersService
  ) {
    this.title = 'Personal Information';

    this.userEditForm = this.formBuilder.group({
      photoURL: new FormControl<string>('', []),
      firstName: new FormControl<string>(data.firstName, [Validators.required]),
      lastName: new FormControl<string>(data.lastName, [Validators.required]),
    });

    this.formHelper.setForm(this.userEditForm);
  }

  title: string;
  isLoading = false;
  isUploadingPicture = false;

  public userEditForm: FormGroup;

  uploadFile(event: Event, uid: string) {
    this.isUploadingPicture = true;

    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length) {
      const file = target.files[0];
      this.userService
        .uploadProfilePicture(`images/${uid}/${file.name}`, file)
        .subscribe({
          next: (value: string) => {
            this.data.userProfileIcon = value;
            this.isUploadingPicture = false;
          },
          error: (error) => {
            this.logger.error('Error uploading piture file', error);
            this.isUploadingPicture = false;
            this.messageBox.error(
              'Error uploading file, file is either not an image or larger than 1mb.'
            );
          },
        });
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public async save() {
    this.isLoading = true;
    try {
      const { firstName, lastName } = this.userEditForm.value;

      if (!this.userEditForm.valid || !firstName || !lastName) {
        return;
      }

      await this.userService.updateUserProfile(
        firstName,
        lastName,
        this.data.userProfileIcon
      );

      this.messageBox.success('Profile updated successfully!', () => {
        this.dialogRef.close({
          photoUrl: this.data.userProfileIcon,
          firstName: this.userEditForm.value.firstName,
          lastName: this.userEditForm.value.lastName,
        });
      });
    } catch (error) {
      this.logger.error('Error updating user profile', error);
      this.messageBox.error(
        'Something went wrong, please logout, login and try again.'
      );
    } finally {
      this.isLoading = false;
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
