import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerService } from 'app/services/customer.service';
import { FormatService } from 'app/services/format.service';
import {
  ListProjectsFilter,
  ListProjectsRequest,
  ListProjectsResponse,
} from 'app/services/generated/src/main/proto/api/project-service.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  AdvertiserEvents,
  Date as ProjectDate,
  LookbackWindow,
  Project,
  State,
} from 'app/services/generated/src/main/proto/storage/project.pb';
import { LoggerService } from 'app/services/logger.service';
import { ProjectService } from 'app/services/project.service';
import moment from 'moment';

import { LocationList, ProjectStateList } from '../../constants/lookups';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';

const RECORDS_PER_PAGE = 15;

interface Page {
  continuationToken: string | null;
}

interface ProjectRow {
  advertiserEvents: AdvertiserEvents[] | undefined;
  id: string;
  binaryType: BinaryType;
  createdEmail: string;
  creationTimestamp: string;
  publisherId: string | undefined;
  advertiserId: string | undefined;
  location: string;
  lastUpdate: string | undefined;
  lastUpdatedEmail: string;
  campaignStartDay: ProjectDate | undefined;
  campaignEndDay: ProjectDate | undefined;
  campaignTimezoneOffsetMinutes: number;
  postPeriodDays: number;
  publisherDataSetId: string | undefined;
  advertiserDataSetId: string | undefined;
  lookbackWindow: LookbackWindow | undefined;
  jobRunDate: string | undefined;
  matchKeys: string[];
  notes: string;
  archived: boolean;
  state: State;
  studyId: string;
}

@Component({
  selector: 'app-admin-project-manager',
  providers: [DatePipe],
  templateUrl: './admin-project-manager.component.html',
  styleUrls: ['./admin-project-manager.component.scss'],
  standalone: false,
})
export class AdminProjectManagerComponent implements OnInit {
  allowedCustomerLocations: Location[] = [];
  allowedBinaryTypes: BinaryType[] = [];
  archived: boolean = false;
  selectedBinaryType: BinaryType | undefined;
  binaryTypeToName = new Map<BinaryType, string>();
  customerNameMap = new Map<string, string>();
  dataSource: MatTableDataSource<ProjectRow> = new MatTableDataSource();
  public columnsToDisplay: string[] = [
    'state',
    'location',
    'binaryType',
    'advertiserId',
    'publisherId',
    'campaignStartDate',
    'campaignEndDate',
    'postPeriodDays',
    'jobRunDate',
    'publisherDataSetName',
    'advertiserDataSetName',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  selectedAdvertiser: Customer | undefined;
  selectedLocation: Location | undefined;
  selectedPublisher: Customer | undefined;
  projects: Project[] = [];
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  lastUpdate = this.formatService.getLastUpdate();
  selectedCustomer: any;

  constructor(
    private binaryTypeService: BinaryTypeService,
    private customerService: CustomerService,
    private projectService: ProjectService,
    private formatService: FormatService,
    private logger: LoggerService,
    private router: Router
  ) {
    LocationList.forEach((location) =>
      this.allowedCustomerLocations.push(location.value)
    );
  }

  async ngOnInit() {
    this.sort.sort({ id: 'creationTimestamp', start: 'desc' } as MatSortable);
    this.getCustomerData();
    this.loadBinaryTypes();
    this.loadProjects();
  }

  getContinuationToken() {
    return this.pages[this.currentPageIndex]?.continuationToken || null;
  }

  onAdvertiserSelect(customer: Customer) {
    this.selectedAdvertiser = customer;
    this.loadProjects();
  }

  onPublisherSelect(customer: Customer) {
    this.selectedPublisher = customer;
    this.allowedCustomerLocations = customer.locations;
    this.allowedBinaryTypes = customer.allowedBinaryTypes;
    this.loadProjects();
  }

  async onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex;
    const continuationToken = this.pages[pageIndex]?.continuationToken;
    this.loadProjects(pageIndex, continuationToken);
    this.currentPageIndex = pageIndex;
  }

  async loadBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      response.binaryTypeInfos?.forEach((info) => {
        this.binaryTypeToName.set(info.binaryType, info.name);
      });
    } catch (error) {
      this.logger.error('Error loading binary types:', error);
    }
  }

  async loadProjects(pageIndex = 0, token: string | null = null) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.dataSource.data.splice(0);
      this.projects.splice(0);

      try {
        const getRequest = this.createGetRequest(token);
        const response = await this.projectService.listProjects(getRequest);
        if (response.projects) {
          response.projects.forEach((project) => {
            this.projects.push(project);
            this.dataSource.data.push({
              id: project.id,
              binaryType: project.binaryType,
              advertiserId: project.advertiserId,
              state: project.state,
              creationTimestamp: project.creationTimestamp
                ? project.creationTimestamp.seconds
                : '',
              advertiserEvents: project.advertiserEvents,
              createdEmail: project.createdEmail,
              publisherId: project.publisherId,
              location: this.getLocationName(project.location),
              lastUpdate: this.formatService.formatProtoDate(
                project.lastUpdate
              ),
              lastUpdatedEmail: project.lastUpdatedEmail,
              campaignStartDay: project.campaignStartDay,
              campaignEndDay: project.campaignEndDay,
              campaignTimezoneOffsetMinutes:
                project.campaignTimezoneOffsetMinutes,
              postPeriodDays: project.postPeriodDays,
              lookbackWindow: project.lookbackWindow,
              matchKeys: project.matchKeys,
              notes: project.notes,
              archived: project.archived,
              studyId: project.studyId,
              jobRunDate: this.getJobRunDate(
                project.campaignEndDay,
                project.postPeriodDays
              ),
              advertiserDataSetId: this.getDataSetId(project, 'advertiser'),
              publisherDataSetId: this.getDataSetId(project, 'publisher'),
            });
          });
          this.updatePaginationData(response, pageIndex);
          this.setDataSource();
        }
      } catch (error) {
        this.logger.error('Error loading projects:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  getDataSetId(project: Project, customerType: string): string | undefined {
    return project.customerDataSets?.[customerType]?.id || undefined;
  }

  getJobRunDate(campaignEndDay?: ProjectDate, postPeriodDays?: number): string {
    if (!campaignEndDay || !postPeriodDays) {
      return '-';
    }

    const formattedDate =
      this.formatService.formatProjectDateToDate(campaignEndDay);
    return moment(formattedDate)
      .add(postPeriodDays, 'days')
      .format('MMM DD, YYYY');
  }

  createGetRequest(token: string | null): ListProjectsRequest {
    const getProjectRequest = new ListProjectsRequest();

    if (token && getProjectRequest.paginated) {
      getProjectRequest.paginated.continuationToken = token;
    }

    getProjectRequest.filter = new ListProjectsFilter();

    if (this.selectedPublisher) {
      getProjectRequest.filter.publisherId = this.selectedPublisher.id;
    }

    if (this.archived) {
      getProjectRequest.filter.archived = this.archived;
    }

    if (this.selectedAdvertiser) {
      getProjectRequest.filter.advertiserId = this.selectedAdvertiser.id;
    }

    if (this.selectedLocation) {
      getProjectRequest.filter.location = this.selectedLocation;
    }

    if (this.selectedBinaryType) {
      getProjectRequest.filter.binaryType = this.selectedBinaryType;
    }

    return getProjectRequest;
  }

  updatePaginationData(response: ListProjectsResponse, pageIndex: number) {
    if (pageIndex === 0) {
      this.totalRecords =
        response.paginatedResponse?.count || this.dataSource.data.length;
    }
    if (response.paginatedResponse?.continuationToken) {
      this.pages[pageIndex + 1] = {
        continuationToken: response.paginatedResponse.continuationToken,
      };
    }
  }

  isReadyState(state: State) {
    return state === State.STATE_PROJECT_READY;
  }

  isDraftState(state: State) {
    return state === State.STATE_PROJECT_DRAFT;
  }

  newProject() {
    this.router.navigate(['/projects/new']);
  }

  onBinarySelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.loadProjects();
  }

  onLocationSelect(locations: Location) {
    this.selectedLocation = locations;
    this.loadProjects();
  }

  getLocationName(location: Location | undefined) {
    if (!location) {
      return 'Unknown';
    }

    const decodedLocation = LocationList.find((l) => l.value == location);
    if (decodedLocation) {
      return decodedLocation.name;
    } else {
      return 'Unknown';
    }
  }

  getCustomerName(customerId: string) {
    return this.customerNameMap.get(customerId);
  }

  getStateName(projectState: State) {
    return ProjectStateList.find((state) => state.value === projectState)?.name;
  }

  edit(projectId: string) {
    this.router.navigate([`projects/${projectId}/edit`]);
  }

  formatProjectDate(date: ProjectDate): string {
    if (this.isValidProjectDate(date)) {
      return moment(this.formatService.formatProjectDateToDate(date)).format(
        'MMM DD, YYYY'
      );
    }
    return '-';
  }

  private isValidProjectDate(date: ProjectDate): boolean {
    return date?.year > 0 && date?.month > 0 && date?.day > 0;
  }

  async getCustomerData() {
    const customers = await this.customerService.readAllCustomers();
    this.customerNameMap.clear();
    customers.forEach((customer) =>
      this.customerNameMap.set(customer.id, customer.companyName)
    );
  }

  setDataSource() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'id':
          return item.id;
        case 'advertiserId':
          return item.advertiserId!;
        case 'binaryType':
          return item.binaryType;
        case 'publiserId':
          return item.publisherId!;
        case 'state':
          return item.state;
        case 'creationTimestamp':
          return item.creationTimestamp;
        default:
          // Sort by creation time by default.
          return item.creationTimestamp;
      }
    };
    this.dataSource.sort = this.sort;
  }

  viewDataSet(dataSetId: string) {
    this.router.navigate([`dataset/${dataSetId}/edit`]);
  }

  viewJobs(projectId: string) {
    this.router.navigate([`jobs/${projectId}/project-view`]);
  }
}
