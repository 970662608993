import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { GetJobResultsRequest } from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import Papa from 'papaparse';

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrl: './results-table.component.scss',
  standalone: false,
})
export class ResultsTableComponent implements OnInit, OnChanges {
  @Input() jobId: string | undefined | null;
  @Input() fileName: string | undefined | null;

  error: boolean = false;
  columns: string[] = [];
  rows: Record<string, string>[] = [];

  constructor(
    private jobLogService: JobLogService,
    private logger: LoggerService
  ) {}

  async ngOnInit() {
    if (!this.jobId || !this.fileName) {
      return;
    }
    try {
      const getJobsResultsRequest = new GetJobResultsRequest({
        jobLogId: this.jobId,
        fileNames: [this.fileName],
      });
      const response = await this.jobLogService.getJobResults(
        getJobsResultsRequest
      );

      const result = Papa.parse<Record<string, string>>(
        response.jobResultFiles![0].fileContents!,
        {
          header: true,
          skipEmptyLines: true,
        }
      );
      this.rows = result.data;
      this.columns = Object.keys(result.data[0]);
    } catch (error) {
      this.error = true;
      this.logger.error('Failed to load job results with error: {}', error);
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }
}
