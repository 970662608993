import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IdentityMatchKeysList } from 'app/constants/lookups';
import { AdminModule } from 'app/modules/admin/admin.module';

@Component({
  selector: 'app-match-keys-selector',
  imports: [
    CommonModule,
    AdminModule,
    DragDropModule,
    MatCheckboxModule,
    MatChipsModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './match-keys-selector.component.html',
  styleUrl: './match-keys-selector.component.scss',
})
export class MatchKeysSelectorComponent {
  @Input() selectedMatchKeys: string[] = [];
  @Output() selectMatchKeysEvent = new EventEmitter<string[]>();
  matchKeys = IdentityMatchKeysList;

  constructor() {}

  capitalizeFirstLetter(value: string) {
    return String(value).charAt(0).toUpperCase() + String(value).slice(1);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.matchKeys, event.previousIndex, event.currentIndex);
    this.orderStringListByReference();
    this.selectMatchKeysEvent.emit(this.selectedMatchKeys);
  }

  onMatchKeySelected(matchKey: string) {
    const lowerCaseMatchKey = matchKey.toLocaleLowerCase();
    const index = this.selectedMatchKeys.indexOf(lowerCaseMatchKey);
    if (index === -1) {
      this.selectedMatchKeys.push(lowerCaseMatchKey);
    } else {
      this.selectedMatchKeys.splice(index, 1);
    }
    this.selectMatchKeysEvent.emit(this.selectedMatchKeys);
  }

  orderStringListByReference() {
    const orderMap = new Map(
      this.matchKeys.map((item, index) => [item, index])
    );

    this.selectedMatchKeys = this.selectedMatchKeys.slice().sort((a, b) => {
      const indexA = orderMap.has(a)
        ? orderMap.get(a)!
        : this.selectedMatchKeys.length;
      const indexB = orderMap.has(b)
        ? orderMap.get(b)!
        : this.selectedMatchKeys.length;
      return indexA - indexB;
    });
  }

  toggleSelectedMatchKey(matchKey: string) {
    return this.selectedMatchKeys.includes(matchKey.toLocaleLowerCase());
  }
}
