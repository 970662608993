/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/commons.pb';
import * as storage003 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api004 from '../../../../src/main/proto/api/headers.pb';
import * as api005 from '../../../../src/main/proto/api/pagination.pb';
import * as storage006 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage007 from '../../../../src/main/proto/storage/job-log.pb';
export enum JobResultFileType {
  JOB_RESULT_FILE_TYPE_UNSPECIFIED = 0,
  JOB_RESULT_FILE_TYPE_OUTPUT_JSON = 1,
  JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV = 2,
  JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV = 3,
  JOB_RESULT_FILE_TYPE_LOGS_JSON = 4,
  JOB_RESULT_FILE_TYPE_STORAGE_METADATA = 5,
}
/**
 * Message implementation for api.job_log.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.job_log.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobLog = _instance.jobLog || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLog = new storage007.JobLog();
          _reader.readMessage(
            _instance.jobLog,
            storage007.JobLog.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLog) {
      _writer.writeMessage(
        2,
        _instance.jobLog as any,
        storage007.JobLog.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLog?: storage007.JobLog;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLog = _value.jobLog
      ? new storage007.JobLog(_value.jobLog)
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLog(): storage007.JobLog | undefined {
    return this._jobLog;
  }
  set jobLog(value: storage007.JobLog | undefined) {
    this._jobLog = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLog: this.jobLog ? this.jobLog.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLog: this.jobLog ? this.jobLog.toProtobufJSON(options) : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLog?: storage007.JobLog.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLog: storage007.JobLog.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_log.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.job_log.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _id: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.id = _value.id;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    id: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    id: string;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.CloneRequest
 */
export class CloneRequest implements GrpcMessage {
  static id = 'api.job_log.CloneRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CloneRequest();
    CloneRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CloneRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobLogId = _instance.jobLogId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CloneRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLogId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CloneRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CloneRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogId) {
      _writer.writeString(2, _instance.jobLogId);
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLogId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CloneRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CloneRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLogId = _value.jobLogId;
    CloneRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    this._jobLogId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CloneRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CloneRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogId: this.jobLogId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CloneRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogId: this.jobLogId,
    };
  }
}
export module CloneRequest {
  /**
   * Standard JavaScript object representation for CloneRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLogId: string;
  }

  /**
   * Protobuf JSON representation for CloneRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLogId: string;
  }
}

/**
 * Message implementation for api.job_log.CloneResponse
 */
export class CloneResponse implements GrpcMessage {
  static id = 'api.job_log.CloneResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CloneResponse();
    CloneResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CloneResponse) {
    _instance.header = _instance.header || undefined;
    _instance.jobLog = _instance.jobLog || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CloneResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLog = new storage007.JobLog();
          _reader.readMessage(
            _instance.jobLog,
            storage007.JobLog.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CloneResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CloneResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLog) {
      _writer.writeMessage(
        2,
        _instance.jobLog as any,
        storage007.JobLog.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLog?: storage007.JobLog;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CloneResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CloneResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLog = _value.jobLog
      ? new storage007.JobLog(_value.jobLog)
      : undefined;
    this.etag = _value.etag;
    CloneResponse.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLog(): storage007.JobLog | undefined {
    return this._jobLog;
  }
  set jobLog(value: storage007.JobLog | undefined) {
    this._jobLog = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CloneResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CloneResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLog: this.jobLog ? this.jobLog.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CloneResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLog: this.jobLog ? this.jobLog.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module CloneResponse {
  /**
   * Standard JavaScript object representation for CloneResponse
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLog?: storage007.JobLog.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CloneResponse
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLog: storage007.JobLog.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.GetJobResultsRequest
 */
export class GetJobResultsRequest implements GrpcMessage {
  static id = 'api.job_log.GetJobResultsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetJobResultsRequest();
    GetJobResultsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetJobResultsRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobLogId = _instance.jobLogId || '';
    _instance.fileNames = _instance.fileNames || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetJobResultsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLogId = _reader.readString();
          break;
        case 3:
          (_instance.fileNames = _instance.fileNames || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetJobResultsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetJobResultsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogId) {
      _writer.writeString(2, _instance.jobLogId);
    }
    if (_instance.fileNames && _instance.fileNames.length) {
      _writer.writeRepeatedString(3, _instance.fileNames);
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLogId: string;
  private _fileNames: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetJobResultsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetJobResultsRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLogId = _value.jobLogId;
    this.fileNames = (_value.fileNames || []).slice();
    GetJobResultsRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    this._jobLogId = value;
  }
  get fileNames(): string[] {
    return this._fileNames;
  }
  set fileNames(value: string[]) {
    this._fileNames = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetJobResultsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetJobResultsRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogId: this.jobLogId,
      fileNames: (this.fileNames || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetJobResultsRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogId: this.jobLogId,
      fileNames: (this.fileNames || []).slice(),
    };
  }
}
export module GetJobResultsRequest {
  /**
   * Standard JavaScript object representation for GetJobResultsRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLogId: string;
    fileNames: string[];
  }

  /**
   * Protobuf JSON representation for GetJobResultsRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLogId: string;
    fileNames: string[];
  }
}

/**
 * Message implementation for api.job_log.GetJobResultsResponse
 */
export class GetJobResultsResponse implements GrpcMessage {
  static id = 'api.job_log.GetJobResultsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetJobResultsResponse();
    GetJobResultsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetJobResultsResponse) {
    _instance.header = _instance.header || undefined;
    _instance.jobResultFiles = _instance.jobResultFiles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetJobResultsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new JobResultFile();
          _reader.readMessage(
            messageInitializer2,
            JobResultFile.deserializeBinaryFromReader
          );
          (_instance.jobResultFiles = _instance.jobResultFiles || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetJobResultsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetJobResultsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobResultFiles && _instance.jobResultFiles.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.jobResultFiles as any,
        JobResultFile.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.ResponseHeader;
  private _jobResultFiles?: JobResultFile[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetJobResultsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetJobResultsResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.jobResultFiles = (_value.jobResultFiles || []).map(
      (m) => new JobResultFile(m)
    );
    GetJobResultsResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get jobResultFiles(): JobResultFile[] | undefined {
    return this._jobResultFiles;
  }
  set jobResultFiles(value: JobResultFile[] | undefined) {
    this._jobResultFiles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetJobResultsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetJobResultsResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobResultFiles: (this.jobResultFiles || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetJobResultsResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobResultFiles: (this.jobResultFiles || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module GetJobResultsResponse {
  /**
   * Standard JavaScript object representation for GetJobResultsResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    jobResultFiles?: JobResultFile.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetJobResultsResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    jobResultFiles: JobResultFile.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.job_log.JobResultFile
 */
export class JobResultFile implements GrpcMessage {
  static id = 'api.job_log.JobResultFile';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobResultFile();
    JobResultFile.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobResultFile) {
    _instance.jobResultFileType = _instance.jobResultFileType || 0;
    _instance.fileContents = _instance.fileContents || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobResultFile,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.jobResultFileType = _reader.readEnum();
          break;
        case 2:
          _instance.fileContents = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    JobResultFile.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: JobResultFile,
    _writer: BinaryWriter
  ) {
    if (_instance.jobResultFileType) {
      _writer.writeEnum(1, _instance.jobResultFileType);
    }
    if (_instance.fileContents) {
      _writer.writeString(2, _instance.fileContents);
    }
  }

  private _jobResultFileType: JobResultFileType;
  private _fileContents: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobResultFile to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobResultFile.AsObject>) {
    _value = _value || {};
    this.jobResultFileType = _value.jobResultFileType;
    this.fileContents = _value.fileContents;
    JobResultFile.refineValues(this);
  }
  get jobResultFileType(): JobResultFileType {
    return this._jobResultFileType;
  }
  set jobResultFileType(value: JobResultFileType) {
    this._jobResultFileType = value;
  }
  get fileContents(): string {
    return this._fileContents;
  }
  set fileContents(value: string) {
    this._fileContents = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobResultFile.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobResultFile.AsObject {
    return {
      jobResultFileType: this.jobResultFileType,
      fileContents: this.fileContents,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobResultFile.AsProtobufJSON {
    return {
      jobResultFileType:
        JobResultFileType[
          this.jobResultFileType === null ||
          this.jobResultFileType === undefined
            ? 0
            : this.jobResultFileType
        ],
      fileContents: this.fileContents,
    };
  }
}
export module JobResultFile {
  /**
   * Standard JavaScript object representation for JobResultFile
   */
  export interface AsObject {
    jobResultFileType: JobResultFileType;
    fileContents: string;
  }

  /**
   * Protobuf JSON representation for JobResultFile
   */
  export interface AsProtobufJSON {
    jobResultFileType: string;
    fileContents: string;
  }
}

/**
 * Message implementation for api.job_log.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.job_log.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.header = _instance.header || undefined;

    _instance.filter = _instance.filter || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLogId = _reader.readString();
          break;
        case 3:
          _instance.paginated = new api005.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api005.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.filter = new GetRequestFilter();
          _reader.readMessage(
            _instance.filter,
            GetRequestFilter.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogId || _instance.jobLogId === '') {
      _writer.writeString(2, _instance.jobLogId);
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api005.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
    if (_instance.filter) {
      _writer.writeMessage(
        4,
        _instance.filter as any,
        GetRequestFilter.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.ResponseHeader;
  private _jobLogId: string;
  private _paginated?: api005.GetPaginatedRequest;
  private _filter?: GetRequestFilter;

  private _criteria: GetRequest.CriteriaCase = GetRequest.CriteriaCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.jobLogId = _value.jobLogId;
    this.paginated = _value.paginated
      ? new api005.GetPaginatedRequest(_value.paginated)
      : undefined;
    this.filter = _value.filter
      ? new GetRequestFilter(_value.filter)
      : undefined;
    GetRequest.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    if (value !== undefined && value !== null) {
      this._paginated = undefined;
      this._criteria = GetRequest.CriteriaCase.jobLogId;
    }
    this._jobLogId = value;
  }
  get paginated(): api005.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api005.GetPaginatedRequest | undefined) {
    if (value !== undefined && value !== null) {
      this._jobLogId = undefined;
      this._criteria = GetRequest.CriteriaCase.paginated;
    }
    this._paginated = value;
  }
  get filter(): GetRequestFilter | undefined {
    return this._filter;
  }
  set filter(value: GetRequestFilter | undefined) {
    this._filter = value;
  }
  get criteria() {
    return this._criteria;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogId: this.jobLogId,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
      filter: this.filter ? this.filter.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogId:
        this.jobLogId === null || this.jobLogId === undefined
          ? null
          : this.jobLogId,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
      filter: this.filter ? this.filter.toProtobufJSON(options) : null,
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    jobLogId: string;
    paginated?: api005.GetPaginatedRequest.AsObject;
    filter?: GetRequestFilter.AsObject;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    jobLogId: string | null;
    paginated: api005.GetPaginatedRequest.AsProtobufJSON | null;
    filter: GetRequestFilter.AsProtobufJSON | null;
  }
  export enum CriteriaCase {
    none = 0,
    jobLogId = 1,
    paginated = 2,
  }
}

/**
 * Message implementation for api.job_log.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.job_log.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.header = _instance.header || undefined;
    _instance.jobLogs = _instance.jobLogs || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new ApiJobLog();
          _reader.readMessage(
            messageInitializer2,
            ApiJobLog.deserializeBinaryFromReader
          );
          (_instance.jobLogs = _instance.jobLogs || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api005.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api005.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogs && _instance.jobLogs.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.jobLogs as any,
        ApiJobLog.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api005.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLogs?: ApiJobLog[];
  private _paginatedResponse?: api005.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLogs = (_value.jobLogs || []).map((m) => new ApiJobLog(m));
    this.paginatedResponse = _value.paginatedResponse
      ? new api005.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    GetResponse.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLogs(): ApiJobLog[] | undefined {
    return this._jobLogs;
  }
  set jobLogs(value: ApiJobLog[] | undefined) {
    this._jobLogs = value;
  }
  get paginatedResponse(): api005.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api005.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogs: (this.jobLogs || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogs: (this.jobLogs || []).map((m) => m.toProtobufJSON(options)),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLogs?: ApiJobLog.AsObject[];
    paginatedResponse?: api005.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLogs: ApiJobLog.AsProtobufJSON[] | null;
    paginatedResponse: api005.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_log.GetJobsForCustomerRequest
 */
export class GetJobsForCustomerRequest implements GrpcMessage {
  static id = 'api.job_log.GetJobsForCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetJobsForCustomerRequest();
    GetJobsForCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetJobsForCustomerRequest) {
    _instance.header = _instance.header || undefined;

    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetJobsForCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobState = _reader.readEnum();
          break;
        case 3:
          _instance.binaryType = _reader.readEnum();
          break;
        case 4:
          _instance.paginated = new api005.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api005.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetJobsForCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetJobsForCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobState !== undefined && _instance.jobState !== null) {
      _writer.writeEnum(2, _instance.jobState);
    }
    if (_instance.binaryType !== undefined && _instance.binaryType !== null) {
      _writer.writeEnum(3, _instance.binaryType);
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        4,
        _instance.paginated as any,
        api005.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _jobState?: storage007.JobState;
  private _binaryType?: storage006.BinaryType;
  private _paginated?: api005.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetJobsForCustomerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetJobsForCustomerRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobState = _value.jobState;
    this.binaryType = _value.binaryType;
    this.paginated = _value.paginated
      ? new api005.GetPaginatedRequest(_value.paginated)
      : undefined;
    GetJobsForCustomerRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobState(): storage007.JobState | undefined {
    return this._jobState;
  }
  set jobState(value?: storage007.JobState) {
    this._jobState = value;
  }
  get binaryType(): storage006.BinaryType | undefined {
    return this._binaryType;
  }
  set binaryType(value?: storage006.BinaryType) {
    this._binaryType = value;
  }
  get paginated(): api005.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api005.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetJobsForCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetJobsForCustomerRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobState: this.jobState,
      binaryType: this.binaryType,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetJobsForCustomerRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobState:
        this.jobState === undefined
          ? null
          : storage007.JobState[
              this.jobState === null || this.jobState === undefined
                ? 0
                : this.jobState
            ],
      binaryType:
        this.binaryType === undefined
          ? null
          : storage006.BinaryType[
              this.binaryType === null || this.binaryType === undefined
                ? 0
                : this.binaryType
            ],
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module GetJobsForCustomerRequest {
  /**
   * Standard JavaScript object representation for GetJobsForCustomerRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobState?: storage007.JobState;
    binaryType?: storage006.BinaryType;
    paginated?: api005.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for GetJobsForCustomerRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobState: string | null;
    binaryType: string | null;
    paginated: api005.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_log.GetJobsForCustomerResponse
 */
export class GetJobsForCustomerResponse implements GrpcMessage {
  static id = 'api.job_log.GetJobsForCustomerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetJobsForCustomerResponse();
    GetJobsForCustomerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetJobsForCustomerResponse) {
    _instance.header = _instance.header || undefined;
    _instance.jobs = _instance.jobs || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetJobsForCustomerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new DisplayJob();
          _reader.readMessage(
            messageInitializer2,
            DisplayJob.deserializeBinaryFromReader
          );
          (_instance.jobs = _instance.jobs || []).push(messageInitializer2);
          break;
        case 3:
          _instance.paginatedResponse = new api005.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api005.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetJobsForCustomerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetJobsForCustomerResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobs && _instance.jobs.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.jobs as any,
        DisplayJob.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api005.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.ResponseHeader;
  private _jobs?: DisplayJob[];
  private _paginatedResponse?: api005.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetJobsForCustomerResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetJobsForCustomerResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.jobs = (_value.jobs || []).map((m) => new DisplayJob(m));
    this.paginatedResponse = _value.paginatedResponse
      ? new api005.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    GetJobsForCustomerResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get jobs(): DisplayJob[] | undefined {
    return this._jobs;
  }
  set jobs(value: DisplayJob[] | undefined) {
    this._jobs = value;
  }
  get paginatedResponse(): api005.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api005.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetJobsForCustomerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetJobsForCustomerResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobs: (this.jobs || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetJobsForCustomerResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobs: (this.jobs || []).map((m) => m.toProtobufJSON(options)),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetJobsForCustomerResponse {
  /**
   * Standard JavaScript object representation for GetJobsForCustomerResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    jobs?: DisplayJob.AsObject[];
    paginatedResponse?: api005.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for GetJobsForCustomerResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    jobs: DisplayJob.AsProtobufJSON[] | null;
    paginatedResponse: api005.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_log.DisplayJob
 */
export class DisplayJob implements GrpcMessage {
  static id = 'api.job_log.DisplayJob';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DisplayJob();
    DisplayJob.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DisplayJob) {
    _instance.id = _instance.id || '';
    _instance.location = _instance.location || 0;
    _instance.startTime = _instance.startTime || undefined;
    _instance.completeTime = _instance.completeTime || undefined;
    _instance.binaryType = _instance.binaryType || 0;

    _instance.state = _instance.state || 0;
    _instance.error = _instance.error || undefined;
    _instance.description = _instance.description || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DisplayJob,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 10:
          _instance.location = _reader.readEnum();
          break;
        case 3:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.completeTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.completeTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.binaryType = _reader.readEnum();
          break;
        case 6:
          _instance.binaryVersion = _reader.readString();
          break;
        case 7:
          _instance.state = _reader.readEnum();
          break;
        case 8:
          _instance.error = new storage007.JobError();
          _reader.readMessage(
            _instance.error,
            storage007.JobError.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.description = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DisplayJob.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: DisplayJob, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.location) {
      _writer.writeEnum(10, _instance.location);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        3,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.completeTime) {
      _writer.writeMessage(
        4,
        _instance.completeTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.binaryType) {
      _writer.writeEnum(5, _instance.binaryType);
    }
    if (
      _instance.binaryVersion !== undefined &&
      _instance.binaryVersion !== null
    ) {
      _writer.writeString(6, _instance.binaryVersion);
    }
    if (_instance.state) {
      _writer.writeEnum(7, _instance.state);
    }
    if (_instance.error) {
      _writer.writeMessage(
        8,
        _instance.error as any,
        storage007.JobError.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(9, _instance.description);
    }
  }

  private _id: string;
  private _location: storage002.Location;
  private _startTime?: googleProtobuf000.Timestamp;
  private _completeTime?: googleProtobuf000.Timestamp;
  private _binaryType: storage006.BinaryType;
  private _binaryVersion?: string;
  private _state: storage007.JobState;
  private _error?: storage007.JobError;
  private _description: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DisplayJob to deeply clone from
   */
  constructor(_value?: RecursivePartial<DisplayJob.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.location = _value.location;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.completeTime = _value.completeTime
      ? new googleProtobuf000.Timestamp(_value.completeTime)
      : undefined;
    this.binaryType = _value.binaryType;
    this.binaryVersion = _value.binaryVersion;
    this.state = _value.state;
    this.error = _value.error
      ? new storage007.JobError(_value.error)
      : undefined;
    this.description = _value.description;
    DisplayJob.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get location(): storage002.Location {
    return this._location;
  }
  set location(value: storage002.Location) {
    this._location = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get completeTime(): googleProtobuf000.Timestamp | undefined {
    return this._completeTime;
  }
  set completeTime(value: googleProtobuf000.Timestamp | undefined) {
    this._completeTime = value;
  }
  get binaryType(): storage006.BinaryType {
    return this._binaryType;
  }
  set binaryType(value: storage006.BinaryType) {
    this._binaryType = value;
  }
  get binaryVersion(): string | undefined {
    return this._binaryVersion;
  }
  set binaryVersion(value?: string) {
    this._binaryVersion = value;
  }
  get state(): storage007.JobState {
    return this._state;
  }
  set state(value: storage007.JobState) {
    this._state = value;
  }
  get error(): storage007.JobError | undefined {
    return this._error;
  }
  set error(value: storage007.JobError | undefined) {
    this._error = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DisplayJob.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DisplayJob.AsObject {
    return {
      id: this.id,
      location: this.location,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      completeTime: this.completeTime
        ? this.completeTime.toObject()
        : undefined,
      binaryType: this.binaryType,
      binaryVersion: this.binaryVersion,
      state: this.state,
      error: this.error ? this.error.toObject() : undefined,
      description: this.description,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DisplayJob.AsProtobufJSON {
    return {
      id: this.id,
      location:
        storage002.Location[
          this.location === null || this.location === undefined
            ? 0
            : this.location
        ],
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      completeTime: this.completeTime
        ? this.completeTime.toProtobufJSON(options)
        : null,
      binaryType:
        storage006.BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
      binaryVersion:
        this.binaryVersion === null || this.binaryVersion === undefined
          ? null
          : this.binaryVersion,
      state:
        storage007.JobState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      error: this.error ? this.error.toProtobufJSON(options) : null,
      description: this.description,
    };
  }
}
export module DisplayJob {
  /**
   * Standard JavaScript object representation for DisplayJob
   */
  export interface AsObject {
    id: string;
    location: storage002.Location;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    completeTime?: googleProtobuf000.Timestamp.AsObject;
    binaryType: storage006.BinaryType;
    binaryVersion?: string;
    state: storage007.JobState;
    error?: storage007.JobError.AsObject;
    description: string;
  }

  /**
   * Protobuf JSON representation for DisplayJob
   */
  export interface AsProtobufJSON {
    id: string;
    location: string;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    completeTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    binaryType: string;
    binaryVersion: string | null;
    state: string;
    error: storage007.JobError.AsProtobufJSON | null;
    description: string;
  }
}

/**
 * Message implementation for api.job_log.ApiJobLog
 */
export class ApiJobLog implements GrpcMessage {
  static id = 'api.job_log.ApiJobLog';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiJobLog();
    ApiJobLog.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiJobLog) {
    _instance.jobLog = _instance.jobLog || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiJobLog,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.jobLog = new storage007.JobLog();
          _reader.readMessage(
            _instance.jobLog,
            storage007.JobLog.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ApiJobLog.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ApiJobLog, _writer: BinaryWriter) {
    if (_instance.jobLog) {
      _writer.writeMessage(
        1,
        _instance.jobLog as any,
        storage007.JobLog.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _jobLog?: storage007.JobLog;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiJobLog to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiJobLog.AsObject>) {
    _value = _value || {};
    this.jobLog = _value.jobLog
      ? new storage007.JobLog(_value.jobLog)
      : undefined;
    this.etag = _value.etag;
    ApiJobLog.refineValues(this);
  }
  get jobLog(): storage007.JobLog | undefined {
    return this._jobLog;
  }
  set jobLog(value: storage007.JobLog | undefined) {
    this._jobLog = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiJobLog.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiJobLog.AsObject {
    return {
      jobLog: this.jobLog ? this.jobLog.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiJobLog.AsProtobufJSON {
    return {
      jobLog: this.jobLog ? this.jobLog.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ApiJobLog {
  /**
   * Standard JavaScript object representation for ApiJobLog
   */
  export interface AsObject {
    jobLog?: storage007.JobLog.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ApiJobLog
   */
  export interface AsProtobufJSON {
    jobLog: storage007.JobLog.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.GetRequestFilter
 */
export class GetRequestFilter implements GrpcMessage {
  static id = 'api.job_log.GetRequestFilter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequestFilter();
    GetRequestFilter.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequestFilter) {
    _instance.state = _instance.state || 0;
    _instance.location = _instance.location || 0;
    _instance.customerIds = _instance.customerIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequestFilter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.state = _reader.readEnum();
          break;
        case 2:
          _instance.location = _reader.readEnum();
          break;
        case 3:
          (_instance.customerIds = _instance.customerIds || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequestFilter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetRequestFilter,
    _writer: BinaryWriter
  ) {
    if (_instance.state) {
      _writer.writeEnum(1, _instance.state);
    }
    if (_instance.location) {
      _writer.writeEnum(2, _instance.location);
    }
    if (_instance.customerIds && _instance.customerIds.length) {
      _writer.writeRepeatedString(3, _instance.customerIds);
    }
  }

  private _state: storage007.JobState;
  private _location: storage002.Location;
  private _customerIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequestFilter to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequestFilter.AsObject>) {
    _value = _value || {};
    this.state = _value.state;
    this.location = _value.location;
    this.customerIds = (_value.customerIds || []).slice();
    GetRequestFilter.refineValues(this);
  }
  get state(): storage007.JobState {
    return this._state;
  }
  set state(value: storage007.JobState) {
    this._state = value;
  }
  get location(): storage002.Location {
    return this._location;
  }
  set location(value: storage002.Location) {
    this._location = value;
  }
  get customerIds(): string[] {
    return this._customerIds;
  }
  set customerIds(value: string[]) {
    this._customerIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequestFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequestFilter.AsObject {
    return {
      state: this.state,
      location: this.location,
      customerIds: (this.customerIds || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequestFilter.AsProtobufJSON {
    return {
      state:
        storage007.JobState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      location:
        storage002.Location[
          this.location === null || this.location === undefined
            ? 0
            : this.location
        ],
      customerIds: (this.customerIds || []).slice(),
    };
  }
}
export module GetRequestFilter {
  /**
   * Standard JavaScript object representation for GetRequestFilter
   */
  export interface AsObject {
    state: storage007.JobState;
    location: storage002.Location;
    customerIds: string[];
  }

  /**
   * Protobuf JSON representation for GetRequestFilter
   */
  export interface AsProtobufJSON {
    state: string;
    location: string;
    customerIds: string[];
  }
}

/**
 * Message implementation for api.job_log.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.job_log.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
    _instance.jobLog = _instance.jobLog || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        case 3:
          _instance.jobLog = new storage007.JobLog();
          _reader.readMessage(
            _instance.jobLog,
            storage007.JobLog.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
    if (_instance.jobLog) {
      _writer.writeMessage(
        3,
        _instance.jobLog as any,
        storage007.JobLog.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _etag: string;
  private _jobLog?: storage007.JobLog;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    this.jobLog = _value.jobLog
      ? new storage007.JobLog(_value.jobLog)
      : undefined;
    UpdateRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get jobLog(): storage007.JobLog | undefined {
    return this._jobLog;
  }
  set jobLog(value: storage007.JobLog | undefined) {
    this._jobLog = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
      jobLog: this.jobLog ? this.jobLog.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
      jobLog: this.jobLog ? this.jobLog.toProtobufJSON(options) : null,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    etag: string;
    jobLog?: storage007.JobLog.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    etag: string;
    jobLog: storage007.JobLog.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_log.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.job_log.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.UpdateStateRequest
 */
export class UpdateStateRequest implements GrpcMessage {
  static id = 'api.job_log.UpdateStateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateStateRequest();
    UpdateStateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateStateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobLogId = _instance.jobLogId || '';
    _instance.state = _instance.state || 0;
    _instance.error = _instance.error || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateStateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLogId = _reader.readString();
          break;
        case 3:
          _instance.state = _reader.readEnum();
          break;
        case 4:
          _instance.error = new storage007.JobError();
          _reader.readMessage(
            _instance.error,
            storage007.JobError.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateStateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateStateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogId) {
      _writer.writeString(2, _instance.jobLogId);
    }
    if (_instance.state) {
      _writer.writeEnum(3, _instance.state);
    }
    if (_instance.error) {
      _writer.writeMessage(
        4,
        _instance.error as any,
        storage007.JobError.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLogId: string;
  private _state: storage007.JobState;
  private _error?: storage007.JobError;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateStateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateStateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLogId = _value.jobLogId;
    this.state = _value.state;
    this.error = _value.error
      ? new storage007.JobError(_value.error)
      : undefined;
    UpdateStateRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    this._jobLogId = value;
  }
  get state(): storage007.JobState {
    return this._state;
  }
  set state(value: storage007.JobState) {
    this._state = value;
  }
  get error(): storage007.JobError | undefined {
    return this._error;
  }
  set error(value: storage007.JobError | undefined) {
    this._error = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateStateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateStateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogId: this.jobLogId,
      state: this.state,
      error: this.error ? this.error.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateStateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogId: this.jobLogId,
      state:
        storage007.JobState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      error: this.error ? this.error.toProtobufJSON(options) : null,
    };
  }
}
export module UpdateStateRequest {
  /**
   * Standard JavaScript object representation for UpdateStateRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLogId: string;
    state: storage007.JobState;
    error?: storage007.JobError.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateStateRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLogId: string;
    state: string;
    error: storage007.JobError.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_log.UpdateStateResponse
 */
export class UpdateStateResponse implements GrpcMessage {
  static id = 'api.job_log.UpdateStateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateStateResponse();
    UpdateStateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateStateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateStateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateStateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateStateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateStateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateStateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateStateResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateStateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateStateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateStateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateStateResponse {
  /**
   * Standard JavaScript object representation for UpdateStateResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateStateResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.ArchiveRequest
 */
export class ArchiveRequest implements GrpcMessage {
  static id = 'api.job_log.ArchiveRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveRequest();
    ArchiveRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobLogId = _instance.jobLogId || '';
    _instance.archive = _instance.archive || false;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLogId = _reader.readString();
          break;
        case 3:
          _instance.archive = _reader.readBool();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogId) {
      _writer.writeString(2, _instance.jobLogId);
    }
    if (_instance.archive) {
      _writer.writeBool(3, _instance.archive);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLogId: string;
  private _archive: boolean;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLogId = _value.jobLogId;
    this.archive = _value.archive;
    this.etag = _value.etag;
    ArchiveRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    this._jobLogId = value;
  }
  get archive(): boolean {
    return this._archive;
  }
  set archive(value: boolean) {
    this._archive = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogId: this.jobLogId,
      archive: this.archive,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogId: this.jobLogId,
      archive: this.archive,
      etag: this.etag,
    };
  }
}
export module ArchiveRequest {
  /**
   * Standard JavaScript object representation for ArchiveRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLogId: string;
    archive: boolean;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLogId: string;
    archive: boolean;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.ArchiveResponse
 */
export class ArchiveResponse implements GrpcMessage {
  static id = 'api.job_log.ArchiveResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveResponse();
    ArchiveResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ArchiveResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ArchiveResponse {
  /**
   * Standard JavaScript object representation for ArchiveResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.CancelRequest
 */
export class CancelRequest implements GrpcMessage {
  static id = 'api.job_log.CancelRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelRequest();
    CancelRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobLogId = _instance.jobLogId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobLogId = _reader.readString();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobLogId) {
      _writer.writeString(2, _instance.jobLogId);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api004.RequestHeader;
  private _jobLogId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CancelRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.jobLogId = _value.jobLogId;
    this.etag = _value.etag;
    CancelRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    this._jobLogId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobLogId: this.jobLogId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobLogId: this.jobLogId,
      etag: this.etag,
    };
  }
}
export module CancelRequest {
  /**
   * Standard JavaScript object representation for CancelRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    jobLogId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CancelRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    jobLogId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.job_log.CancelResponse
 */
export class CancelResponse implements GrpcMessage {
  static id = 'api.job_log.CancelResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelResponse();
    CancelResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CancelResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    CancelResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module CancelResponse {
  /**
   * Standard JavaScript object representation for CancelResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CancelResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}
