import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocationList, ProjectStateList } from 'app/constants/lookups';
import { AdminModule } from 'app/modules/admin/admin.module';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormHelpersService } from 'app/services/form-helpers.service';
import {
  ListFilter,
  ListRequest,
} from 'app/services/generated/src/main/proto/api/customer-data-set-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import {
  AdvertiserEvents,
  Date as ProtoDate,
  LookbackWindow,
  Project,
  State,
} from 'app/services/generated/src/main/proto/storage/project.pb';
import { LoggerService } from 'app/services/logger.service';
import { ProjectService } from 'app/services/project.service';
import { CustomerDataSetInfo } from 'app/views/binary-type/common/dataset-picker/dataset-picker.component';
import { BinaryTypeDropdownComponent } from 'app/views/shared/components/binary-type-dropdown/binary-type-dropdown.component';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { MatchKeysSelectorComponent } from 'app/views/shared/components/match-keys-selector/match-keys-selector.component';
import { MessageBoxComponent } from 'app/views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';
import { TimezoneOffsetSelectorComponent } from 'app/views/shared/components/timezone-offset-selector/timezone-offset-selector.component';

const RECORDS_PER_PAGE = 50;

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss', '../../shared/shared.scss'],
  imports: [
    AdminModule,
    BinaryTypeDropdownComponent,
    CommonModule,
    ContainerComponent,
    FormsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatchKeysSelectorComponent,
    MessageBoxComponent,
    PageHeaderComponent,
    ReactiveFormsModule,
    TimezoneOffsetSelectorComponent,
  ],
})
export class AddProjectComponent implements OnInit {
  advertiserDatasets: CustomerDataSet[] = [];
  allowedCustomerLocations: Location[] = [];
  dateLabel = 'Campaign';
  defaultLocations = LocationList;
  eventTypes: AdvertiserEventType[] = [];
  listStates = ProjectStateList;
  isLoading = false;
  project: Project | undefined;
  projectForm: FormGroup;
  publisherDatasets: CustomerDataSet[] = [];
  selectedAdvertiserId: string | undefined;
  selectedAdvertiserDataSetReference: CustomerDataSetReference | undefined;
  selectedAdvertiserEvents: AdvertiserEvents[] = [];
  selectedBinaryType: BinaryType | undefined;
  selectedLocation: Location | undefined;
  selectedPublisherId: string | undefined;
  selectedPublisherUserDataSetReference: CustomerDataSetReference | undefined;
  selectedPublisherDataSetReference: CustomerDataSetReference | undefined;
  selectedState: State = State.STATE_PROJECT_DRAFT;
  selectedTimezoneOffsetMinutes: number | undefined = 0;
  selectedMatchKeys: string[] = [];
  title = 'New project';
  time: string = new Date().toLocaleString();
  update = false;

  //Event vars
  metricsColumns: string[] = [
    'advertiserEventType',
    'amount',
    'numUnits',
    'notes',
    'actions',
  ];
  metricsDataSource = new MatTableDataSource<any>();
  metricRows = this.formBuilder.array([
    this.formBuilder.group({
      advertiserEventType: 0,
      amounts: false,
      numUnits: false,
      notes: '',
    }),
  ]);

  get metrics() {
    return this.projectForm.get('metrics') as FormArray;
  }

  constructor(
    private activatedRouter: ActivatedRoute,
    private customerDataService: CustomerDataService,
    private projectService: ProjectService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private router: Router
  ) {
    this.getDefaultLocations();
    this.getEventTypes();

    this.projectForm = this.formBuilder.group({
      archived: new FormControl('', {}),
      advertiserEvents: new FormControl('', {}),
      campaignStartDate: new FormControl('', {}),
      campaignEndDate: new FormControl('', {}),
      lookbackWindowClickDays: new FormControl(0, {}),
      lookbackWindowViewDays: new FormControl(0, {}),
      postPeriodDays: new FormControl(0, {}),
      notes: new FormControl('', {}),
      studyId: new FormControl('', {}),
      state: new FormControl(this.selectedState, {}),
      metrics: new FormArray([]),
    });
    this.setupMetricsTable();
    this.formHelper.setForm(this.projectForm);
  }

  ngOnInit() {
    this.activatedRouter.params.subscribe(async (params: Params) => {
      if (params['id']) {
        this.title = 'Update project';
        this.update = true;
        try {
          this.isLoading = true;
          const projectResponse = await this.projectService.getProjectById(
            params['id']
          );

          this.project = projectResponse.project;

          if (!this.project) {
            return;
          }

          this.projectForm = this.formBuilder.group({
            archived: new FormControl(this.project.archived, {}),
            advertiserEvents: new FormControl('', {}),
            campaignStartDate: new FormControl(
              this.fromProtoDate(this.project.campaignStartDay),
              {}
            ),
            campaignEndDate: new FormControl(
              this.fromProtoDate(this.project.campaignEndDay),
              {}
            ),
            timezoneOffsetMinutes: new FormControl(
              this.project.campaignTimezoneOffsetMinutes,
              {}
            ),
            lookbackWindowClickDays: new FormControl(
              this.project.lookbackWindow?.clickDays,
              {}
            ),
            lookbackWindowViewDays: new FormControl(
              this.project.lookbackWindow?.viewDays,
              {}
            ),
            postPeriodDays: new FormControl(this.project.postPeriodDays, {}),
            notes: new FormControl(this.project.notes, {}),
            studyId: new FormControl(this.project.studyId, {}),
            state: new FormControl(this.project.state, {}),
            metrics: this.metricRows,
          });

          if (this.project.matchKeys) {
            this.selectedMatchKeys = this.project.matchKeys;
          }

          if (this.project.advertiserEvents) {
            this.selectedAdvertiserEvents = this.project.advertiserEvents;
          }

          if (this.project.campaignTimezoneOffsetMinutes) {
            this.selectedTimezoneOffsetMinutes =
              this.project.campaignTimezoneOffsetMinutes;
          }

          if (this.project.customerDataSets['advertiser']) {
            this.selectedAdvertiserDataSetReference =
              this.project.customerDataSets['advertiser'];
          }

          if (this.project.customerDataSets['publisher']) {
            this.selectedPublisherDataSetReference =
              this.project.customerDataSets['publisher'];
          }

          if (this.project.customerDataSets['publisher-user']) {
            this.selectedPublisherUserDataSetReference =
              this.project.customerDataSets['publisher-user'];
          }

          this.selectedAdvertiserId = this.project.advertiserId;
          this.selectedBinaryType = this.project.binaryType;
          this.selectedLocation = this.project.location;
          this.selectedPublisherId = this.project.publisherId;
          this.selectedState = this.project.state;

          this.updateMetricsTable();
        } catch (error) {
          this.logger.error('Unable to load project.', error, 15);
          this.messageBox.error('Unable to load project.');
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  addEventMetric() {
    const emptyRow = this.formBuilder.group({
      advertiserEventType:
        AdvertiserEventType.ADVERTISER_EVENT_TYPE_UNSPECIFIED,
      notes: '',
      amounts: false,
      numUnits: false,
    });
    this.metrics.push(emptyRow);
    this.metricsDataSource.data = this.metrics.value;
  }

  displayPublisherUserDataset() {
    return this.selectedBinaryType === BinaryType.BINARY_TYPE_REDDIT_LIFT;
  }

  displayLookbackWindowViewDays() {
    return this.selectedBinaryType === BinaryType.BINARY_TYPE_ATTRIBUTION;
  }

  displayStudyId() {
    return this.selectedBinaryType === BinaryType.BINARY_TYPE_LIFT;
  }

  getDefaultLocations() {
    LocationList.forEach((location) => {
      this.allowedCustomerLocations.push(location.value);
    });
  }

  getEventTypes() {
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      this.eventTypes.push(parseInt(value));
    }
  }

  async process() {
    const { value } = this.projectForm;

    if (this.validate()) {
      this.isLoading = true;

      let project = new Project();

      if (this.update && this.project) {
        try {
          this.isLoading = true;
          const projectResponse = await this.projectService.getProjectById(
            this.project.id
          );
          if (projectResponse.project) {
            project = projectResponse.project;
          }
        } catch (e: any) {
          this.logger.error(e);
          this.messageBox.error(
            'Failed to retreive latest etag from project.',
            e.message
          );
        } finally {
          this.isLoading = false;
        }
      }

      if (this.selectedAdvertiserId) {
        project.advertiserId = this.selectedAdvertiserId;
      }

      if (this.selectedPublisherId) {
        project.publisherId = this.selectedPublisherId;
      }

      if (this.selectedLocation) {
        project.location = this.selectedLocation;
      }

      if (this.selectedState) {
        project.state = this.selectedState;
      }

      if (this.selectedBinaryType) {
        project.binaryType = this.selectedBinaryType;
      }

      const lookbackWindow = new LookbackWindow();
      if (value.lookbackWindowClickDays) {
        lookbackWindow.clickDays = value.lookbackWindowClickDays;
      }

      if (value.lookbackWindowViewDays) {
        lookbackWindow.viewDays = value.lookbackWindowViewDays;
      }

      if (this.selectedMatchKeys) {
        project.matchKeys = this.selectedMatchKeys;
      }

      if (this.selectedTimezoneOffsetMinutes) {
        project.campaignTimezoneOffsetMinutes =
          this.selectedTimezoneOffsetMinutes;
      }

      project.campaignEndDay = this.toProtoDate(value.campaignEndDate);
      project.campaignStartDay = this.toProtoDate(value.campaignStartDate);
      project.archived = value.archived;
      project.lookbackWindow = lookbackWindow;
      project.postPeriodDays = value.postPeriodDays;
      project.notes = value.notes;
      project.studyId = value.studyId;
      project.advertiserEvents = this.getAdvertiserEvents();

      const customerDataSets: { [prop: string]: CustomerDataSetReference } = {};
      if (this.selectedAdvertiserDataSetReference) {
        customerDataSets['advertiser'] =
          this.selectedAdvertiserDataSetReference;
      }

      if (this.selectedPublisherDataSetReference) {
        customerDataSets['publisher'] = this.selectedPublisherDataSetReference;
      }

      if (this.selectedPublisherUserDataSetReference) {
        customerDataSets['publisher-user'] =
          this.selectedPublisherUserDataSetReference;
      }
      project.customerDataSets = customerDataSets;

      if (this.update) {
        await this.updateProject(project);
      } else {
        await this.createProject(project);
      }
    }
  }

  async createProject(project: Project) {
    try {
      await this.projectService.createProject(project);
      this.messageBox.success(
        'Project created succesfully.',
        () => {
          this.router.navigate(['projects']);
        },
        5
      );
    } catch (e: any) {
      this.logger.error(e);
      this.messageBox.error('Failed to create new project.', e.message);
    } finally {
      this.isLoading = false;
    }
  }

  async updateProject(project: Project) {
    try {
      await this.projectService.updateProject(project);
      this.messageBox.success(
        'Project updated.',
        () => {
          this.router.navigate(['projects']);
        },
        5
      );
    } catch (e: any) {
      this.logger.error(e);
      this.messageBox.error('Failed to update project.', e.message);
    } finally {
      this.isLoading = false;
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.selectedState === State.STATE_PROJECT_READY
      ? this.formHelper.checkError(controlName, errorName)
      : true;
  }

  getAdvertiserEventName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  getAdvertiserEvents(): AdvertiserEvents[] {
    if (!this.metrics) return [];
    return this.metrics.controls
      .filter(
        (row: any) =>
          row.get('advertiserEventType')?.value !=
          AdvertiserEventType.ADVERTISER_EVENT_TYPE_UNSPECIFIED
      )
      .map((row: any) => {
        const advertiserEvent = new AdvertiserEvents();
        advertiserEvent.advertiserEventType = row.get(
          'advertiserEventType'
        )?.value;
        advertiserEvent.notes = row.get('notes')?.value || null;
        advertiserEvent.includeAmounts = row.get('amounts')?.value || null;
        advertiserEvent.includeNumUnits = row.get('numUnits')?.value || null;
        return advertiserEvent;
      });
  }

  getLocationName(location: Location | undefined): string {
    if (!location) {
      return 'Unknown';
    }

    return LocationList.find((l) => l.value === location)?.name || 'Unknown';
  }

  async getDatasets(customerId: string) {
    const listRequest = new ListRequest();
    listRequest.filter = new ListFilter();
    if (customerId) {
      listRequest.filter.customerId = customerId;
    }
    let continuationToken = '';

    const datasets: CustomerDataSet[] = [];
    const paginated = new GetPaginatedRequest();
    paginated.numRecords = RECORDS_PER_PAGE;

    do {
      paginated.continuationToken = continuationToken;
      listRequest.paginated = paginated;
      const result = await this.customerDataService.list(listRequest);
      result.customerDataSets?.forEach((dataset) => {
        datasets.push(dataset);
      });
      if (result.paginatedResponse) {
        continuationToken = result.paginatedResponse.continuationToken;
      }
    } while (continuationToken);
    return datasets;
  }

  onBinaryTypeSelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.dateLabel = this.displayStudyId() ? 'Study' : 'Campaign';
  }

  onLocationSelect(location: Location) {
    this.selectedLocation = location;
  }

  onStateSelect(state: State) {
    this.selectedState = state;
  }

  onAdvertiserSelect(customerId: string) {
    this.selectedAdvertiserId = customerId;
  }

  onAdvertiserSelectDatasetsInfo(data: CustomerDataSetInfo) {
    this.selectedAdvertiserDataSetReference = data.customerDataSetReference;
  }

  onMatchKeysEvent(matchKeys: string[]) {
    this.selectedMatchKeys = matchKeys;
  }

  onPublisherSelectDatasetsInfo(data: CustomerDataSetInfo) {
    this.selectedPublisherDataSetReference = data.customerDataSetReference;
  }

  onPublisherSelect(customerId: string) {
    this.selectedPublisherId = customerId;
  }

  onPublisherUserSelectDatasetsInfo(data: CustomerDataSetInfo) {
    this.selectedPublisherUserDataSetReference = data.customerDataSetReference;
  }

  onTimezoneSelect(timezoneOffset: number) {
    this.selectedTimezoneOffsetMinutes = timezoneOffset;
  }

  setupMetricsTable() {
    this.metrics.clear();

    this.selectedAdvertiserEvents.forEach((value) => {
      const row = this.formBuilder.group({
        advertiserEventType: value.advertiserEventType,
        notes: value.notes,
        amounts: value.includeAmounts,
        numUnits: value.includeNumUnits,
      });
      this.metricRows.push(row);
    });
    this.metricsDataSource.data = this.metrics.value;
  }

  removeEventMetric(index: number) {
    this.metrics.removeAt(index);
    this.metricsDataSource.data = this.metrics.value;
  }

  updateMetricsTable(): void {
    this.metrics.clear(); // Clear existing controls

    if (this.project?.advertiserEvents?.length) {
      const controls = this.project.advertiserEvents.map((advertiserEvent) =>
        this.formBuilder.group({
          advertiserEventType: advertiserEvent.advertiserEventType,
          notes: advertiserEvent.notes,
          amounts: advertiserEvent.includeAmounts,
          numUnits: advertiserEvent.includeNumUnits,
        })
      );
      this.metrics.controls.push(...controls);
    }
    this.metricsDataSource.data = this.metrics.controls;
  }

  toProtoDate(dateString: string) {
    if (!dateString) return undefined;

    const [year, month, day] = dateString.split('-').map(Number);
    return Object.assign(new ProtoDate(), { year, month, day });
  }

  fromProtoDate(protoDate: ProtoDate | undefined) {
    if (!protoDate || protoDate.year <= 0) return '';

    const { year, month, day } = protoDate;
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }

  validate(): boolean {
    const { value } = this.projectForm;

    if (!this.validateBinaryType()) return false;

    if (this.selectedState === State.STATE_PROJECT_READY) {
      if (!this.validateAdvertiserAndPublisher()) return false;
      if (!this.validateLocation()) return false;
      if (!this.validateDatasetReferences()) return false;
      if (!this.validateCampaignDates(value)) return false;
      if (!this.validateNumericValues(value)) return false;
      if (!this.validateTextValues(value)) return false;
    }

    return true;
  }

  private validateAdvertiserAndPublisher(): boolean {
    const isValid = Boolean(
      this.selectedAdvertiserId && this.selectedPublisherId
    );

    if (!isValid) {
      this.messageBox.error('Publisher and advertiser cannot be blank.');
    }

    return isValid;
  }

  private validateBinaryType(): boolean {
    if (
      this.selectedBinaryType === BinaryType.BINARY_TYPE_UNSPECIFIED ||
      this.selectedBinaryType === undefined
    ) {
      this.messageBox.error('Binary type cannot be unspecified.');
      return false;
    }
    return true;
  }

  private validateLocation(): boolean {
    if (
      this.selectedLocation === Location.LOCATION_UNSPECIFIED ||
      this.selectedLocation === undefined
    ) {
      this.messageBox.error('Location cannot be unspecified.');
      return false;
    }
    return true;
  }

  private validateDatasetReferences(): boolean {
    if (
      !this.selectedAdvertiserDataSetReference ||
      this.selectedAdvertiserDataSetReference.id.length === 0
    ) {
      this.messageBox.error('Advertiser dataset reference must be specified.');
      return false;
    }
    if (
      !this.selectedPublisherDataSetReference ||
      this.selectedPublisherDataSetReference.id.length === 0
    ) {
      this.messageBox.error('Publisher dataset reference must be specified.');
      return false;
    }
    if (
      !this.selectedPublisherUserDataSetReference &&
      this.selectedBinaryType === BinaryType.BINARY_TYPE_REDDIT_LIFT
    ) {
      return false;
    }
    return true;
  }

  private validateCampaignDates(value: any): boolean {
    const campaignStartDate = new Date(value.campaignStartDate);
    const campaignEndDate = new Date(value.campaignEndDate);

    if (isNaN(campaignStartDate.getTime())) {
      this.messageBox.error('Start date must be valid.');
      return false;
    }
    if (isNaN(campaignEndDate.getTime())) {
      this.messageBox.error('End date must be valid.');
      return false;
    }
    if (campaignEndDate < campaignStartDate) {
      this.messageBox.error('End date must be after start date.');
      return false;
    }
    return true;
  }

  private validateNumericValues(value: any): boolean {
    if (
      value.timezoneOffsetMinutes < -720 ||
      value.timezoneOffsetMinutes > 720
    ) {
      this.messageBox.error('Time offset not in range [-720,720].');
      return false;
    }
    if (value.lookbackWindowClickDays < 0) {
      this.messageBox.error('Lookback window click days cannot be negative.');
      return false;
    }
    if (value.lookbackWindowViewDays < 0) {
      this.messageBox.error('Lookback window view days cannot be negative.');
      return false;
    }
    if (value.postPeriodDays < 0) {
      this.messageBox.error('Post period days cannot be negative.');
      return false;
    }
    return true;
  }

  private validateTextValues(value: any): boolean {
    if (
      !value.studyId &&
      this.selectedBinaryType === BinaryType.BINARY_TYPE_LIFT
    ) {
      this.messageBox.error('Time offset in minutes cannot be negative.');
      return false;
    }

    return true;
  }
}
