/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum BinaryType {
  BINARY_TYPE_UNSPECIFIED = 0,
  BINARY_TYPE_ATTRIBUTION = 1,
  BINARY_TYPE_DENTSU_PROTOTYPE = 6,
  BINARY_TYPE_ENCLAVE_ENCRYPTION = 5,
  BINARY_TYPE_LIFT = 2,
  BINARY_TYPE_LIFT_DASK = 3,
  BINARY_TYPE_REDDIT_ATTRIBUTION = 9,
  BINARY_TYPE_REDDIT_LIFT = 8,
  BINARY_TYPE_TIKTOK_TARGETING = 7,
  BINARY_TYPE_VALIDATION = 4,
  BINARY_TYPE_POSTPROCESSING = 10,
}
/**
 * Message implementation for storage.BinaryTypeInfo
 */
export class BinaryTypeInfo implements GrpcMessage {
  static id = 'storage.BinaryTypeInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryTypeInfo();
    BinaryTypeInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryTypeInfo) {
    _instance.binaryType = _instance.binaryType || 0;

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.deprecated = _instance.deprecated || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryTypeInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.binaryType = _reader.readEnum();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        case 5:
          _instance.lastUpdate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.deprecated = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryTypeInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BinaryTypeInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.binaryType) {
      _writer.writeEnum(1, _instance.binaryType);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(4, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        5,
        _instance.lastUpdate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.deprecated) {
      _writer.writeBool(6, _instance.deprecated);
    }
  }

  private _binaryType: BinaryType;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf000.Timestamp;
  private _name: string;
  private _description: string;
  private _deprecated: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryTypeInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryTypeInfo.AsObject>) {
    _value = _value || {};
    this.binaryType = _value.binaryType;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf000.Timestamp(_value.lastUpdate)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    this.deprecated = _value.deprecated;
    BinaryTypeInfo.refineValues(this);
  }
  get binaryType(): BinaryType {
    return this._binaryType;
  }
  set binaryType(value: BinaryType) {
    this._binaryType = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get deprecated(): boolean {
    return this._deprecated;
  }
  set deprecated(value: boolean) {
    this._deprecated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryTypeInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryTypeInfo.AsObject {
    return {
      binaryType: this.binaryType,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      name: this.name,
      description: this.description,
      deprecated: this.deprecated,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryTypeInfo.AsProtobufJSON {
    return {
      binaryType:
        BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      name: this.name,
      description: this.description,
      deprecated: this.deprecated,
    };
  }
}
export module BinaryTypeInfo {
  /**
   * Standard JavaScript object representation for BinaryTypeInfo
   */
  export interface AsObject {
    binaryType: BinaryType;
    etag?: string;
    lastUpdate?: googleProtobuf000.Timestamp.AsObject;
    name: string;
    description: string;
    deprecated: boolean;
  }

  /**
   * Protobuf JSON representation for BinaryTypeInfo
   */
  export interface AsProtobufJSON {
    binaryType: string;
    etag: string | null;
    lastUpdate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
    deprecated: boolean;
  }
}
