import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TenantInfo } from 'app/services/generated/src/main/proto/api/customer-service.pb';

import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-tenant-select',
  templateUrl: './tenant-select-modal.component.html',
  styleUrls: ['./tenant-select-modal.component.scss'],
  standalone: false,
})
export class TenantSelectModalComponent {
  form = new FormGroup({
    customerSelect: new FormControl(''),
  });
  tenantInfos: TenantInfo[] | undefined;
  selectedTenantId: string | undefined;

  constructor(
    private dialogRef: MatDialogRef<TenantSelectModalComponent>,
    private router: Router,
    private messageBox: MessageBoxProvider,
    @Inject(MAT_DIALOG_DATA)
    public data: TenantInfo[]
  ) {
    this.tenantInfos = data.sort((a, b) =>
      a.companyName.localeCompare(b.companyName)
    );
    this.selectedTenantId = data[0].tenantId;
  }

  public tenantSelectPopup() {
    const tenantId = this.form.value.customerSelect;

    if (!tenantId) {
      this.messageBox.show('Please select an account to sign in to.', 'error');
      return;
    }

    this.dialogRef.close(tenantId);
  }

  public goBack() {
    this.router.navigate(['sign-in']);
  }
}
