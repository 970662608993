import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';

import { Date as ProjectDate } from './generated/src/main/proto/storage/project.pb';

/**
 * Service to handle various formatting tasks, such as date and time formatting.
 */
@Injectable({
  providedIn: 'root',
})
export class FormatService {
  defaultDateTimeOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  /**
   * Formats a protobuf Timestamp to a localized date string.
   *
   * @param timestamp - The protobuf Timestamp to format.
   * @returns The formatted date string, or undefined if the timestamp is null or undefined.
   */
  public formatProtoDate(
    timestamp: Timestamp | undefined | null
  ): string | undefined {
    if (timestamp) {
      return timestamp.toDate().toLocaleDateString(this.locale);
    } else {
      return undefined;
    }
  }

  /**
   * Formats a protobuf Timestamp to a date string suitable for input fields (YYYY-MM-DD).
   *
   * @param timestamp - The protobuf Timestamp to format.
   * @returns The formatted date string, or null if the timestamp is null or undefined.
   */
  public formatProtoDateForInput(
    timestamp: Timestamp | undefined | null
  ): string | null {
    if (timestamp) {
      const date = timestamp.toDate();
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = date.getUTCDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  /**
   * Formats a project date to a human readable format in UTC.
   *
   * @param date - Date to convert.
   * @returns The human readable string for the date.
   */
  public formatProjectDateToDateUTC(date: ProjectDate): Date {
    return new Date(Date.UTC(date.year, date.month - 1, date.day));
  }

  /**
   * Formats a project date to a human readable format.
   *
   * @param date - Date to convert.
   * @returns The human readable string for the date.
   */
  public formatProjectDateToDate(date: ProjectDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  public formatDateForInput(date: Date) {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Formats a protobuf Timestamp to a localized date and time string.
   *
   * @param timestamp - The protobuf Timestamp to format.
   * @param trimSeconds - Whether to trim the seconds from the formatted string (default is true).
   * @returns The formatted date and time string, or undefined if the timestamp is null or undefined.
   */
  public formatProtoDateTime(
    timestamp: Timestamp | undefined | null,
    trimSeconds = true
  ): string | undefined {
    let options = {};
    if (trimSeconds) {
      options = this.defaultDateTimeOptions;
    }
    if (timestamp && parseInt(timestamp.seconds) > 0) {
      return timestamp.toDate().toLocaleString(this.locale, options);
    } else {
      return undefined;
    }
  }

  /**
   * Formats an ISO date string to a localized date and time string.
   *
   * @param date - The ISO date string to format.
   * @returns The formatted date and time string.
   */
  public formatISODate(date: string) {
    return new Date(date).toLocaleString(
      this.locale,
      this.defaultDateTimeOptions
    );
  }

  /**
   * Formats a timestamp (number) to a localized date and time string.
   *
   * @param timestamp - The timestamp to format.
   * @returns The formatted date and time string.
   */
  public formatTimestamp(timestamp: number) {
    return new Date(timestamp).toLocaleString(
      this.locale,
      this.defaultDateTimeOptions
    );
  }

  /**
   * Retrieves the current date and time as a localized string.
   *
   * @returns The current date and time string.
   */
  public getLastUpdate(): string {
    return new Date().toLocaleString(this.locale, this.defaultDateTimeOptions);
  }

  /**
   * Formats a number of bytes into a human-readable string with appropriate units.
   *
   * @param bytes - The number of bytes to format.
   * @param decimals - The number of decimal places to include (default is 2).
   * @returns The formatted string with appropriate units.
   */
  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) {
      return '0 Bytes';
    } else {
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = [
        'Bytes',
        'KiB',
        'MiB',
        'GiB',
        'TiB',
        'PiB',
        'EiB',
        'ZiB',
        'YiB',
      ];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }
  }
}
