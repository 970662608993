import { PermissionRoles } from 'app/services/permission.service';

import { Node } from './model';

export const NODES: Node[] = [
  {
    name: 'Home',
    icon: 'home_menu',
    route: 'landing-page',
    custom_icon: true,
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    name: 'Getting Started',
    icon: 'prep_list',
    route: 'getting-started',
    custom_icon: true,
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    name: 'Your Binaries',
    icon: 'your_binaries',
    route: 'audit-approval',
    custom_icon: true,
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    name: 'API Integrations',
    icon: 'apps',
    route: 'app-manager',
    custom_icon: false,
    data: {
      expectedRoles: [PermissionRoles.PartnerAdmin],
    },
  },
  {
    name: 'Job Activity',
    icon: 'job_activity',
    route: 'job',
    custom_icon: true,
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    name: 'Anonym Public Key',
    route: 'anonym-public-key',
    icon: 'vpn_key',
    custom_icon: false,
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    name: 'Data Upload',
    icon: 'file_upload',
    route: 'app-data-manager',
    custom_icon: false,
    data: {
      expectedRoles: [PermissionRoles.PortalContributor],
    },
  },
  {
    name: 'Knowledge Base',
    icon: 'knowledge_base',
    custom_icon: true,
    route: '#',
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
    children: [],
  },
  {
    name: 'Account Settings',
    icon: 'person',
    route: 'settings-page',
    custom_icon: false,
    data: {
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    name: 'Admin',
    icon: 'extension',
    route: 'admin',
    custom_icon: false,
    children: [
      {
        name: 'Admin Storage Credentials',
        icon: 'file_upload',
        route: 'admin-storage-credential',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Approvals',
        icon: 'cloud_upload',
        route: 'audit-approvals-manager',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Customers',
        icon: 'group',
        route: 'customer-page',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymAdmin],
        },
      },
      {
        name: 'Datasets',
        icon: 'create_new_folder',
        route: 'datasets',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Documentation',
        route: 'documentation-page',
        custom_icon: false,
        icon: 'file_download',
        data: {
          expectedRoles: [PermissionRoles.AnonymAdmin],
        },
      },
      {
        name: 'Jobs',
        icon: 'work',
        route: 'jobs',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Keys',
        icon: 'vpn_key',
        route: 'keys',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Projects',
        icon: 'business',
        route: 'projects',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Schedules',
        icon: 'schedule',
        route: 'job-schedule',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymJobAdmin],
        },
      },
      {
        name: 'Users',
        icon: 'accessibility',
        route: 'users-manager',
        custom_icon: false,
        data: {
          expectedRoles: [PermissionRoles.AnonymAdmin],
        },
      },
    ],
    data: {
      expectedRoles: [
        PermissionRoles.AnonymJobAdmin,
        PermissionRoles.AnonymAdmin,
      ],
    },
  },
];
