import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddUserModalComponent } from 'app/modals/add-user-modal/add-user-modal.component';
import { CustomerService } from 'app/services/customer.service';
import { UserInfo } from 'app/services/generated/src/main/proto/api/user-service.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { LoggerService } from 'app/services/logger.service';
import { UserService } from 'app/services/user.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-admin-users-manager',
  templateUrl: './admin-users-manager.component.html',
  styleUrls: ['./admin-users-manager.component.scss'],
  standalone: false,
})
export class AdminUsersManagerComponent implements AfterViewInit, OnInit {
  public columnsToDisplay: string[] = [
    'UserName',
    'Email',
    'Role',
    'Status',
    'Delete',
  ];
  anonymTenantId: string | undefined;
  isLoadingUsers = false;
  customers: Customer[] | undefined;
  selectedTenantId: string | undefined;
  users: MatTableDataSource<UserInfo> = new MatTableDataSource();

  constructor(
    public customerService: CustomerService,
    public userService: UserService,
    public dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private messageBox: MessageBoxProvider,
    private logger: LoggerService
  ) {}

  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  ngOnInit(): void {
    if (this.selectedTenantId) {
      this.getUsers(this.selectedTenantId);
    }
  }

  ngAfterViewInit() {
    this.users.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getUsers(tenantId: string) {
    this.isLoadingUsers = true;
    Promise.all([
      this.userService.getCustomerUsers(tenantId).then((res) => {
        this.users.data = res.users ?? [];
      }),
      this.customerService.getCustomer().then((res) => {
        // We assume the current user is an anonym user because they have access
        // to this screen.
        this.anonymTenantId = res.customer?.tenantId;
      }),
    ])
      .catch((error) => this.logger.error(error))
      .finally(() => {
        this.isLoadingUsers = false;
      });
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.selectedTenantId = customer.tenantId;
      this.getUsers(this.selectedTenantId);
    }
  }

  changeUserState(userInfo: UserInfo): void {
    let state = 'Disable';
    if (userInfo.disabled) {
      state = 'Enable';
    }

    if (confirm(state + ' user ' + userInfo.displayName + ' ?') == true) {
      this.isLoadingUsers = true;
      userInfo.disabled = !userInfo.disabled;
      this.userService
        .updateUser(userInfo, true)
        .then(() => {
          this.messageBox.success('User state changed succesfully.');
        })
        .catch((e) => {
          this.logger.error(e);
          this.messageBox.error('Unable to change user state.');
        })
        .finally(() => {
          this.isLoadingUsers = false;
          this.ngOnInit();
        });
    }
  }

  deleteUser(userInfo: UserInfo): void {
    if (confirm(`Delete user ${userInfo.displayName} ?`) == true) {
      this.isLoadingUsers = true;
      let isAnonym = false;
      let tenantId = undefined;

      if (this.anonymTenantId === userInfo.tenantId) {
        isAnonym = true;
      } else {
        tenantId = userInfo.tenantId;
      }
      this.userService
        .deleteUser(userInfo.uid, isAnonym, tenantId)
        .then(() => {
          this.messageBox.success('User deleted succesfully.');
          this.users.data = this.users.data.filter((user) => {
            return user !== userInfo;
          });
        })
        .catch((e) => {
          this.logger.error(e);
          this.messageBox.error('Unable to delete user.');
        })
        .finally(() => {
          this.isLoadingUsers = false;
          this.ngOnInit();
        });
    }
  }

  editUser(userInfo: UserInfo) {
    const dialogRef = this.dialog.open(AddUserModalComponent, {
      data: {
        tenantId: this.selectedTenantId,
        isAdmin: true,
        isAnonym:
          this.anonymTenantId && this.selectedTenantId === this.anonymTenantId,
        userInfo: userInfo,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  openCreateUserDialog() {
    const dialogRef = this.dialog.open(AddUserModalComponent, {
      data: {
        tenantId: this.selectedTenantId,
        isAdmin: true,
        isAnonym:
          this.anonymTenantId && this.selectedTenantId === this.anonymTenantId,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }
}
