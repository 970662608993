import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AdminModule } from 'app/modules/admin/admin.module';

interface TimeOffset {
  offsetMinutes: number;
  label: string;
}

@Component({
  selector: 'app-timezone-offset-selector',
  imports: [
    CommonModule,
    AdminModule,
    MatCheckboxModule,
    MatChipsModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './timezone-offset-selector.component.html',
  styleUrl: './timezone-offset-selector.component.scss',
})
export class TimezoneOffsetSelectorComponent {
  @Input() selectedTimezoneOffsetMinutes: number | undefined = 0;
  @Output() selectTimezoneOffsetEvent = new EventEmitter<number>();
  timeOffsets: TimeOffset[];

  constructor() {
    const offsets: TimeOffset[] = [];

    // Loop over -12 to +14 hours for all possible UTC offsets
    for (let offsetHours = -12; offsetHours <= 14; offsetHours++) {
      const offsetMinutes = offsetHours * 60;
      const sign = offsetHours >= 0 ? '+' : '-';
      const absHours = Math.abs(offsetHours);
      const label = `UTC${sign}${absHours.toString().padStart(2, '0')}:00`;
      offsets.push({ offsetMinutes, label });
    }

    this.timeOffsets = offsets;
  }

  onTimezoneOffsetSelect(timezoneOffset: number) {
    this.selectedTimezoneOffsetMinutes = timezoneOffset;
    this.selectTimezoneOffsetEvent.emit(timezoneOffset);
  }
}
