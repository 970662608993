import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { Auth, getIdTokenResult } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { AddCustomerModalComponent } from 'app/modals/add-customer-modal/add-customer-modal.component';
import {
  ApiApprovalRequestAllCustomers,
  DecisionRollup,
} from 'app/services/generated/src/main/proto/api/approval-service.pb';
import { ApprovedViewers } from 'app/services/generated/src/main/proto/storage/approval.pb';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { LoggerService } from 'app/services/logger.service';

import { ApprovalService } from '../../services/approval.service';
import { BinaryTypeService } from '../../services/binary-type.service';
import { FormatService } from '../../services/format.service';
import { ApproverDetailsDialogComponent } from './approver-details-dialog/approver-details-dialog.component';

type ApprovalTable = {
  id: string | undefined;
  binaryType: BinaryType;
  version: string | undefined;
  creationTime: Timestamp | undefined;
  approvedViewers: ApprovedViewers | undefined;
  etag: string | undefined;
  decisionRollups: DecisionRollup[] | undefined;
};

interface Page {
  continuationToken: string | null;
}

const RECORDS_PER_PAGE = 15;

@Component({
  selector: 'app-admin-audit-approval',
  templateUrl: './admin-audit-approval.component.html',
  styleUrls: ['./admin-audit-approval.component.scss'],
  standalone: false,
})
export class AdminAuditApprovalComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  anonymTenantId: string | null | undefined = undefined;
  approvalRequests: MatTableDataSource<ApprovalTable> =
    new MatTableDataSource();
  displayArchived = false;
  binaryTypeInfos?: BinaryTypeInfo[] = [];
  currentPageIndex = 0;
  isLoadingApprovals = false;
  selectedBinaryType: BinaryType | undefined;
  time: string = new Date().toLocaleString();
  totalRecords = 0;
  pages: Page[] = [{ continuationToken: null }];
  pageSize = RECORDS_PER_PAGE;

  public showCreateCustomer!: boolean;
  public columnsToDisplay: string[] = [
    'Id',
    'BinaryType',
    'Version',
    'CreationTime',
    'Assigned',
  ];

  constructor(
    @Optional() private auth: Auth,
    public approvalService: ApprovalService,
    public binaryTypeService: BinaryTypeService,
    private dialog: MatDialog,
    private formatService: FormatService,
    private liveAnnouncer: LiveAnnouncer,
    private logger: LoggerService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user !== null) {
        getIdTokenResult(user).then((result) => {
          const {
            claims: { Roles },
          } = result;
          this.showCreateCustomer = (Roles as string)
            .split(',')
            .includes('AnonymAdmin');
        });
      }
    });

    this.binaryTypeService.getBinaryTypes().then((getBinaryTypeResponse) => {
      this.binaryTypeInfos = getBinaryTypeResponse.binaryTypeInfos;
    });
  }

  ngOnInit(): void {
    this.currentPageIndex = 0;
    this.pages = [{ continuationToken: null }];
    this.anonymTenantId = this.auth.currentUser?.tenantId;
    this.getCustomerApprovals(this.currentPageIndex);
  }

  ngAfterViewInit() {
    this.approvalRequests.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction} ending.`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared.');
    }
  }

  createCustomer(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(AddCustomerModalComponent, dialogConfig);
  }

  editCustomer(customer: Customer): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = customer;

    this.dialog.open(AddCustomerModalComponent, dialogConfig);
  }

  setBinaryType(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(AddCustomerModalComponent, dialogConfig);
  }

  formatDate(time: any) {
    return this.formatService.formatProtoDateTime(time);
  }

  displayBinaryTypes(binaryTypes: BinaryType[]) {
    const result: string[] = [];
    if (binaryTypes.length === 0) {
      return 'None';
    } else {
      binaryTypes.forEach((binaryType) => {
        this.binaryTypeInfos?.forEach((binaryTypeInfo) => {
          if (binaryTypeInfo.binaryType === binaryType) {
            result.push(binaryTypeInfo.name);
          }
        });
      });
      return result.join(' | ');
    }
  }

  getCustomerApprovals(pageIndex: number) {
    this.isLoadingApprovals = true;
    const page = this.pages[pageIndex];
    this.currentPageIndex = pageIndex;

    const binaryType = this.selectedBinaryType
      ? [this.selectedBinaryType]
      : undefined;
    this.approvalService
      .getCustomerApproval(
        this.pageSize,
        page.continuationToken,
        binaryType,
        this.displayArchived
      )
      .then((response) => {
        if (response.approvalRequests !== undefined) {
          this.approvalRequests.data = this.formatApprovalData(
            response.approvalRequests
          );

          if (page.continuationToken === null) {
            // When we fetch the first page, (no continuation token), we receive the
            // total records count.
            if (response.paginatedResponse?.count) {
              this.totalRecords = response.paginatedResponse?.count;
            }
          }

          if (response.paginatedResponse?.continuationToken) {
            this.pages[this.currentPageIndex + 1] = {
              continuationToken: response.paginatedResponse.continuationToken,
            };
          }
        }
      })
      .catch((error) =>
        this.logger.error('Error loading approval requests data.', error)
      )
      .finally(() => {
        this.isLoadingApprovals = false;
      });
  }

  formatApprovalData(approvalRequests: ApiApprovalRequestAllCustomers[]) {
    const approvalTable: ApprovalTable[] = [];
    approvalRequests.forEach((approvalRequest) => {
      const approvalData: ApprovalTable = {
        id: approvalRequest.id,
        binaryType:
          approvalRequest.approvalRequestDetail!.change!.binaryInfo!.binaryType,
        version:
          approvalRequest.approvalRequestDetail!.change!.binaryInfo!.version,
        creationTime: approvalRequest.approvalRequestDetail!.creationTime,
        approvedViewers: approvalRequest.approvedViewers,
        etag: approvalRequest.etag,
        decisionRollups: approvalRequest.decisionRollups,
      };
      approvalTable.push(approvalData);
    });
    return approvalTable;
  }

  async onPageChange(event: PageEvent) {
    this.getCustomerApprovals(event.pageIndex);
  }

  onBinaryTypeSelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.ngOnInit();
  }

  onArchiveSelect(displayArchived: boolean) {
    this.displayArchived = displayArchived;
    this.ngOnInit();
  }

  openDetails(
    approvalId: string,
    etag: string,
    approvedViewers: ApprovedViewers,
    decisionRollups: DecisionRollup[]
  ) {
    this.dialog
      .open(ApproverDetailsDialogComponent, {
        data: {
          approvalId: approvalId,
          etag: etag,
          approvedViewers: approvedViewers,
          decisionRollups: decisionRollups,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.getCustomerApprovals(this.currentPageIndex);
      });
  }

  async archive(id: string, etag: string) {
    if (confirm(`Archive data set with ${id}.`) == true) {
      await this.approvalService.archive(id, etag);
      this.ngOnInit();
    }
  }
}
