import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  CreateRequest,
  CreateResponse,
  GetProjectRequest,
  GetProjectResponse,
  ListProjectsRequest,
  ListProjectsResponse,
  UpdateProjectRequest,
  UpdateProjectResponse,
} from './generated/src/main/proto/api/project-service.pb';
import { ProjectServiceClient } from './generated/src/main/proto/api/project-service.pbsc';
import { Project } from './generated/src/main/proto/storage/project.pb';

/**
 * Service to manage projects including retrieving, creating, updating.
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private auth: Auth,
    private apiAuthService: ApiAuthService,
    private projectService: ProjectServiceClient
  ) {}

  /**
   * Creates a new project.
   *
   * @param project - A project record to create.
   * @returns A promise that resolves to a CreateResponse containing an ETAG.
   */
  public async createProject(project: Project): Promise<CreateResponse> {
    const grpcMetaData = await this.getAuthenticatedHeader();
    const createRequest = new CreateRequest();
    createRequest.project = project;

    return firstValueFrom(
      this.projectService.create(createRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves a list of projects based on the provided filters.
   *
   * @param listProjectsRequest - Request parameters for listing projects.
   * @returns A promise that resolves to a ListProjectsResponse containing a list of projects.
   */
  public async listProjects(
    listProjectsRequest: ListProjectsRequest
  ): Promise<ListProjectsResponse> {
    const grpcMetaData = await this.getAuthenticatedHeader();
    return firstValueFrom(
      this.projectService.list(listProjectsRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves a project by its ID.
   *
   * @param projectId - The ID of the project to retrieve.
   * @returns A promise that resolves to a GetProjectResponse containing the project details.
   */
  public async getProjectById(projectId: string): Promise<GetProjectResponse> {
    const grpcMetaData = await this.getAuthenticatedHeader();
    const getProjectRequest = new GetProjectRequest();
    getProjectRequest.projectId = projectId;

    return firstValueFrom(
      this.projectService.get(getProjectRequest, grpcMetaData)
    );
  }

  /**
   * Updates an existing project.
   *
   * @param project - A project record to update.
   * @returns A promise that resolves to an UpdateProjectResponse containing an ETAG.
   */
  public async updateProject(project: Project): Promise<UpdateProjectResponse> {
    const grpcMetaData = await this.getAuthenticatedHeader();
    const updateRequest = new UpdateProjectRequest();
    updateRequest.project = project;

    return firstValueFrom(
      this.projectService.update(updateRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves authenticated headers for gRPC requests.
   *
   * @returns A promise that resolves to the authenticated gRPC metadata.
   */
  private getAuthenticatedHeader() {
    return this.apiAuthService.getAuthenticatedRequestHeader();
  }
}
