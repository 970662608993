import { Injectable } from '@angular/core';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  CancelRequest,
  CloneRequest,
  CloneResponse,
  CreateRequest,
  CreateResponse,
  GetJobResultsRequest,
  GetJobResultsResponse,
  GetJobsForCustomerRequest,
  GetJobsForCustomerResponse,
  GetRequest,
  GetResponse,
  UpdateRequest,
  UpdateResponse,
  UpdateStateRequest,
  UpdateStateResponse,
} from './generated/src/main/proto/api/job-log-service.pb';
import { JobLogServiceClient } from './generated/src/main/proto/api/job-log-service.pbsc';
import { GetPaginatedRequest } from './generated/src/main/proto/api/pagination.pb';
import {
  JobLog,
  JobState,
} from './generated/src/main/proto/storage/job-log.pb';

/**
 * Service to manage job logs, including retrieving, creating, updating, and cloning job logs.
 */
@Injectable({
  providedIn: 'root',
})
export class JobLogService {
  constructor(
    private apiAuthService: ApiAuthService,
    private jobLogService: JobLogServiceClient
  ) {}

  /**
   * Retrieves job logs for a customer with optional filters and pagination.
   *
   * @param numRecords - The number of records to retrieve.
   * @param binaryType - The binary type to filter by (optional).
   * @param jobState - The job state to filter by (optional).
   * @param continuationToken - The continuation token for pagination (optional).
   * @returns A promise that resolves to a GetJobsForCustomerResponse containing the job logs.
   */
  public async getJobLog(
    numRecords: number,
    binaryType: BinaryType | null,
    jobState: JobState | null,
    continuationToken: string | null
  ): Promise<GetJobsForCustomerResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getRequest = new GetJobsForCustomerRequest();
    const getPaginatedRequest: GetPaginatedRequest = new GetPaginatedRequest();

    if (binaryType) {
      getRequest.binaryType = binaryType;
    }
    if (jobState) {
      getRequest.jobState = jobState;
    }

    getPaginatedRequest.numRecords = numRecords;

    if (continuationToken) {
      getPaginatedRequest.continuationToken = continuationToken;
    }

    getRequest.paginated = getPaginatedRequest;

    return firstValueFrom(
      this.jobLogService.getJobsForCustomer(getRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves a job log based on the given request.
   *
   * @param getRequest - The request parameters for retrieving the job log.
   * @returns A promise that resolves to a GetResponse containing the job log details.
   */
  public async get(getRequest: GetRequest): Promise<GetResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(this.jobLogService.get(getRequest, grpcMetaData));
  }

  /**
   * Clones a job log by its ID.
   *
   * @param id - The ID of the job log to clone.
   * @returns A promise that resolves to a CloneResponse containing the result of the cloning operation.
   */
  public async clone(id: string): Promise<CloneResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.jobLogService.clone(
        new CloneRequest({
          jobLogId: id,
        }),
        grpcMetaData
      )
    );
  }

  /**
   * Creates a new job log.
   *
   * @param jobLog - The job log data to create.
   * @returns A promise that resolves to a CreateResponse containing the result of the creation operation.
   */
  public async create(jobLog: JobLog): Promise<CreateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.jobLogService.create(
        new CreateRequest({
          jobLog: jobLog,
        }),
        grpcMetaData
      )
    );
  }

  /**
   * Get results for a job log by its ID.
   *
   * @param getJobResultsRequest - The request parameters to get the results of a job.
   * @returns A promise that resolves to aGetJobResultsResponse containing the results for a job.
   */
  public async getJobResults(
    getJobResultsRequest: GetJobResultsRequest
  ): Promise<GetJobResultsResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    return firstValueFrom(
      this.jobLogService.getJobResults(getJobResultsRequest, grpcMetaData)
    );
  }

  /**
   * Updates the state of a job log.
   *
   * @param updateStateRequest - The request parameters for updating the job log state.
   * @returns A promise that resolves to an UpdateStateResponse containing the result of the update operation.
   */
  public async updateState(
    updateStateRequest: UpdateStateRequest
  ): Promise<UpdateStateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.jobLogService.updateState(updateStateRequest, grpcMetaData)
    );
  }

  /**
   * Updates an existing job log.
   *
   * @param jobLog - The job log data to update.
   * @param etag - The etag of the job log to update.
   * @returns A promise that resolves to an UpdateResponse containing the result of the update operation.
   */
  public async update(jobLog: JobLog, etag: string): Promise<UpdateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.jobLogService.update(
        new UpdateRequest({
          etag: etag,
          jobLog: jobLog,
        }),
        grpcMetaData
      )
    );
  }

  /**
   * cancel an existing job.
   *
   * @param jobId - The job Id to be cancelled.
   * @returns A promise that resolves to an CancelResponse containing the result of the update operation.
   */
  public async cancelJob(jobId: string): Promise<void> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const cancelRequest = new CancelRequest({
      jobLogId: jobId,
    });

    await firstValueFrom(
      this.jobLogService.cancel(cancelRequest, grpcMetaData)
    );
  }
}
