import { Component, OnInit, Optional } from '@angular/core';
import {
  Auth,
  getIdTokenResult,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorInfo,
  User,
  UserInfo,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  EventBusService,
  EventData,
  Events,
} from 'app/services/event-bus.service';
import { LoggerService } from 'app/services/logger.service';
import { CustomerAccount } from 'types/customerAccount';

import { MultifactorSetupModalComponent } from '../../modals/multifactor-setup-modal.component/multifactor-setup-modal.component';
import { UserProfileEditModalComponent } from '../../modals/user-profile-edit-modal/user-profile-edit-modal.component';
import { CustomerService } from '../../services/customer.service';
import { FormatService } from '../../services/format.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: false,
})
export class AccountComponent implements OnInit {
  private mfaKeyName = 'mfaphone';
  private rolesLookup = {
    ANONYMUSER: 'ANONYM USER',
    ANONYMADMIN: 'ANONYM ADMIN',
    ANONYMJOBADMIN: 'ANONYM JOB ADMIN',
    ANONYMPARTNERMANAGER: 'ANONYM PARTNER MANAGER',
    PORTALUSER: 'PORTAL USER',
    APPROVER: 'APPROVER',
    AUDITOR: 'AUDITOR',
    PARTNERADMIN: 'ADMIN',
    PARTNERKEYMANAGER: 'KEY MANAGER',
    PARTNERKEYVIEWER: 'KEY VIEWER',
  };
  public showSamlLinkOptions = false;
  public user: User | undefined;
  public firstName!: string | null;
  public lastName!: string | null;
  public roles!: string | null;
  public email!: string | null;
  public userProfileIcon!: string | null;
  public multiFactorAuthenticationStatus!: string | null;
  public phoneNumber!: string | null;
  public providerData: UserInfo[] | undefined;
  public uid!: string | null;

  customerData: CustomerAccount | undefined;

  public time: string = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
  }).format(Date.now());

  constructor(
    private router: Router,
    @Optional() private auth: Auth,
    private analytics: AngularFireAnalytics,
    public customerService: CustomerService,
    private dialog: MatDialog,
    private events: EventBusService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    public userService: UserService,
    private FormatService: FormatService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user === null) {
        this.router.navigate(['sign-in-options'], {
          queryParams: { redirect: this.router.url[0] },
        });
      } else {
        this.user = user;
        const { firstName, lastName } = this.userService.getFullUserName(
          user.displayName
        );

        this.lastName = lastName;
        this.firstName = firstName;
        this.userProfileIcon = user.photoURL;
        this.phoneNumber = user.phoneNumber;
        this.email = user.email;
        this.uid = user.uid;
        this.providerData = user.providerData;

        this.setupTwoFactorAuth();

        getIdTokenResult(user).then((result) => {
          const {
            claims: { Roles },
          } = result;
          this.roles = this.formatRoles((Roles as string).split(',')).join(
            ', '
          );
        });
      }
    });
  }

  ngOnInit(): void {
    this.customerService
      .getCustomer()
      .then(({ customer }) => {
        this.customerData = {
          avatarUrl: 'assets/default-profile.png', // TODO
          companyName: customer?.companyName ?? '',
          accountCreatedAt: this.FormatService.formatProtoDateTime(
            customer?.creationTime
          ),
          lastModifiedTimestamp: this.FormatService.formatProtoDateTime(
            customer?.lastUpdate
          ),
          numberOfUsers: 0,
          passwordSignInAllowed: customer?.passwordSignInAllowed,
          samlSignInAllowed: customer?.samlProviderConfig?.enabled,
        };
      })
      .catch((error) => this.logger.error('Error loading company data', error));

    this.userService.getCustomerUsers().then((response) => {
      if (response.users) {
        if (this.customerData) {
          this.customerData.numberOfUsers = response.users.length;
        }
      }
    });
  }

  setupMultiFactorAuthentication() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog
      .open(MultifactorSetupModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.analytics.logEvent('updated-2fa-settings');
        this.auth.currentUser?.reload();
        this.setupTwoFactorAuth();
      });
  }

  setupTwoFactorAuth() {
    multiFactor(this.user!).enrolledFactors.forEach((factor) => {
      if (
        factor.factorId === PhoneAuthProvider.PROVIDER_ID &&
        factor.displayName === this.mfaKeyName
      ) {
        if (this.multiFactorAuthenticationStatus) {
          this.multiFactorAuthenticationStatus +=
            (factor as PhoneMultiFactorInfo).phoneNumber + ' ';
        } else {
          this.multiFactorAuthenticationStatus =
            (factor as PhoneMultiFactorInfo).phoneNumber + ' ';
        }
      }
    });

    if (!this.multiFactorAuthenticationStatus) {
      this.multiFactorAuthenticationStatus = '';
    }
  }

  public updatePassword() {
    this.router.navigate(['set-password']);
  }

  public updateProfileInfo() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      userProfileIcon: this.userProfileIcon ?? './assets/default-profile.png',
      uid: this.uid,
      roles: this.roles,
    };

    this.dialog
      .open(UserProfileEditModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.analytics.logEvent('update-profile-info');
          this.userProfileIcon = result.photoUrl;
          this.firstName = result.firstName;
          this.lastName = result.lastName;
          this.events.emit(new EventData(Events.UserProfileChange, result));
        }
      });
  }

  public signOut() {
    this.userService.signOut();
  }

  public deleteUser() {
    if (confirm('Please confirm user deletion') == true) {
      this.auth.currentUser
        ?.delete()
        .then(() => {
          this.router.navigate([
            'sign-in-options',
            {
              queryParams: { redirect: this.router.url[0] },
            },
          ]);
          this.analytics.logEvent('delete-user');
        })
        .catch((error) => {
          this.logger.error('delete-user', error);
        });
    }
  }

  public linkWithSamlAccount() {
    this.userService
      .linkWithSamlAccount()
      .then(() => {
        this.messageBox.success('Account has been linked to provider.');
      })
      .catch((error) => {
        if (error.code === 'auth/credential-already-in-use') {
          this.analytics.logEvent('account-linked-to-saml-provider');
          this.messageBox.error(
            'This account has already been linked to provider.'
          );
        } else {
          this.analytics.logEvent('account-failed-to-link-saml-provider');
          this.logger.error('Unknown error linking provider.', error);
          this.messageBox.error('Unknown error linking provider.');
        }
      });
  }

  public unlinkSamlAccount() {
    this.userService
      .unlinkSamlAccount()
      .then(() => {
        this.messageBox.error(
          'Account has been unlinked from SAML provider, please sign-in again.',
          () => {
            this.userService.signOut();
          },
          5
        );
      })
      .catch((error) => {
        this.analytics.logEvent('account-failed-to-unlink-saml-provider');
        this.logger.error('Unknown error unlinking provider.', error);
        this.messageBox.error('Unknown error unlicking provider.');
      });
  }

  private formatRoles(roles: Array<string>): Array<string> {
    const prettyRoles: Array<string> = [];
    roles.forEach((role) => {
      prettyRoles.push(
        this.rolesLookup[role.toUpperCase() as keyof typeof this.rolesLookup]
      );
    });
    return prettyRoles.sort();
  }

  public isUserLinkedWithSaml() {
    return this.user?.providerData.find((value) => {
      return value.providerId.startsWith('saml.');
    });
  }
}
