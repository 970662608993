<app-container>
  <app-page-header title="Data Manager" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <app-customer-dropdown
    [showArchiveToggle]="true"
    [showSearchBox]="true"
    (selectCustomerEvent)="onCustomerSelect($event)"
  ></app-customer-dropdown>

  <section class="data-table-container" tabindex="0">
    <div class="data-table-title-container">
      <h2 class="data-users-title">Customer Datasets</h2>
      <button
        data-testid="createCustomerDataSet"
        class="add-user-button"
        (click)="createCustomerDataSet()"
      >
        New dataset
      </button>
    </div>

    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>

    <table
      mat-table
      data-testid="datasetTable"
      [dataSource]="dataSource"
      class="table-data"
      matSort
    >
      <ng-container matColumnDef="ID">
        <th class="row-header" mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <a class="dataset-id" (click)="editCustomerDataSet(dataset)">{{
              dataset.id
            }}</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <span>{{ dataset.name }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="customerId">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by customer"
        >
          Customer ID
        </th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <span>{{ dataset.customerId }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by location"
        >
          Location
        </th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <span>{{ getLocationName(dataset.location) }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by creation time"
        >
          Creation time
        </th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <span>{{
              dataset.creationTimestamp?.seconds * 1000 | date: 'medium'
            }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdate">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by last update"
        >
          Last Updated
        </th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <span>{{
              dataset.lastUpdate.seconds * 1000 | date: 'medium'
            }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by finalized"
        >
          State
        </th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <span>{{ dataset.state === 1 ? 'Draft' : 'Finalized' }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let dataset">
          <div class="user-name-col">
            <button
              mat-icon-button
              [matMenuTriggerFor]="actionsMenu"
              data-testid="viewActionMenu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <button
                mat-menu-item
                *ngIf="
                  dataset.encryptedData && dataset.encryptedData?.keyId !== ''
                "
                (click)="
                  downloadPublicKey(
                    dataset.encryptedData?.keyId,
                    dataset.encryptedData?.keyVersion
                  )
                "
              >
                <mat-icon>attachment</mat-icon>
                <span>Download PEM file</span>
              </button>
              <button
                mat-menu-item
                (click)="startExternalTransfer(dataset.id)"
                *ngIf="dataset.externalBlobStorage"
              >
                <mat-icon>sync_alt</mat-icon>
                <span>Start External Transfer</span>
              </button>
              <button
                mat-menu-item
                (click)="finalize(dataset.id, dataset.etag)"
                *ngIf="dataset.state === 1"
              >
                <mat-icon>assignment_turned_in</mat-icon>
                <span>Finalize</span>
              </button>
              <button
                mat-menu-item
                (click)="archive(dataset.id, dataset.etag)"
                data-testid="DataSet{}"
              >
                <mat-icon>delete</mat-icon>
                <span>Archive</span>
              </button>
              <button mat-menu-item (click)="createTrigger(dataset.id)">
                <mat-icon>fast_forward</mat-icon>
                <span>Create Trigger</span>
              </button>
              <button mat-menu-item (click)="deleteTrigger(dataset.id)">
                <mat-icon>fast_rewind</mat-icon>
                <span>Delete Trigger</span>
              </button>
              <button mat-menu-item (click)="openStorageViewer(dataset.id)">
                <mat-icon>visibility</mat-icon>
                <span>View Metadata</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
  </section>
  <div class="footer">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="dataSource.data.length ? 24 : 42"></mat-spinner>
    </div>

    <mat-paginator
      *ngIf="(!isLoading || dataSource.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isLoading"
    ></mat-paginator>
  </div>
</app-container>
