<app-container>
  <app-page-header
    title="{{ title }}"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <form
    ngNativeValidate
    (ngSubmit)="process()"
    [formGroup]="projectForm"
    id="customerForm"
  >
    <ng-container>
      <app-binary-type-dropdown
        [displayBinaryDescription]="true"
        (selectBinaryTypeEvent)="onBinaryTypeSelect($event)"
        [selectedBinaryType]="selectedBinaryType"
      ></app-binary-type-dropdown>
    </ng-container>

    <ng-container>
      <app-location-dropdown
        [allowedLocations]="allowedCustomerLocations"
        [selectedLocation]="selectedLocation"
        (selectLocationEvent)="onLocationSelect($event)"
      ></app-location-dropdown>
    </ng-container>

    <ng-container *ngIf="selectedBinaryType">
      <p class="field-title">Publisher datasets</p>
      <app-dataset-picker
        name="Publisher"
        [inputCustomerId]="selectedPublisherId"
        [customerDataSetReference]="selectedPublisherDataSetReference!"
        [location]="selectedLocation!"
        (customerDataSetInfo)="onPublisherSelectDatasetsInfo($event)"
        (customerInfo)="onPublisherSelect($event)"
        [showPartitions]="false"
        [showDatasetVersions]="false"
      >
      </app-dataset-picker>
    </ng-container>

    <ng-container *ngIf="displayPublisherUserDataset()">
      <p class="field-title">Publisher user datasets</p>
      <app-dataset-picker
        name="Publisher user"
        [inputCustomerId]="selectedPublisherId"
        [customerDataSetReference]="selectedPublisherDataSetReference!"
        [location]="selectedLocation!"
        [showPartitions]="false"
        [showDatasetVersions]="false"
        (customerDataSetInfo)="onPublisherSelectDatasetsInfo($event)"
      >
      </app-dataset-picker>
    </ng-container>

    <ng-container *ngIf="selectedBinaryType">
      <p class="field-title">Advertiser datasets</p>
      <app-dataset-picker
        name="Advertiser"
        [inputCustomerId]="selectedAdvertiserId"
        [customerDataSetReference]="selectedAdvertiserDataSetReference!"
        [location]="selectedLocation!"
        [showPartitions]="false"
        [showDatasetVersions]="false"
        (customerDataSetInfo)="onAdvertiserSelectDatasetsInfo($event)"
        (customerInfo)="onAdvertiserSelect($event)"
      >
      </app-dataset-picker>
    </ng-container>

    <ng-container>
      <div class="event-metrics-title">
        <div class="event-metric-title-left">
          <label class="field-title">Advertiser event metrics</label>
        </div>
        <button
          type="button"
          matTooltip="Add event"
          class="new-project-button"
          (click)="addEventMetric()"
        >
          <mat-icon>add_box</mat-icon>
        </button>
      </div>

      <div class="metrics-table">
        <table
          mat-table
          [dataSource]="metricsDataSource"
          formArrayName="metrics"
          class="mat-elevation-z1"
        >
          <ng-container matColumnDef="advertiserEventType">
            <th mat-header-cell *matHeaderCellDef>Event type</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field>
                <mat-select
                  class="select"
                  data-testid="eventMetricSelect"
                  placeholder="--Select--"
                  [value]="index + 1"
                  formControlName="advertiserEventType"
                >
                  <mat-option
                    *ngFor="let eventType of eventTypes"
                    [value]="eventType"
                  >
                    {{ getAdvertiserEventName(eventType) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amounts</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-checkbox formControlName="amounts"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="numUnits">
            <th mat-header-cell *matHeaderCellDef>Num units</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-checkbox formControlName="numUnits"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>Notes</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <textarea rows="3" cols="30" formControlName="notes"></textarea>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let index = index">
              <mat-icon class="delete-icon" (click)="removeEventMetric(index)"
                >delete</mat-icon
              >
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="metricsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; let i = index; columns: metricsColumns"
          ></tr>
        </table>
      </div>
    </ng-container>

    <ng-container>
      <mat-form-field class="input-half-width">
        <mat-label class="title"
          >{{ dateLabel }} start date (inclusive)</mat-label
        >
        <input type="date" matInput formControlName="campaignStartDate" />
      </mat-form-field>
    </ng-container>

    <ng-container>
      <mat-form-field class="input-half-width">
        <mat-label class="title"
          >{{ dateLabel }} end date (exclusive)</mat-label
        >
        <input type="date" matInput formControlName="campaignEndDate" />
      </mat-form-field>
    </ng-container>

    <ng-container>
      <mat-form-field class="input-half-width">
        <mat-label class="title">Lookback window click (days)</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          formControlName="lookbackWindowClickDays"
          required
          size="40"
      /></mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayLookbackWindowViewDays()">
      <mat-form-field class="input-half-width">
        <mat-label class="title">Lookback window view (days)</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          formControlName="lookbackWindowViewDays"
          required
          size="40"
      /></mat-form-field>
    </ng-container>

    <ng-container>
      <mat-form-field class="input-half-width">
        <mat-label class="title">Post period days</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          formControlName="postPeriodDays"
          required
          size="40"
        />
      </mat-form-field>
    </ng-container>

    <ng-container>
      <app-timezone-offset-selector
        (selectTimezoneOffsetEvent)="onTimezoneSelect($event)"
        [selectedTimezoneOffsetMinutes]="selectedTimezoneOffsetMinutes"
      ></app-timezone-offset-selector>
    </ng-container>

    <ng-container *ngIf="displayStudyId()">
      <mat-form-field class="input-half-width">
        <mat-label class="title">Study Id</mat-label>
        <input
          matInput
          type="text"
          placeholder="Study Id"
          formControlName="studyId"
          required
          size="40"
        />
      </mat-form-field>
    </ng-container>

    <app-match-keys-selector
      [selectedMatchKeys]="selectedMatchKeys"
      (selectMatchKeysEvent)="onMatchKeysEvent($event)"
    ></app-match-keys-selector>

    <ng-container>
      <mat-form-field class="input-full-width">
        <mat-label class="title">Notes</mat-label>
        <textarea
          matInput
          placeholder="Notes"
          data-testid="notes"
          formControlName="notes"
          rows="6"
          cols="60"
        ></textarea>
      </mat-form-field>
    </ng-container>

    <ng-container>
      <div class="field-row">
        <mat-form-field class="input-half-width">
          <mat-label class="title">State</mat-label>
          <mat-select
            formControlName="state"
            name="state"
            data-testid="state"
            (selectionChange)="onStateSelect($event.value)"
            [(value)]="selectedState"
            required
          >
            <mat-option *ngFor="let state of listStates" [value]="state.value">
              {{ state.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="input-half-width">
          <mat-checkbox
            matInput
            formControlName="archived"
            data-testid="archived"
          >
            Archived
          </mat-checkbox>
        </div>
      </div>
    </ng-container>

    <app-message-box></app-message-box>
    <ng-container>
      <button
        class="process-button"
        mat-flat-button
        (click)="process()"
        color="primary"
        form="projectForm"
        type="submit"
        data-testid="saveBtn"
      >
        {{ isLoading ? null : 'Save' }}
        <span *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="24"></mat-spinner>
        </span>
      </button>
    </ng-container>
  </form>
</app-container>
