import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CustomerAccount } from 'types/customerAccount';

@Component({
  selector: 'app-company-account-card',
  templateUrl: './company-account-card.component.html',
  styleUrls: ['./company-account-card.component.scss'],
  imports: [CommonModule, MatCardModule],
  standalone: true,
})
export class CompanyAccountCardComponent {
  @Input() customerData: CustomerAccount | undefined | null;
  @Input() bgColor?: string = '#f1f7ff';
  @Input() title?: string = 'Customer Account';
}
