import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GrpcStatusEvent } from '@ngx-grpc/common';
import { LoggerService } from 'app/services/logger.service';

import { LandingPageType } from '../../../types/landingPage';
import { PendingOnboardingModalComponent } from '../../modals/pending-onboarding-modal/pending-onboarding-modal.component';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: false,
})
export class LandingPageComponent implements OnInit {
  // TODO: Better if we can make this private
  public content: string | undefined;
  public welcomeTitle: string | undefined;
  public welcomeText: string | undefined;
  public welcomeLink: string | undefined;
  public systemOverviewTitle: string | undefined;
  public systemOverviewText: string | undefined;
  public systemOverviewLink: string | undefined;
  public knowledgeBaseTitle: string | undefined;
  public knowledgeBaseText: string | undefined;
  public knowledgeBaseLink: string | undefined;
  public auditResourcesTitle: string | undefined;
  public auditResourcesText: string | undefined;
  public auditResourcesLink: string | undefined;
  public jobLogTitle: string | undefined;
  public jobLogText: string | undefined;
  public jobLogLink: string | undefined;

  @ViewChild('welcomeImage') welcomeImage!: ElementRef;
  @ViewChild('jobLogImage') jobLogImage!: ElementRef;
  @ViewChild('knowledgeBaseImage') knowledgeBaseImage!: ElementRef;
  @ViewChild('systemOverviewImage') systemOverviewImage!: ElementRef;
  @ViewChild('auditResourcesImage') auditResourcesImage!: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    @Inject(LOCALE_ID) public locale: string,
    private logger: LoggerService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ fileName }) => {
      this.logger.info(`Getting landing page content from ${fileName}.`);
      this.getContent(fileName);
    });
  }

  getContent(fileName: string) {
    this.contentService
      .getContent(fileName)
      .then((contentResponse) => {
        const content = JSON.parse(contentResponse.contents) as LandingPageType;

        this.welcomeTitle = content.welcome.title;
        this.welcomeText = content.welcome.text;
        this.welcomeLink = content.welcome.link;
        this.welcomeImage.nativeElement.src = content.welcome.image;

        this.jobLogText = content.job_log.text;
        this.jobLogTitle = content.job_log.title;
        this.jobLogLink = content.job_log.link;
        this.jobLogImage.nativeElement.src = content.job_log.image;

        this.knowledgeBaseText = content.knowledge_base.text;
        this.knowledgeBaseTitle = content.knowledge_base.title;
        this.knowledgeBaseLink = content.knowledge_base.link;
        this.knowledgeBaseImage.nativeElement.src =
          content.knowledge_base.image;

        this.auditResourcesText = content.audit_resources.text;
        this.auditResourcesTitle = content.audit_resources.title;
        this.auditResourcesLink = content.audit_resources.link;
        this.auditResourcesImage.nativeElement.src =
          content.audit_resources.image;

        this.systemOverviewText = content.system_overview.text;
        this.systemOverviewTitle = content.system_overview.title;
        this.systemOverviewLink = content.system_overview.link;
        this.systemOverviewImage.nativeElement.src =
          content.system_overview.image;
      })
      .catch((error: GrpcStatusEvent) => {
        this.logger.error('Content Error', error);
      });

    this.route.queryParams.subscribe((params) => {
      const { openPendingOnboard = false } = params;

      if (openPendingOnboard) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'bg-color';

        this.dialog.open(PendingOnboardingModalComponent, dialogConfig);
      }
    });
  }
}
