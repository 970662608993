<section class="search-box">
  <div class="field-dropdown-label" *ngIf="displayTitle">
    <span class="field-title">{{ title }}</span>
  </div>
  <div class="mat-mdc-card">
    <form [formGroup]="form">
      <div>
        <mat-form-field class="search">
          <mat-label>Search</mat-label>
          <input matInput type="text" size="40" formControlName="text" />
        </mat-form-field>
        <button class="search-button" (click)="onSearch()">Search</button>
        <button class="reset-button" (click)="onReset()">Reset</button>
      </div>
      <div>
        <mat-form-field class="search">
          <mat-label>Start Date</mat-label>
          <input matInput type="date" size="50" formControlName="startDate" />
        </mat-form-field>
        <mat-form-field class="search">
          <mat-label>End Date</mat-label>
          <input matInput type="date" size="50" formControlName="endDate" />
        </mat-form-field>
      </div>
    </form>
  </div>
</section>
