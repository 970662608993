import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddJobScheduleModalComponent } from 'app/modals/add-job-schedule-modal/add-job-schedule-modal.component';
import { FormatService } from 'app/services/format.service';
import {
  ApiJobSchedule,
  ListRequest,
  ListRequestFilter,
} from 'app/services/generated/src/main/proto/api/job-schedule-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import {
  JobSchedule,
  TimeOfDay,
} from 'app/services/generated/src/main/proto/storage/job-schedule.pb';
import { LoggerService } from 'app/services/logger.service';

import { JobScheduleService } from '../../services/job-schedule.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

const RECORDS_PER_PAGE = 15;

interface Page {
  continuationToken: string | null;
}

@Component({
  selector: 'app-job-schedule',
  providers: [DatePipe],
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.scss'],
  standalone: false,
})
export class JobScheduleComponent implements AfterViewInit, OnInit {
  dataSource = new MatTableDataSource<JobSchedule>();
  filterStartTime = '';
  filterEndTime = '';
  public columnsToDisplay: string[] = [
    'ID',
    'Name',
    'jobLogId',
    'startTime',
    'lastUpdate',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  lastUpdate = this.formatService.getLastUpdate();

  constructor(
    public jobScheduleService: JobScheduleService,
    private dialog: MatDialog,
    private formatService: FormatService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadJobSchedule();
  }

  applyFilters() {
    let filter: ListRequestFilter | undefined;

    if (this.filterStartTime && this.filterEndTime) {
      const [startHour, startMinute] = this.filterStartTime
        .split(':')
        .map(Number);
      const [endHour, endMinute] = this.filterEndTime.split(':').map(Number);

      filter = new ListRequestFilter({
        startTime: new TimeOfDay({ startHour, startMinute }),
        endTime: new TimeOfDay({ startHour: endHour, startMinute: endMinute }),
      });
    }

    this.loadJobSchedule(0, null, filter);
  }

  createJobSchedule(): void {
    const dialogRef = this.dialog.open(AddJobScheduleModalComponent, {
      height: '600px',
      width: '800px',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadJobSchedule(this.currentPageIndex);
    });
  }

  archive(id: string): void {
    if (confirm(`Archive job schedule with ${id}`) == true) {
      this.isLoading = true;
      this.jobScheduleService
        .archiveJobSchedule(id)
        .catch((error) => {
          this.snackBar.open(error.statusMessage, 'Archive', {
            verticalPosition: 'top',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.ngOnInit();
        });
    }
  }

  async onPageChange(event: PageEvent) {
    if (event.pageIndex == 0) {
      this.loadJobSchedule(0);
      this.currentPageIndex = 0;
    } else {
      const page = this.pages[event.pageIndex];
      if (page.continuationToken) {
        this.loadJobSchedule(event.pageIndex, page.continuationToken);
        this.currentPageIndex = event.pageIndex;
      }
    }
  }

  loadJobSchedule(
    pageIndex = 0,
    token: string | null = null,
    filter?: ListRequestFilter
  ) {
    this.isLoading = true;
    const listRequest = new ListRequest({
      filter: filter,
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && listRequest.paginated) {
      listRequest.paginated.continuationToken = token;
    }

    this.jobScheduleService
      .listAllJobSchedules(listRequest)
      .then((data) => {
        this.dataSource.data =
          data.jobSchedules
            ?.map(
              (apiJobSchedule: ApiJobSchedule) => apiJobSchedule.jobSchedule
            )
            .filter(
              (jobSchedule): jobSchedule is JobSchedule =>
                jobSchedule !== undefined
            ) ?? [];
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (
          item: JobSchedule,
          property: string
        ) => {
          let value: string | number = '';
          switch (property) {
            case 'ID':
              value = item.id;
              break;
            case 'Name':
              value = item.name;
              break;
            case 'jobLogId':
              value = item.jobLogId;
              break;
            case 'startTime':
              value = item.startTime
                ? `${item.startTime.startHour}:${item.startTime.startMinute}`
                : '';
              break;
            case 'lastUpdate':
              value = item.lastUpdate?.seconds ?? '';
              break;
            default:
              value = item.lastUpdate?.seconds ?? '';
              break;
          }
          return value;
        };

        if (!token) {
          this.totalRecords =
            data.paginatedResponse?.count ?? this.dataSource.data.length;
        }

        if (data.paginatedResponse?.continuationToken) {
          this.pages[pageIndex + 1] = {
            continuationToken: data.paginatedResponse.continuationToken,
          };
        }

        this.isLoading = false;
      })
      .catch((error) => {
        this.logger.error('Error loading job schedules', error);
        this.messageBox.error('Error loading job schedules');
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    this.sort.sort({ id: 'creationTimestamp', start: 'desc' } as MatSortable);
  }
}
