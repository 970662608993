import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerService } from 'app/services/customer.service';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { KeyVersions } from 'app/services/generated/src/main/proto/api/key-service.pb';
import {
  AwsS3Credential,
  CustomerStorageCredential,
} from 'app/services/generated/src/main/proto/storage/customer-storage-credential.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';

import { CustomerStorageCredentialService } from '../../services/customer-storage-credential.service';
import { EncryptionService } from '../../services/encryption.service';

export enum StorageType {
  AWS_S3 = 1,
}

@Component({
  selector: 'app-storage-credential-creator',
  templateUrl: './storage-credential-creator.component.html',
  styleUrls: ['./storage-credential-creator.component.scss'],
  standalone: false,
})
export class StorageCredentialCreatorComponent {
  storageTypeControl = this.formBuilder.control('', {});
  accessKeyIdControl = this.formBuilder.control('', {});
  secretAccessKeyControl = this.formBuilder.control('', {});
  StorageType = StorageType;

  public checkError(controlName: string, validation: string) {
    return this.formHelper.checkError(controlName, validation);
  }

  storageTypeToName = new Map<StorageType, string>([
    [StorageType.AWS_S3, 'AWS S3'],
  ]);
  // The storage credential form.
  storageCredentialForm = this.formBuilder.group({
    storage_type: this.storageTypeControl,
    access_key_id: this.accessKeyIdControl,
    secret_access_key: this.secretAccessKeyControl,
  });

  constructor(
    private _customerService: CustomerService,
    private _encryptionService: EncryptionService,
    private _keyService: KeyService,
    private _storageCredentialCreatorService: CustomerStorageCredentialService,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private logger: LoggerService,
    private _snackBar: MatSnackBar
  ) {
    this.formHelper.setForm(this.storageCredentialForm);
  }

  async getKeys(): Promise<KeyVersions[]> {
    const resp = await this._keyService.listKeys();
    if (resp.keyVersions) {
      return resp.keyVersions.sort((a, b) => {
        const keyNameA = a.config?.keyName || '';
        const keyNameB = b.config?.keyName || '';
        return keyNameA.localeCompare(keyNameB);
      });
    }
    return [];
  }

  async createStorageCredentialFromForm(): Promise<CustomerStorageCredential> {
    const form = this.storageCredentialForm.value;
    const customerStorageCredential = new CustomerStorageCredential();

    if (!form.storage_type || !form.access_key_id || !form.secret_access_key) {
      return customerStorageCredential;
    }

    try {
      const customerResponse = await this._customerService.getCustomer();
      const customerId = customerResponse.customer?.id;
      this.logger.info(`Customer Id: ${customerId}`);

      const publicKeys = await this.getKeys();
      if (publicKeys.length === 0) {
        this.logger.info('No keys available');
        return customerStorageCredential;
      }

      const firstKeyVersion = publicKeys[0];
      const keyName = firstKeyVersion.config?.id || '';

      if (!firstKeyVersion.versions || firstKeyVersion.versions.length === 0) {
        this.logger.info('No versions available for the first key');
        return customerStorageCredential;
      }

      const keyVersion = firstKeyVersion.versions[0].version || '';
      this.logger.info(`Key Name: ${keyName}, Key Version: ${keyVersion}`);

      const publicKeyPem = await this._keyService.getPublicKeyPem(
        keyName,
        keyVersion
      );
      const secretAccessKey = form.secret_access_key || '';

      const encryptedKey = await this._encryptionService.encrypt(
        secretAccessKey,
        publicKeyPem
      );

      const accessKeyId = form.access_key_id || '';

      const serializedEncryptedKey = encryptedKey.serializeBinary();
      const strEncryptedKey = this._encryptionService.bytesToBase64(
        serializedEncryptedKey
      );

      const awsS3Credential = new AwsS3Credential({
        accessKeyId: accessKeyId,
        secretAccessKey: strEncryptedKey,
      });

      customerStorageCredential.keyName = keyName;
      customerStorageCredential.keyVersion = keyVersion;
      customerStorageCredential.awsCredential = awsS3Credential;
      customerStorageCredential.customerId = customerId || '';

      return customerStorageCredential;
    } catch (error) {
      this.logger.error('Encryption failed:', error);
      return customerStorageCredential;
    }
  }

  isStorageType(storage_type: StorageType) {
    if (!this.storageTypeControl.value) {
      return false;
    }
    return storage_type == parseInt(this.storageTypeControl.value);
  }

  handleSubmit() {
    this.createStorageCredentialFromForm().then((storageCredential) => {
      if (storageCredential) {
        this._storageCredentialCreatorService
          .createStorageCredential(storageCredential)
          .then(() => {
            this._snackBar.open(
              'Successfully created the encrypted storage credential.',
              'Dismiss',
              { verticalPosition: 'top' }
            );
          })
          .catch((error) => {
            const errorMessage = error.statusMessage || 'An error occurred';
            this.logger.error(error.statusMessage);
            this._snackBar.open(errorMessage, 'Dismiss', {
              verticalPosition: 'top',
            });
          });
      } else {
        this.logger.error('Required data is missing');
      }
    });
  }
}
