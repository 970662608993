<app-container>
  <div class="header">
    <h1>{{ binaryTypeInfo?.name }} binary diff</h1>
    <h3>{{ getVersionText() }}</h3>
    <p>{{ binaryTypeInfo?.description }}</p>
    <div class="disclaimer">
      Only modified text files are displayed on this diff.
    </div>
  </div>

  <app-message-box></app-message-box>

  <span *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="48"></mat-spinner>
  </span>

  <mat-accordion>
    <mat-expansion-panel *ngIf="filesToSkip.length !== 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Files with no updates.</h3>
        </mat-panel-title>
        <mat-panel-description
          >The following files are found in the source code zip file but have
          not changed between versions.</mat-panel-description
        >
      </mat-expansion-panel-header>
      <div class="file-list">
        <ul *ngFor="let diff of filesToSkip">
          <li>{{ getFilename(diff) }}</li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="filesToDisclaim.length !== 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Modified files that have not been rendered.</h3>
        </mat-panel-title>
        <mat-panel-description
          >Large csv and parquest files that where updated but not
          diffed.</mat-panel-description
        >
      </mat-expansion-panel-header>
      <div class="file-list">
        <ul *ngFor="let diff of filesToDisclaim">
          <li>{{ getFilename(diff) }}</li>
        </ul>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="diff" *ngFor="let diff of filesToDisplay">
    <div class="card-header">
      <div class="version-header-left">
        {{ getBeforeVersionText() }}
      </div>
      <div class="version-header-right">
        {{ getAfterVersionText() }}
      </div>
      <div class="toolbar">
        <button
          class="toggle-btn"
          mat-icon-button
          color="white"
          (click)="switchRenderMode()"
          matTooltip="Toggle side-by-side vs unified view."
          #tooltipTarget="matTooltip"
          (click)="tooltipTarget.show()"
        >
          <mat-icon class="download-icon">developer_mode</mat-icon>
        </button>
        <button
          class="toggle-btn"
          mat-icon-button
          color="white"
          (click)="expandCode(diff.left?.filename)"
          matTooltip="Toogle full file view."
          #tooltipTarget="matTooltip"
          (click)="tooltipTarget.show()"
        >
          <mat-icon class="download-icon">unfold_more</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <ngx-side-by-side-diff
        *ngIf="isSideBySide"
        [title]="diff.left?.filename || diff.right?.filename"
        [before]="diff.left?.content"
        [after]="diff.right?.content"
        [lineContextSize]="
          lineContextSize[diff.left?.filename || diff.right?.filename || '']
        "
        class="ngx-diff-light-theme my-custom-ngx-diff-theme"
      />

      <ngx-unified-diff
        *ngIf="!isSideBySide"
        [title]="diff.left?.filename || diff.right?.filename"
        [before]="diff.left?.content"
        [after]="diff.right?.content"
        [lineContextSize]="
          lineContextSize[diff.left?.filename || diff.right?.filename || '']
        "
        class="ngx-diff-light-theme my-custom-ngx-diff-theme"
      />
    </div>
  </div>
</app-container>
