import { Component, OnInit } from '@angular/core';
import { LoggerService } from 'app/services/logger.service';
import { UserService } from 'app/services/user.service';

import { STEPS, TODOS } from './constants';
import { GroupedTodos, Todo } from './model';

const groupTodos = (items: Todo[]) =>
  items.reduce<GroupedTodos>(
    (grouped, todo) => ({
      ...grouped,
      [todo.parentId]: [...(grouped[todo.parentId] ?? []), todo],
    }),
    {}
  );

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss'],
  standalone: false,
})
export class TodoListComponent implements OnInit {
  constructor(
    public userService: UserService,
    public logger: LoggerService
  ) {}

  steps = STEPS;
  groups = groupTodos(TODOS);
  state: Record<string, boolean> = {};
  isLoading = false;

  onToggleTodo(id: string, state: boolean) {
    // We take an optimistic aproach here
    // we update the state immediately upon user interaction.
    // If an error happens, we revert the change.
    const backup = this.state[id];
    this.state[id] = state;
    this.userService.updateTodo(id, state)?.catch((error) => {
      this.state[id] = backup;
      this.logger.error(error);
    });
  }

  areSomeChildrenChecked(id: string) {
    return this.groups[id].some(({ id: childId }) => this.state[childId]);
  }

  areAllChildrenChecked(id: string) {
    return this.groups[id].every(({ id: childId }) => this.state[childId]);
  }

  onToggleParent(id: string, state: boolean) {
    this.groups[id].forEach(({ id }) => {
      this.onToggleTodo(id, state);
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService
      .getTodos()
      ?.then((docSnap) => {
        if (docSnap) {
          this.state = (docSnap.data() ?? {}) as typeof this.state;
        }
      })
      .catch((error) => {
        this.logger.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
