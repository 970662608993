<ng-container>
  <div class="field-dropdown-label">
    <span class="field-title">Timezone</span>
  </div>
  <div class="field-row">
    <mat-form-field class="field-name-container" appearance="outline">
      <mat-select
        placeholder="--Select--"
        name="timezone"
        data-testid="timezone"
        (selectionChange)="onTimezoneOffsetSelect($event.value)"
        required
        [(value)]="selectedTimezoneOffsetMinutes"
      >
        <mat-option
          *ngFor="let timeOffset of timeOffsets"
          [value]="timeOffset.offsetMinutes"
        >
          {{ timeOffset.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
