import { Component, OnInit } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { LoggerService } from 'app/services/logger.service';

import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-reset-password',
  templateUrl: './new-user-password.component.html',
  styleUrls: ['./new-user-password.component.scss', '../../shared/shared.scss'],
  standalone: false,
})
export class NewUserPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    public userService: UserService,
    private analytics: AngularFireAnalytics,
    private logger: LoggerService,
    private route: ActivatedRoute,
    private auth: Auth,
    private messageBox: MessageBoxProvider,
    private formHelper: FormHelpersService
  ) {
    this.newPasswordForm = new FormGroup({
      password: new FormControl<string>('', [Validators.required]),
      confirmation: new FormControl<string>('', [
        Validators.required,
        (control) => {
          return formHelper.checkConfirmationPassword(control);
        },
      ]),
    });
    this.userData = {
      email: '',
      firstName: '',
      lastName: '',
    };
    this.formHelper.setForm(this.newPasswordForm);
  }

  private code!: string;
  private tenantId!: string;

  public userData!: {
    firstName: string;
    lastName: string;
    email: string;
  };

  codeExpired = false;
  isLoading = false;
  newPasswordForm: FormGroup;

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.code = params['oobCode'];

      if (this.code) {
        this.tenantId = params['tenantId'];
        this.auth.tenantId = this.tenantId;

        verifyPasswordResetCode(this.auth, this.code)
          .then((email) => {
            if (email) {
              this.userData = {
                ...this.userService.getFullUserName(
                  this.auth.currentUser?.displayName ?? ''
                ),
                email,
              };
              this.codeExpired = false;
              this.analytics.logEvent('new-user-password');
            } else {
              this.codeExpired = true;
            }
          })
          .catch((error) => {
            this.codeExpired = true;

            this.messageBox.error(
              'The new user password link has expired or has already been used.',
              undefined,
              10000
            );

            this.logger.error('ERROR', error);
          });
      } else {
        this.codeExpired = true;
        this.router.navigate(['sign-in-options']);
      }
    });
  }

  confirmNewPassword() {
    this.isLoading = true;

    const { password, confirmation } = this.newPasswordForm?.value ?? {};

    if (password !== confirmation) {
      this.messageBox.error('Password and confirmation do not match.');
      this.isLoading = false;
      return;
    }

    if (this.codeExpired) {
      this.messageBox.error(
        'Your new user request is expired, please request it again.'
      );
      this.isLoading = false;
      return;
    }

    this.auth.tenantId = this.tenantId;

    confirmPasswordReset(this.auth, this.code, password)
      .then(() => {
        this.analytics.logEvent('new-user-password-success');

        this.messageBox.success('The password was successfully added.', () => {
          this.router.navigate(['landing-page'], {
            queryParams: { openPendingOnboard: true },
          });
        });
      })
      .catch((error) => {
        this.logger.error('Error creating password', error);
        this.messageBox.error(
          'Something went wrong. Please try again or contact the support.'
        );
      })
      .finally(() => (this.isLoading = false));
  }
}
