import { Component, OnDestroy, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { CsvFormatService } from 'app/services/csv-format.service';
import { CustomerService } from 'app/services/customer.service';
import { CustomerDataService } from 'app/services/customer-data.service';
import {
  EncryptedSymmetricKey,
  EncryptionService,
} from 'app/services/encryption.service';
import { FileUploadService } from 'app/services/file-upload.service';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { FormatService } from 'app/services/format.service';
import { StartExternalTransferRequest } from 'app/services/generated/src/main/proto/api/customer-data-set-service.pb';
import {
  KeyVersion,
  KeyVersions,
} from 'app/services/generated/src/main/proto/api/key-service.pb';
import { App } from 'app/services/generated/src/main/proto/storage/app.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  CustomerDataSet,
  EncryptedDataConfig,
  ExternalBlobStorage,
  KeyFileStructure,
} from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { EncryptedValueAndKey } from 'app/services/generated/src/main/proto/storage/encrypted.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';
import { ceil } from 'lodash';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-data-manager',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss'],
  standalone: false,
})
export class DataManagementComponent implements OnInit, OnDestroy {
  allKeys: KeyVersions[] = [];
  apps: App[] | undefined;
  binaryNameToType = new Map<string, BinaryType>();
  completeStep = false;
  currentSelectedKeyId: string | undefined;
  currentSelectedKeyVersion: string | undefined;
  currentFileUploadState: string | undefined;
  currentSelectedLocation: Location | undefined;
  customer: Customer | undefined;
  datasetFormGroup: FormGroup;
  datasetName: string | undefined = undefined;
  displayProgress = false;
  fileProgress = 0;
  isFileProgressTypeDeterminate = false;
  isFormatProgress = false;
  fileUploadFormGroup: FormGroup;
  fileUploadProgress$ = new Observable<number>();
  files: File[] = [];
  formattingUpdateSubscription: Subscription;
  formatProgress = 0;
  displayInvalidFileFormatMessage = false;
  isLoading = false;
  isProcessing = false;
  isProcessComplete = false;
  keys: KeyVersions[] = [];
  keyVersions: KeyVersion[] = [];
  uploadStage: string | undefined;
  uploadStep = 0;
  uploadTaskStep = false;
  tenantId: string | undefined;
  totalProgress = 0;
  totalUploadFileSize = 0;
  totalUploadTimeInSeconds = 0;
  processStep = false;
  publicKeys: KeyVersions[] | undefined;
  validFileFormats = ['.csv', '.json', '.parquet', '.pqt'];
  visibleKeys: string[] = [];

  constructor(
    private auth: Auth,
    private csvFormatService: CsvFormatService,
    private customerDataService: CustomerDataService,
    private customerService: CustomerService,
    private encryptionService: EncryptionService,
    private fileUploadService: FileUploadService,
    private formatService: FormatService,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private keyService: KeyService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider
  ) {
    this.datasetFormGroup = this.formBuilder.group({
      name: new FormControl('', {
        validators: [Validators.required],
      }),
      publicKey: new FormControl('', {}),
      keyVersion: new FormControl('', {}),
    });

    this.formHelper.setForm(this.datasetFormGroup);

    this.getKeys();
    this.getCustomerInfo();

    this.fileUploadFormGroup = this.formBuilder.group({
      fileUpload: new FormControl({
        validators: [Validators.required],
      }),
    });

    this.formattingUpdateSubscription = this.csvFormatService
      .getFormatStatusObserver()
      .subscribe((item: number) => (this.formatProgress = item));
  }

  ngOnInit(): void {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.tenantId = user.tenantId!;
      }
    });
  }

  ngOnDestroy() {
    this.formattingUpdateSubscription.unsubscribe();
  }

  async createDataset() {
    const { value } = this.datasetFormGroup;

    if (this.customer) {
      const encryptedDataConfig = new EncryptedDataConfig({
        keyId: this.currentSelectedKeyId,
        keyVersion: this.currentSelectedKeyVersion,
        dataPathPrefix: 'data/',
        encryptedSymmetricKeyPath: 'key_file',
        keyFileStructure: KeyFileStructure.KEY_FILE_STRUCTURE_SINGLE_KEY,
      });

      const customerDataSet = new CustomerDataSet();

      customerDataSet.customerId = this.customer?.id;
      if (this.currentSelectedLocation) {
        customerDataSet.location = this.currentSelectedLocation;
      }
      customerDataSet.name = value.name;
      this.datasetName = value.name;
      customerDataSet.state = CustomerDataSet.DatasetState.DATASET_STATE_DRAFT;
      customerDataSet.encryptedData = encryptedDataConfig;
      const createResponse =
        await this.customerDataService.create(customerDataSet);

      // Update the data set to have the correct blob storage paths.
      // These paths must match the SAS Token returned by the API.
      customerDataSet.id = createResponse.customerDataSetId;
      customerDataSet.etag = createResponse.etag;
      customerDataSet.externalBlobStorage = new ExternalBlobStorage({
        dataPathPrefix: `web/${createResponse.customerDataSetId}/data`,
        encryptedSymmetricKeyPath: `web/${createResponse.customerDataSetId}/key_file`,
      });
      await this.customerDataService.update(customerDataSet);
      return createResponse.customerDataSetId;
    } else {
      throw new Error('Unable to load customer data.');
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  formatTimestamp(timestamp: Timestamp | undefined) {
    return timestamp ? this.formatService.formatProtoDateTime(timestamp) : '-';
  }

  getCustomerInfo() {
    this.customerService.getCustomer().then((resp) => {
      this.customer = resp.customer;

      if (this.customer?.locations.length === 1) {
        this.currentSelectedLocation = this.customer.locations[0];
      }
    });
  }

  getKeyVersionExpirationLabel(keyExpiration: Timestamp | undefined) {
    return keyExpiration
      ? ` - Expires ${this.formatService.formatProtoDateTime(keyExpiration)}`
      : '';
  }

  getKeys() {
    this.keyService.listKeys().then((resp) => {
      if (resp.keyVersions) {
        this.publicKeys = resp.keyVersions.sort((a, b) => {
          if (a.config?.keyName && b.config?.keyName) {
            return a.config?.keyName!.localeCompare(b.config?.keyName);
          } else {
            return 0;
          }
        });
        if (this.publicKeys.length == 1 && this.publicKeys[0].config) {
          this.onKeySelect(this.publicKeys[0].config.id);
        } else {
          this.datasetFormGroup
            .get('publicKey')
            ?.setValue('default', { onlySelf: true });
        }
      }
    });
  }

  getKeyName() {
    if (this.publicKeys) {
      return this.publicKeys.find(
        (key) => key.config?.id === this.currentSelectedKeyId
      )?.config?.keyName;
    } else {
      return undefined;
    }
  }

  getValidFileFormats() {
    return this.validFileFormats.join(', ');
  }

  displayBinaryType() {
    if (this.customer?.allowedBinaryTypes) {
      return this.customer?.allowedBinaryTypes.length > 1;
    } else {
      return false;
    }
  }

  validateDatasetForm() {
    const { value } = this.datasetFormGroup;

    if (
      value.name &&
      this.currentSelectedKeyId &&
      this.currentSelectedKeyVersion &&
      this.currentSelectedLocation
    ) {
      return true;
    } else {
      return false;
    }
  }

  areFilesSelected() {
    return this.files.length > 0;
  }

  onKeySelect(keyId: string) {
    this.keyVersions = [];
    if (this.publicKeys) {
      this.publicKeys.forEach((key) => {
        if (key.config?.id == keyId) {
          this.currentSelectedKeyId = keyId;
          if (key.versions) {
            this.keyVersions = key.versions;
            if (this.keyVersions.length == 1) {
              this.currentSelectedKeyVersion = this.keyVersions[0].version;
            }
          }
        }
      });
    }
  }

  onLocationSelect(location: Location) {
    this.currentSelectedLocation = location;
  }

  onFileSelectionChange(event: any) {
    const files = event.target.files;
    this.displayInvalidFileFormatMessage = false;

    if (files.length) {
      // We don't want to use the default FileList as its hard to edit.
      this.files = [];
      for (let i = 0; i < files.length; i++) {
        if (this.isValidFileFormat(files[i].name)) {
          this.files.push(files[i]);
        } else {
          this.displayInvalidFileFormatMessage = true;
        }
      }
    }
  }

  async process() {
    this.displayProgress = true;
    this.isProcessing = true;
    this.totalProgress = 1;
    this.fileProgress = 1;
    this.isFileProgressTypeDeterminate = false;

    try {
      if (this.files.length && this.customer) {
        const publicKey = await this.getPublicKey();

        this.currentFileUploadState = 'Creating data set ...';
        const startingDataSetTimeStamp = Date.now();
        const customerDataSetId = await this.createDataset();
        const datasetCreationTime = Date.now() - startingDataSetTimeStamp;

        this.currentFileUploadState = 'Creating tokens ...';
        const fileExtension = this.files[0].name.split('.').slice(-1)[0];
        const sasTokens = await this.customerDataService.createSasTokens(
          customerDataSetId,
          fileExtension,
          this.files.length
        );

        this.currentFileUploadState = 'Creating symmetric key ...';
        const symmetricKey =
          await this.encryptionService.generateSymmetricKey(publicKey);

        this.currentFileUploadState = `Encrypting and uploading symmetric key...`;

        await firstValueFrom(
          this.fileUploadService.uploadToBlobStorage(
            new Uint8Array(symmetricKey.encryptedSymmetricKey),
            sasTokens.keySasToken?.sasUrl || ''
          )
        );

        for (const file of this.files) {
          this.isFileProgressTypeDeterminate = true;
          const fileUploadTime = await this.uploadFile(
            file,
            symmetricKey,
            sasTokens.dataSasTokens?.shift()?.sasUrl || ''
          );
          this.totalUploadFileSize += file.size;
          this.totalUploadTimeInSeconds += fileUploadTime;
        }
        this.totalUploadTimeInSeconds += datasetCreationTime;
        this.startExternalTransfer(customerDataSetId);
        this.completeUpload();
      }
    } catch (error) {
      if (typeof error === 'string') {
        this.logger.error(error);
      } else if (error instanceof Error) {
        this.logger.error(error.message);
      }
      this.messageBox.error(
        `Failed to process files due to ${error}`,
        undefined,
        50000
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async uploadFile(
    file: File,
    symmetricKey: EncryptedSymmetricKey,
    sasUrl: string
  ) {
    this.fileProgress = 1;
    const startingTimeStamp = Date.now();
    this.currentFileUploadState = `Formatting & encrypting file ${file.name} of size ${this.formatBytes(file.size)}...`;
    const encryptedDataAndKey = await this.formatAndEncrypt(file, symmetricKey);
    this.currentFileUploadState = `Uploading file ${
      file.name
    } of size ${this.formatService.formatBytes(file.size)}...`;

    // There might be a more efficient way of concatenating the IV + ciphertext + tag
    // but this works for now.

    const dataUploadProgress$ = this.fileUploadService.uploadToBlobStorage(
      this.mergeFileComponents(encryptedDataAndKey),
      sasUrl
    );

    return new Promise<number>((resolve, reject) => {
      dataUploadProgress$.subscribe({
        next: (value) => {
          this.increaseFileProgressBy(value, file.size);
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          this.increaseTotalProgress();
          resolve(Date.now() - startingTimeStamp);
        },
      });
    });
  }

  completeUpload() {
    this.isProcessComplete = true;
    this.messageBox.success(
      'Process complete, please click Next to see summary.',
      undefined,
      18000
    );
  }

  mergeFileComponents(encryptedDataAndKey: EncryptedValueAndKey): Uint8Array {
    // Calculating total array size
    const totalArraySize = new Uint8Array(
      encryptedDataAndKey.initVector.length +
        encryptedDataAndKey.value.length +
        encryptedDataAndKey.tag.length
    );

    // Creating new array of total array size
    const value = new Uint8Array(totalArraySize);

    let offset = 0;

    // Adding each uint8array in its proper offset
    value.set(encryptedDataAndKey.initVector, offset);
    offset += encryptedDataAndKey.initVector.byteLength;
    value.set(encryptedDataAndKey.value, offset);
    offset += encryptedDataAndKey.value.byteLength;
    value.set(encryptedDataAndKey.tag, offset);

    return value;
  }

  async formatAndEncrypt(file: File, symmetricKey: EncryptedSymmetricKey) {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    return new Promise<EncryptedValueAndKey>((resolve, reject) => {
      fileReader.onload = async () => {
        let fileBuffer = fileReader.result as ArrayBuffer;

        // Try to automatically format CSV files.
        if (
          file.name.endsWith('.csv') &&
          this.customer?.webUploadCsvFormattingEnabled
        ) {
          try {
            this.isFormatProgress = true;
            fileBuffer = new TextEncoder().encode(
              await this.csvFormatService.formatCsv(
                new TextDecoder().decode(fileBuffer)
              )
            );
          } catch (e) {
            this.logger.error('Error formatting CSV file.', e);
            reject(e);
          } finally {
            this.isFormatProgress = false;
          }
        }

        return resolve(
          this.encryptionService.encryptWithKey(fileBuffer, symmetricKey)
        );
      };
      fileReader.onerror = (e: any) => {
        this.logger.error('Error reading file from local path.');
        reject(e);
      };
    });
  }

  async getPublicKey() {
    if (!this.currentSelectedKeyId || !this.currentSelectedKeyVersion) {
      throw new Error('key and version not selected.');
    }
    return await this.keyService.getPublicKeyPem(
      this.currentSelectedKeyId,
      this.currentSelectedKeyVersion
    );
  }

  increaseFileProgressBy(value: number, fileSize: number) {
    this.fileProgress = ceil((value / fileSize) * 100);
    if (this.fileProgress > 100) {
      this.fileProgress = 100;
    }
  }

  increaseTotalProgress() {
    this.totalProgress += ceil((1 / this.files.length) * 100);
    if (this.totalProgress > 100) {
      this.totalProgress = 100;
    }
  }

  isValidFileFormat(fileName: string) {
    return this.validFileFormats.some((fileFormat) =>
      fileName.endsWith(fileFormat)
    );
  }

  formatBytes(bytes: number) {
    return this.formatService.formatBytes(bytes);
  }

  formatMicrosecondsToTime(microseconds: number) {
    return (microseconds / 1000).toFixed(2);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.displayInvalidFileFormatMessage = false;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.isValidFileFormat(file.name)) {
            this.files.push(file);
          } else {
            this.displayInvalidFileFormatMessage = true;
          }
        });
      }
    }
  }

  done() {
    window.location.reload();
  }

  startExternalTransfer(id: string): void {
    this.isLoading = true;
    const startExternalTransferRequest = new StartExternalTransferRequest({
      customerDataSetId: id,
    });
    this.customerDataService
      .startExternalTransfer(startExternalTransferRequest)
      .catch((error) => {
        this.logger.error('Failed to start external data transfer.', error);
      })
      .then(() => {
        this.logger.info('External data transfer run successfully.');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
