import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  KeyVersion,
  KeyVersions,
  ListKeysResponse,
} from 'app/services/generated/src/main/proto/api/key-service.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';

import { ContentService } from '../../services/content.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

interface KeyType {
  keyId: string;
  title: string;
  version: string | undefined;
}

@Component({
  selector: 'app-public-key',
  templateUrl: './public-key.component.html',
  styleUrls: ['./public-key.component.scss'],
  standalone: false,
})
export class PublicKeyComponent implements OnInit {
  defaultKeyName = 'default';
  publicKeys: KeyType[] = [];
  isLoading = false;
  markdown: string | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private keyService: KeyService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider
  ) {}

  ngOnInit(): void {
    this.getKeys();

    this.activatedRoute.data.subscribe(({ fileName }) => {
      this.logger.info(`Getting public key content from ${fileName}.`);
      this.getContent(fileName);
    });
  }

  getContent(fileName: string) {
    this.isLoading = true;
    this.contentService
      .getContent(fileName)
      .then((contentResponse) => {
        this.markdown = contentResponse.contents;
      })
      .catch((error) => {
        this.messageBox.error('Unable to load content for this page.');
        this.logger.error('Error loading content.', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getDefaultKey(
    keyVersions: KeyVersions[] | undefined
  ): KeyVersions[] | undefined {
    const defaultKey = keyVersions!.filter(
      (key) => key.config!.keyName === this.defaultKeyName
    );
    return defaultKey;
  }

  getNewestKey(
    keyVersions: KeyVersions[] | undefined
  ): KeyVersions[] | undefined {
    keyVersions!.sort((a, b) => {
      return a.config!.keyName.localeCompare(b.config!.keyName);
    });
    return keyVersions;
  }

  getLatestVersion(
    keyVersions: KeyVersion[] | undefined
  ): KeyVersion | undefined {
    keyVersions!.sort((a, b) => {
      return (
        parseInt(b.createdTime!.seconds) - parseInt(a.createdTime!.seconds)
      );
    });
    return keyVersions![0];
  }

  getKeys() {
    this.isLoading = true;
    this.keyService
      .listKeys()
      .then((keys: ListKeysResponse) => {
        this.publicKeys = [];
        // Look for the default keyset, if keyset is not found display all keys order alphabetically
        const orderedKeyVersions = this.getNewestKey(keys.keyVersions);
        const defaultKeyVersions = this.getDefaultKey(keys.keyVersions);
        const finalKeyVersions =
          defaultKeyVersions?.length == 0
            ? orderedKeyVersions
            : defaultKeyVersions;
        finalKeyVersions!.forEach((key) => {
          if (!key.config?.archived) {
            // Find the latest version of a key, display that
            const latestKey = this.getLatestVersion(key.versions);
            this.publicKeys.push({
              keyId: key.config!.id,
              title: key.config!.keyName,
              version: latestKey!.version,
            });
          }
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
