import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { AddCustomerModalComponent } from 'app/modals/add-customer-modal/add-customer-modal.component';
import { SamlProviderConfigrationComponent } from 'app/modals/add-customer-modal/saml-provider-configuration/saml-provider-configuration.component';
import { FormatService } from 'app/services/format.service';
import { JobLogService } from 'app/services/job-log.service';
import { AdminAuditApprovalComponent } from 'app/views/admin-audit-approval/admin-audit-approval.component';
import { ApproverDetailsDialogComponent } from 'app/views/admin-audit-approval/approver-details-dialog/approver-details-dialog.component';
import { AdminJobListComponent } from 'app/views/admin-job-list/admin-job-list.component';
import { AdminJobResultsComponent } from 'app/views/admin-job-results/admin-job-results.component';
import { CsvViewerComponent } from 'app/views/admin-job-results/csv-viewer/csv-viewer.component';
import { ResultsTableComponent } from 'app/views/admin-job-results/table/results-table.component';
import { AdminNotificationComponent } from 'app/views/admin-notification/admin-notification.component';
import { AdminProjectManagerComponent } from 'app/views/admin-project-manager/admin-project-manager.component';
import { AdminStorageCredentialComponent } from 'app/views/admin-storage-credential/admin-storage-credential.component';
import { AdminUsersManagerComponent } from 'app/views/admin-users-manager/admin-users-manager.component';
import { AttributionComponent } from 'app/views/binary-type/attribution/attribution.component';
import { DatasetPickerComponent } from 'app/views/binary-type/common/dataset-picker/dataset-picker.component';
import { EnclaveEncryptionComponent } from 'app/views/binary-type/enclave-encryption/enclave-encryption.component';
import { LiftComponent } from 'app/views/binary-type/lift/lift.component';
import { PostprocessingComponent } from 'app/views/binary-type/postprocessing/postprocessing.component';
import { RedditAttributionComponent } from 'app/views/binary-type/reddit-attribution/reddit-attribution.component';
import { RedditLiftComponent } from 'app/views/binary-type/reddit-lift/reddit-lift.component';
import { TiktokTargetingComponent } from 'app/views/binary-type/tiktok-targeting/tiktok-targeting.component';
import { ValidationComponent } from 'app/views/binary-type/validation/validation.component';
import { CustomerDataComponent } from 'app/views/customer-data/customer-data.component';
import { CustomerComponent } from 'app/views/customer-page/customer.component';
import { ExternalStorageMetadataViewerComponent } from 'app/views/external-storage-metadata-viewer/external-storage-metadata-viewer.component';
import { JobDebuggerComponent } from 'app/views/job-debugger/job-debugger.component';
import { JobLogCreatorComponent } from 'app/views/job-log-creator/job-log-creator.component';
import { JobScheduleComponent } from 'app/views/job-schedule/job-schedule.component';
import { ArchiveSelectComponent } from 'app/views/shared/components/archive-select/archive-select.component';
import { BinaryTypeDropdownComponent } from 'app/views/shared/components/binary-type-dropdown/binary-type-dropdown.component';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { CustomerDropdownComponent } from 'app/views/shared/components/customer-dropdown/customer-dropdown.component';
import { FileViewerComponent } from 'app/views/shared/components/file-viewer/file-viewer-component';
import { LocationDropdownComponent } from 'app/views/shared/components/location-dropdown/location-dropdown.component';
import { MessageBoxComponent } from 'app/views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';
import { SearchComponent } from 'app/views/shared/components/search-component/search.component';
import { StorageMetadataViewerComponent } from 'app/views/storage-metadata-viewer/storage-metadata-viewer.component';
import { HighlightModule } from 'ngx-highlightjs';

@NgModule({
  declarations: [
    AddCustomerModalComponent,
    AdminJobListComponent,
    AdminJobResultsComponent,
    AdminUsersManagerComponent,
    AdminAuditApprovalComponent,
    AdminNotificationComponent,
    AdminProjectManagerComponent,
    AdminStorageCredentialComponent,
    ApproverDetailsDialogComponent,
    ArchiveSelectComponent,
    AttributionComponent,
    CsvViewerComponent,
    ResultsTableComponent,
    CustomerComponent,
    CustomerDataComponent,
    CustomerDropdownComponent,
    DatasetPickerComponent,
    EnclaveEncryptionComponent,
    ExternalStorageMetadataViewerComponent,
    FileViewerComponent,
    JobDebuggerComponent,
    JobLogCreatorComponent,
    JobScheduleComponent,
    LiftComponent,
    LocationDropdownComponent,
    PostprocessingComponent,
    RedditAttributionComponent,
    RedditLiftComponent,
    SamlProviderConfigrationComponent,
    SearchComponent,
    StorageMetadataViewerComponent,
    TiktokTargetingComponent,
    ValidationComponent,
  ],
  exports: [
    AdminUsersManagerComponent,
    AdminJobListComponent,
    AdminJobResultsComponent,
    AdminAuditApprovalComponent,
    AdminNotificationComponent,
    AdminProjectManagerComponent,
    AdminStorageCredentialComponent,
    AttributionComponent,
    CustomerComponent,
    CustomerDataComponent,
    CustomerDropdownComponent,
    DatasetPickerComponent,
    EnclaveEncryptionComponent,
    JobDebuggerComponent,
    JobLogCreatorComponent,
    JobScheduleComponent,
    LiftComponent,
    LocationDropdownComponent,
    RedditAttributionComponent,
    RedditLiftComponent,
    SearchComponent,
    TiktokTargetingComponent,
    ValidationComponent,
  ],
  providers: [MessageBoxProvider, FormatService, JobLogService],
  imports: [
    BinaryTypeDropdownComponent,
    CommonModule,
    ClipboardModule,
    ContainerComponent,
    FormsModule,
    HighlightModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MessageBoxComponent,
    PageHeaderComponent,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class AdminModule {}
