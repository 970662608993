import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BinaryTypeService } from 'app/services/binary-type.service';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';

@Component({
  selector: 'app-binary-type-dropdown',
  templateUrl: './binary-type-dropdown.component.html',
  styleUrls: ['./binary-type-dropdown.component.scss'],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule],
  standalone: true,
})
export class BinaryTypeDropdownComponent implements OnInit, OnChanges {
  @Input() selectedBinaryType: BinaryType | undefined;
  @Input() displayBinaryDescription: boolean = false;
  @Input() displayTitle: boolean = true;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() title: string = 'Binary Type';
  @Input() placeholderText: string = '--Select--';
  @Output() selectBinaryTypeEvent = new EventEmitter<BinaryType>();
  binaryTypesInfo: BinaryTypeInfo[] = [];
  binaryTypeUnspecified = BinaryType.BINARY_TYPE_UNSPECIFIED;
  currentBinaryTypeLabel: string | undefined;

  constructor(private binaryTypeService: BinaryTypeService) {}

  async ngOnInit() {
    this.binaryTypesInfo = [];
    const binaryTypes = await this.binaryTypeService.getBinaryTypes();

    if (binaryTypes.binaryTypeInfos) {
      this.binaryTypesInfo = binaryTypes.binaryTypeInfos;
    }
    if (this.selectedBinaryType) {
      this.onBinaryTypeSelect(this.selectedBinaryType);
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  onBinaryTypeSelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.currentBinaryTypeLabel = this.binaryTypesInfo.find(
      (binaryTypeInfo) => binaryTypeInfo.binaryType === binaryType
    )?.description;
    this.selectBinaryTypeEvent.emit(this.selectedBinaryType);
  }

  getAppearance(): MatFormFieldAppearance {
    return this.appearance;
  }
}
