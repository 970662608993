<ng-container>
  <div class="input-full-width">
    <p class="field-title">
      Prioritized identity matching columns (drag and drop to set the order)
    </p>
    <div class="input-half-width">
      <cdk-drop-list cdkDropListOrientation="vertical">
        <mat-chip-listbox
          class="mat-mdc-chip-set-stacked"
          multiple
          aria-label="Match key selection"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          @for (matchKey of matchKeys; track matchKey) {
            <mat-chip-option
              cdkDrag
              (click)="onMatchKeySelected(matchKey)"
              [selected]="toggleSelectedMatchKey(matchKey)"
              >{{ capitalizeFirstLetter(matchKey) }}</mat-chip-option
            >
          }
        </mat-chip-listbox>
      </cdk-drop-list>
    </div>
  </div>
  <br />
</ng-container>
