import { Component, OnInit } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { LoggerService } from 'app/services/logger.service';

import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../../shared/shared.scss'],
  standalone: false,
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    public userService: UserService,
    private analytics: AngularFireAnalytics,
    private logger: LoggerService,
    private activateRoute: ActivatedRoute,
    private auth: Auth,
    private messageBox: MessageBoxProvider,
    private formHelper: FormHelpersService
  ) {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl<string>('', []),
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(12),
      ]),
      confirmation: new FormControl<string>('', [
        Validators.required,
        (control) => {
          return formHelper.checkConfirmationPassword(control);
        },
      ]),
    });

    this.formHelper.setForm(this.resetPasswordForm);
  }

  private code!: string;
  private tenantId!: string;

  codeExpired = false;
  isLoading = false;
  isPasswordReset = false;
  resetPasswordForm: FormGroup;

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  async verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(this.auth, code);
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      // There is probably a better way than this using router module but was not able to find it.
      if (params['mode'] && params['mode'] === 'signIn') {
        this.router.navigate(['email-sign-in'], { queryParams: params });
      } else {
        this.code = params['oobCode'];

        if (this.code) {
          this.tenantId = params['tenantId'];
          this.auth.tenantId = this.tenantId;

          this.verifyPasswordResetCode(this.code)
            .then((email) => {
              if (email) {
                this.codeExpired = false;
                this.resetPasswordForm.setValue({
                  email,
                  password: '',
                  confirmation: '',
                });
                this.analytics.logEvent('reset-password');
              } else {
                this.codeExpired = true;
                this.messageBox.error(
                  'Either the reset link has already been utilized or it has expired.',
                  undefined,
                  10000
                );
              }
            })
            .catch((error) => {
              this.codeExpired = true;
              this.isPasswordReset = false;

              this.messageBox.error(
                'Either the reset link has already been utilized or it has expired.',
                undefined,
                10000
              );

              this.logger.error('ERROR', error);
            });
        } else {
          this.codeExpired = true;
          this.isPasswordReset = false;
          this.router.navigate(['sign-in-options']);
        }
      }
    });
  }

  confirmPasswordReset() {
    this.isLoading = true;
    if (this.resetPasswordForm?.value) {
      const { password, confirmation } = this.resetPasswordForm.value;

      if (password !== confirmation) {
        this.messageBox.error('Passwords does not match.');
        this.isLoading = false;
        return;
      }

      if (password.length < 12 || password.length > 128) {
        this.messageBox.error('Passwords must be 12 to 128 characters long.');
        this.isLoading = false;
        return;
      }

      if (this.codeExpired) {
        this.messageBox.error(
          'Please make a new password reset request as the previous one has expired.'
        );
        this.isLoading = false;
        return;
      }

      this.auth.tenantId = this.tenantId;

      confirmPasswordReset(this.auth, this.code, password)
        .then(() => {
          this.isPasswordReset = true;
          this.analytics.logEvent('reset-password-success');
          this.messageBox.success(
            'The new password was successfully changed.',
            () => {
              this.router.navigate(['sign-in-options']);
            }
          );
        })
        .catch((error) => {
          this.isPasswordReset = false;
          this.logger.error('Error resetting password:', error);
          this.messageBox.show(error.message, 'error');
        })
        .finally(() => (this.isLoading = false));
    }
  }
}
