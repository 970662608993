<form [formGroup]="form">
  <app-customer-dropdown
    class="dropdown"
    [showArchiveToggle]="false"
    [selectedCustomer]="defaultCustomer"
    [showSearchBox]="false"
    [displayAsBanner]="false"
    [displayTitle]="false"
    [showCustomerId]="true"
    [displayLabel]="true"
    label="{{ name }} Customer ID"
    (selectCustomerEvent)="onCustomerSelect($event)"
  ></app-customer-dropdown>
  <mat-form-field class="input-half-width">
    <mat-label>{{ name }} Dataset ID</mat-label>
    <mat-select formControlName="datasetId">
      <mat-option
        *ngFor="let dataset of datasets | keyvalue"
        [value]="dataset.key"
      >
        {{ dataset.value.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="input-half-width" *ngIf="showDatasetVersions">
    <mat-label>Dataset Versions (comma-separated)</mat-label>
    <input matInput formControlName="version" />
  </mat-form-field>
  <mat-form-field class="input-half-width" *ngIf="showPartitions">
    <mat-label>Partitions</mat-label>
    <input type="number" matInput formControlName="partitions" />
  </mat-form-field>
</form>
