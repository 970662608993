/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for postprocessing.PostprocessingConfig
 */
export class PostprocessingConfig implements GrpcMessage {
  static id = 'postprocessing.PostprocessingConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PostprocessingConfig();
    PostprocessingConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PostprocessingConfig) {
    _instance.originalJobId = _instance.originalJobId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PostprocessingConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.originalJobId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    PostprocessingConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PostprocessingConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.originalJobId) {
      _writer.writeString(1, _instance.originalJobId);
    }
  }

  private _originalJobId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PostprocessingConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<PostprocessingConfig.AsObject>) {
    _value = _value || {};
    this.originalJobId = _value.originalJobId;
    PostprocessingConfig.refineValues(this);
  }
  get originalJobId(): string {
    return this._originalJobId;
  }
  set originalJobId(value: string) {
    this._originalJobId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PostprocessingConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PostprocessingConfig.AsObject {
    return {
      originalJobId: this.originalJobId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PostprocessingConfig.AsProtobufJSON {
    return {
      originalJobId: this.originalJobId,
    };
  }
}
export module PostprocessingConfig {
  /**
   * Standard JavaScript object representation for PostprocessingConfig
   */
  export interface AsObject {
    originalJobId: string;
  }

  /**
   * Protobuf JSON representation for PostprocessingConfig
   */
  export interface AsProtobufJSON {
    originalJobId: string;
  }
}
