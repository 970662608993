import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GrpcStatusEvent } from '@ngx-grpc/common';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { GrpcStatus } from 'app/constants/grpc-status';
import { LocationList } from 'app/constants/lookups';
import { CustomerService } from 'app/services/customer.service';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { FormatService } from 'app/services/format.service';
import {
  KeyVersion,
  KeyVersions,
} from 'app/services/generated/src/main/proto/api/key-service.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  CustomerDataSet,
  EncryptedDataConfig,
  ExternalBlobStorage,
  FormatterConfig,
  KeyFileStructure,
} from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-customer-dataset',
  templateUrl: './add-customer-dataset.component.html',
  styleUrls: [
    './add-customer-dataset.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: false,
})
export class AddCustomerDataSetComponent implements OnInit {
  allowedCustomerLocations: Location[] | undefined;
  customerDataSetForm: FormGroup | undefined;
  customerDataSetId: string | undefined;
  customerDataset: CustomerDataSet = new CustomerDataSet();
  defaultKey = 'default';
  isLoading = false;
  KeyFileStructure = KeyFileStructure;
  keyFileStructureControl: FormControl | undefined;
  keys: KeyVersions[] = [];
  keyVersions: KeyVersion[] = [];
  locations = LocationList;
  selectedCustomer: Customer | undefined;
  selectedLocation: Location | undefined;
  title: string = 'New dataset';
  time: string = new Date().toLocaleString();
  update = false;

  constructor(
    private activatedRouter: ActivatedRoute,
    private customerDataService: CustomerDataService,
    private customerService: CustomerService,
    private formatService: FormatService,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private keyService: KeyService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: Router
  ) {
    this.setupForm();
  }

  ngOnInit(): void {
    this.activatedRouter.params.subscribe(async (params: Params) => {
      this.customerDataSetId = params['customerDataSetId'];
      this.update = !!this.customerDataSetId;
      this.title = this.update ? 'Edit dataset' : 'New dataset';

      if (this.update) {
        await this.getCustomerDataSet();
      } else {
        this.initializeNewDataset();
      }

      await this.setup();
    });
  }

  private initializeNewDataset(): void {
    this.customerDataset = new CustomerDataSet({
      name: '',
      customerId: '',
      encryptedData: new EncryptedDataConfig({
        dataPathPrefix: 'data/',
        encryptedSymmetricKeyPath: 'key_file',
        keyId: '',
        keyVersion: '',
      }),
    });
  }

  async addCustomerDataSet() {
    this.isLoading = true;

    try {
      await this.customerDataService.create(this.buildCustomerDataSet());
      this.router.navigate(['datasets']);
    } catch (error) {
      const errorMessage = `Failed to create customer dataset: ${(error as Error).message || 'Unknown error'}`;
      this.messageBox.error(errorMessage);
    } finally {
      this.isLoading = false;
    }
  }

  buildCustomerDataSet(): CustomerDataSet {
    const customerDataSet = this.customerDataset;

    const { value } = this.customerDataSetForm!;

    customerDataSet.name = value.name;
    if (this.selectedCustomer) {
      customerDataSet.customerId = this.selectedCustomer.id;
    }
    if (this.selectedLocation) {
      customerDataSet.location = this.selectedLocation;
    }
    if (value.key_name != undefined && value.key_name != '') {
      customerDataSet.encryptedData = new EncryptedDataConfig({
        keyId: value.key_name,
        keyVersion: value.key_version,
        dataPathPrefix: value.data_directory,
        encryptedSymmetricKeyPath: value.key_path,
        keyFileStructure: value.key_file_structure,
      });
    } else {
      throw new Error('Customer key and version are required.');
    }
    if (
      value.external_data_directory != undefined &&
      value.external_data_directory != ''
    ) {
      customerDataSet.externalBlobStorage = new ExternalBlobStorage({
        dataPathPrefix: value.external_data_directory,
      });
      if (value.key_name != undefined && value.key_name != '') {
        customerDataSet.externalBlobStorage.encryptedSymmetricKeyPath =
          value.external_key_path;
      }
    }
    customerDataSet.dated = value.dated;
    try {
      customerDataSet.formatterConfig = new FormatterConfig(
        JSON.parse(value.parser_config)
      );
    } catch {
      throw new Error('Failed to parse advanced config');
    }

    return customerDataSet;
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  async getCustomer(customerId: string) {
    const response = await this.customerService.readCustomer(customerId);
    this.selectedCustomer = response.customer;
    if (this.selectedCustomer) {
      this.onCustomerSelect(this.selectedCustomer);
    }
  }

  private async getCustomerDataSet(): Promise<void> {
    if (!this.customerDataSetId) return;

    try {
      const response = await this.customerDataService.get(
        this.customerDataSetId
      );
      this.customerDataset = response.customerDataSet || new CustomerDataSet();
    } catch (error) {
      this.logger.error('Error loading customer dataset', error);
      this.messageBox.error('Failed to load customer dataset.');
    }
  }

  getKeyVersionExpirationLabel(keyExpiration: Timestamp | undefined) {
    return keyExpiration
      ? ` - Expires ${this.formatService.formatProtoDateTime(keyExpiration)}`
      : '';
  }

  async getKeys(customerId: string) {
    try {
      const response = await this.keyService.listAllKeys([customerId]);
      this.keys = [];
      this.keys.push(...response.keyVersions!);
      this.keys.sort((a, b) => {
        if (a.config?.keyName && b.config?.keyName) {
          return a.config?.keyName!.localeCompare(b.config?.keyName);
        } else {
          return 0;
        }
      });

      if (this.keys.length == 1 && this.keys[0].config) {
        this.onKeySelect(this.keys[0].config!.id);
        this.customerDataSetForm!.get('key')?.setValue(this.keys[0].config!.id);
      } else {
        const key = this.keys.find((key) => key.config?.keyName === 'default');
        if (key) {
          this.customerDataSetForm!.get('key')?.setValue(key.config?.id, {
            onlySelf: true,
          });
        }
      }
    } catch (error) {
      const grpcStatusEvent = error as GrpcStatusEvent;
      if (grpcStatusEvent.statusCode === GrpcStatus.NOT_FOUND) {
        this.logger.info(grpcStatusEvent.statusMessage);
        this.messageBox.show('No keys found for customer.');
      } else {
        this.logger.error(grpcStatusEvent.statusMessage);
        this.messageBox.error('Error loading customer keys.');
      }
    }
  }

  getAllowedCustomerLocations(): void {
    this.allowedCustomerLocations = this.selectedCustomer?.locations || [];
    this.selectedLocation = this.customerDataset?.location;
  }

  async process(): Promise<void> {
    const action = this.update
      ? this.updateCustomerDataSet
      : this.addCustomerDataSet;
    await action.call(this);
  }

  async setup(): Promise<void> {
    if (!this.customerDataset) {
      return;
    }

    this.isLoading = true;

    try {
      const { customerId, encryptedData } = this.customerDataset;

      if (customerId) {
        await Promise.all([
          this.getKeys(customerId),
          this.getCustomer(customerId),
        ]);
      }

      if (encryptedData?.keyId) {
        this.onKeySelect(encryptedData.keyId);
      }

      this.setupForm();
    } catch (error) {
      this.logger.error('Unable to load form data', error);
      this.messageBox.error('Unable to load form data.');
    } finally {
      this.isLoading = false;
    }
  }

  setupForm(): void {
    const encryptedData = this.customerDataset?.encryptedData;
    const externalBlobStorage = this.customerDataset?.externalBlobStorage;

    this.keyFileStructureControl = new FormControl(
      encryptedData?.keyFileStructure ||
        KeyFileStructure.KEY_FILE_STRUCTURE_SINGLE_KEY
    );

    this.customerDataSetForm = this.formBuilder.group({
      name: new FormControl(this.customerDataset?.name, [Validators.required]),
      key_name: new FormControl(encryptedData?.keyId, [Validators.required]),
      key_version: new FormControl(encryptedData?.keyVersion, [
        Validators.required,
      ]),
      key_file_structure: this.keyFileStructureControl,
      external_data_directory: new FormControl(
        externalBlobStorage?.dataPathPrefix
      ),
      external_key_path: new FormControl(
        externalBlobStorage?.encryptedSymmetricKeyPath
      ),
      data_directory: new FormControl(encryptedData?.dataPathPrefix, [
        Validators.required,
      ]),
      key_path: new FormControl(encryptedData?.encryptedSymmetricKeyPath, [
        Validators.required,
      ]),
      dated: new FormControl(this.customerDataset?.dated ?? false),
      parser_config: new FormControl(
        this.customerDataset?.formatterConfig
          ? JSON.stringify(
              this.customerDataset.formatterConfig.toJSON(),
              undefined,
              2
            )
          : '{}',
        [Validators.required]
      ),
    });

    this.formHelper.setForm(this.customerDataSetForm);
  }

  onCustomerSelect(customer: Customer) {
    this.selectedCustomer = customer;
    this.getAllowedCustomerLocations();
    this.getKeys(customer.id);
  }

  onKeySelect(keyId: string): void {
    this.keyVersions =
      this.keys.find((key) => key.config?.id === keyId)?.versions || [];
    if (this.keyVersions.length === 1 && this.customerDataSetForm) {
      this.customerDataSetForm
        .get('key_version')
        ?.setValue(this.keyVersions[0].version);
    }
  }

  onLocationSelect(locations: Location) {
    this.selectedLocation = locations;
  }

  async updateCustomerDataSet() {
    this.isLoading = true;
    try {
      await this.customerDataService.update(this.buildCustomerDataSet());
      this.router.navigate(['datasets']);
    } catch (error) {
      const errorMessage = `Failed to update customer dataset: ${(error as Error).message || 'Unknown error'}`;
      this.messageBox.error(errorMessage);
    } finally {
      this.isLoading = false;
    }
  }
}
