import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-download-source-code-modal',
  templateUrl: './download-source-code-modal.component.html',
  styleUrls: [
    './download-source-code-modal.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: false,
})
export class DownloadSourceCodeComponent {
  title = 'Source Code Agreement';

  constructor(private dialogRef: MatDialogRef<DownloadSourceCodeComponent>) {}

  public close() {
    this.dialogRef.close({ accepted: false });
  }

  public process() {
    this.dialogRef.close({ accepted: true });
  }
}
