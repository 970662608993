/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for enclaveencryption.EnclaveEncryptionConfig
 */
export class EnclaveEncryptionConfig implements GrpcMessage {
  static id = 'enclaveencryption.EnclaveEncryptionConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnclaveEncryptionConfig();
    EnclaveEncryptionConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnclaveEncryptionConfig) {
    _instance.customerStorageCredentialId =
      _instance.customerStorageCredentialId || '';
    _instance.customerDataSetId = _instance.customerDataSetId || '';
    _instance.isEncrypted = _instance.isEncrypted || false;

    _instance.scanOnly = _instance.scanOnly || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EnclaveEncryptionConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerStorageCredentialId = _reader.readString();
          break;
        case 3:
          _instance.customerDataSetId = _reader.readString();
          break;
        case 5:
          _instance.isEncrypted = _reader.readBool();
          break;
        case 2:
          _instance.awsS3Storage = new AwsS3Storage();
          _reader.readMessage(
            _instance.awsS3Storage,
            AwsS3Storage.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.googleCloudStorage = new GoogleCloudStorage();
          _reader.readMessage(
            _instance.googleCloudStorage,
            GoogleCloudStorage.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.scanOnly = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    EnclaveEncryptionConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EnclaveEncryptionConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.customerStorageCredentialId) {
      _writer.writeString(1, _instance.customerStorageCredentialId);
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(3, _instance.customerDataSetId);
    }
    if (_instance.isEncrypted) {
      _writer.writeBool(5, _instance.isEncrypted);
    }
    if (_instance.awsS3Storage) {
      _writer.writeMessage(
        2,
        _instance.awsS3Storage as any,
        AwsS3Storage.serializeBinaryToWriter
      );
    }
    if (_instance.googleCloudStorage) {
      _writer.writeMessage(
        4,
        _instance.googleCloudStorage as any,
        GoogleCloudStorage.serializeBinaryToWriter
      );
    }
    if (_instance.scanOnly) {
      _writer.writeBool(6, _instance.scanOnly);
    }
  }

  private _customerStorageCredentialId: string;
  private _customerDataSetId: string;
  private _isEncrypted: boolean;
  private _awsS3Storage?: AwsS3Storage;
  private _googleCloudStorage?: GoogleCloudStorage;
  private _scanOnly: boolean;

  private _cloudStorage: EnclaveEncryptionConfig.CloudStorageCase =
    EnclaveEncryptionConfig.CloudStorageCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnclaveEncryptionConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<EnclaveEncryptionConfig.AsObject>) {
    _value = _value || {};
    this.customerStorageCredentialId = _value.customerStorageCredentialId;
    this.customerDataSetId = _value.customerDataSetId;
    this.isEncrypted = _value.isEncrypted;
    this.awsS3Storage = _value.awsS3Storage
      ? new AwsS3Storage(_value.awsS3Storage)
      : undefined;
    this.googleCloudStorage = _value.googleCloudStorage
      ? new GoogleCloudStorage(_value.googleCloudStorage)
      : undefined;
    this.scanOnly = _value.scanOnly;
    EnclaveEncryptionConfig.refineValues(this);
  }
  get customerStorageCredentialId(): string {
    return this._customerStorageCredentialId;
  }
  set customerStorageCredentialId(value: string) {
    this._customerStorageCredentialId = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }
  get isEncrypted(): boolean {
    return this._isEncrypted;
  }
  set isEncrypted(value: boolean) {
    this._isEncrypted = value;
  }
  get awsS3Storage(): AwsS3Storage | undefined {
    return this._awsS3Storage;
  }
  set awsS3Storage(value: AwsS3Storage | undefined) {
    if (value !== undefined && value !== null) {
      this._googleCloudStorage = undefined;
      this._cloudStorage =
        EnclaveEncryptionConfig.CloudStorageCase.awsS3Storage;
    }
    this._awsS3Storage = value;
  }
  get googleCloudStorage(): GoogleCloudStorage | undefined {
    return this._googleCloudStorage;
  }
  set googleCloudStorage(value: GoogleCloudStorage | undefined) {
    if (value !== undefined && value !== null) {
      this._awsS3Storage = undefined;
      this._cloudStorage =
        EnclaveEncryptionConfig.CloudStorageCase.googleCloudStorage;
    }
    this._googleCloudStorage = value;
  }
  get scanOnly(): boolean {
    return this._scanOnly;
  }
  set scanOnly(value: boolean) {
    this._scanOnly = value;
  }
  get cloudStorage() {
    return this._cloudStorage;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnclaveEncryptionConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnclaveEncryptionConfig.AsObject {
    return {
      customerStorageCredentialId: this.customerStorageCredentialId,
      customerDataSetId: this.customerDataSetId,
      isEncrypted: this.isEncrypted,
      awsS3Storage: this.awsS3Storage
        ? this.awsS3Storage.toObject()
        : undefined,
      googleCloudStorage: this.googleCloudStorage
        ? this.googleCloudStorage.toObject()
        : undefined,
      scanOnly: this.scanOnly,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnclaveEncryptionConfig.AsProtobufJSON {
    return {
      customerStorageCredentialId: this.customerStorageCredentialId,
      customerDataSetId: this.customerDataSetId,
      isEncrypted: this.isEncrypted,
      awsS3Storage: this.awsS3Storage
        ? this.awsS3Storage.toProtobufJSON(options)
        : null,
      googleCloudStorage: this.googleCloudStorage
        ? this.googleCloudStorage.toProtobufJSON(options)
        : null,
      scanOnly: this.scanOnly,
    };
  }
}
export module EnclaveEncryptionConfig {
  /**
   * Standard JavaScript object representation for EnclaveEncryptionConfig
   */
  export interface AsObject {
    customerStorageCredentialId: string;
    customerDataSetId: string;
    isEncrypted: boolean;
    awsS3Storage?: AwsS3Storage.AsObject;
    googleCloudStorage?: GoogleCloudStorage.AsObject;
    scanOnly: boolean;
  }

  /**
   * Protobuf JSON representation for EnclaveEncryptionConfig
   */
  export interface AsProtobufJSON {
    customerStorageCredentialId: string;
    customerDataSetId: string;
    isEncrypted: boolean;
    awsS3Storage: AwsS3Storage.AsProtobufJSON | null;
    googleCloudStorage: GoogleCloudStorage.AsProtobufJSON | null;
    scanOnly: boolean;
  }
  export enum CloudStorageCase {
    none = 0,
    awsS3Storage = 1,
    googleCloudStorage = 2,
  }
}

/**
 * Message implementation for enclaveencryption.AwsS3Storage
 */
export class AwsS3Storage implements GrpcMessage {
  static id = 'enclaveencryption.AwsS3Storage';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AwsS3Storage();
    AwsS3Storage.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AwsS3Storage) {
    _instance.bucketName = _instance.bucketName || '';
    _instance.prefixes = _instance.prefixes || [];
    _instance.encryptedSymmetricKeyPath =
      _instance.encryptedSymmetricKeyPath || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AwsS3Storage,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.bucketName = _reader.readString();
          break;
        case 4:
          (_instance.prefixes = _instance.prefixes || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.encryptedSymmetricKeyPath = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AwsS3Storage.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AwsS3Storage,
    _writer: BinaryWriter
  ) {
    if (_instance.bucketName) {
      _writer.writeString(1, _instance.bucketName);
    }
    if (_instance.prefixes && _instance.prefixes.length) {
      _writer.writeRepeatedString(4, _instance.prefixes);
    }
    if (_instance.encryptedSymmetricKeyPath) {
      _writer.writeString(3, _instance.encryptedSymmetricKeyPath);
    }
  }

  private _bucketName: string;
  private _prefixes: string[];
  private _encryptedSymmetricKeyPath: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AwsS3Storage to deeply clone from
   */
  constructor(_value?: RecursivePartial<AwsS3Storage.AsObject>) {
    _value = _value || {};
    this.bucketName = _value.bucketName;
    this.prefixes = (_value.prefixes || []).slice();
    this.encryptedSymmetricKeyPath = _value.encryptedSymmetricKeyPath;
    AwsS3Storage.refineValues(this);
  }
  get bucketName(): string {
    return this._bucketName;
  }
  set bucketName(value: string) {
    this._bucketName = value;
  }
  get prefixes(): string[] {
    return this._prefixes;
  }
  set prefixes(value: string[]) {
    this._prefixes = value;
  }
  get encryptedSymmetricKeyPath(): string {
    return this._encryptedSymmetricKeyPath;
  }
  set encryptedSymmetricKeyPath(value: string) {
    this._encryptedSymmetricKeyPath = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AwsS3Storage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AwsS3Storage.AsObject {
    return {
      bucketName: this.bucketName,
      prefixes: (this.prefixes || []).slice(),
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AwsS3Storage.AsProtobufJSON {
    return {
      bucketName: this.bucketName,
      prefixes: (this.prefixes || []).slice(),
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
    };
  }
}
export module AwsS3Storage {
  /**
   * Standard JavaScript object representation for AwsS3Storage
   */
  export interface AsObject {
    bucketName: string;
    prefixes: string[];
    encryptedSymmetricKeyPath: string;
  }

  /**
   * Protobuf JSON representation for AwsS3Storage
   */
  export interface AsProtobufJSON {
    bucketName: string;
    prefixes: string[];
    encryptedSymmetricKeyPath: string;
  }
}

/**
 * Message implementation for enclaveencryption.GoogleCloudStorage
 */
export class GoogleCloudStorage implements GrpcMessage {
  static id = 'enclaveencryption.GoogleCloudStorage';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoogleCloudStorage();
    GoogleCloudStorage.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoogleCloudStorage) {
    _instance.bucketName = _instance.bucketName || '';
    _instance.prefixes = _instance.prefixes || [];
    _instance.encryptedSymmetricKeyPath =
      _instance.encryptedSymmetricKeyPath || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoogleCloudStorage,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.bucketName = _reader.readString();
          break;
        case 4:
          (_instance.prefixes = _instance.prefixes || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.encryptedSymmetricKeyPath = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GoogleCloudStorage.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoogleCloudStorage,
    _writer: BinaryWriter
  ) {
    if (_instance.bucketName) {
      _writer.writeString(1, _instance.bucketName);
    }
    if (_instance.prefixes && _instance.prefixes.length) {
      _writer.writeRepeatedString(4, _instance.prefixes);
    }
    if (_instance.encryptedSymmetricKeyPath) {
      _writer.writeString(3, _instance.encryptedSymmetricKeyPath);
    }
  }

  private _bucketName: string;
  private _prefixes: string[];
  private _encryptedSymmetricKeyPath: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoogleCloudStorage to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoogleCloudStorage.AsObject>) {
    _value = _value || {};
    this.bucketName = _value.bucketName;
    this.prefixes = (_value.prefixes || []).slice();
    this.encryptedSymmetricKeyPath = _value.encryptedSymmetricKeyPath;
    GoogleCloudStorage.refineValues(this);
  }
  get bucketName(): string {
    return this._bucketName;
  }
  set bucketName(value: string) {
    this._bucketName = value;
  }
  get prefixes(): string[] {
    return this._prefixes;
  }
  set prefixes(value: string[]) {
    this._prefixes = value;
  }
  get encryptedSymmetricKeyPath(): string {
    return this._encryptedSymmetricKeyPath;
  }
  set encryptedSymmetricKeyPath(value: string) {
    this._encryptedSymmetricKeyPath = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoogleCloudStorage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoogleCloudStorage.AsObject {
    return {
      bucketName: this.bucketName,
      prefixes: (this.prefixes || []).slice(),
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoogleCloudStorage.AsProtobufJSON {
    return {
      bucketName: this.bucketName,
      prefixes: (this.prefixes || []).slice(),
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
    };
  }
}
export module GoogleCloudStorage {
  /**
   * Standard JavaScript object representation for GoogleCloudStorage
   */
  export interface AsObject {
    bucketName: string;
    prefixes: string[];
    encryptedSymmetricKeyPath: string;
  }

  /**
   * Protobuf JSON representation for GoogleCloudStorage
   */
  export interface AsProtobufJSON {
    bucketName: string;
    prefixes: string[];
    encryptedSymmetricKeyPath: string;
  }
}
